@charset 'utf-8';

@import "../common/mixins";
@import "../common/helpers/variables";
@import "component/c_trip_samll_layer";
/*
boot
***********************************************
* @description    : 11번가 > 여행사 사이트
***********************************************
* DATE          AUTHOR      DESCRIPTION
* ---------------------------------------------
* 2016.10.28     장경진          최초작성
* 2017.01.31     남협수          부분수정(.tour_nav)
***********************************************
*/

/* =============================================================================
   Common
   ========================================================================== */
html {
    overflow: auto;
}
html,
body {
    margin: 0;
    padding: 0;
}
/* layout */
.tour_bodyArea {
    width: 100%;
    color: #666;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
    letter-spacing: -1px;
    min-height: 1100px;
}
.tour_bodyArea a {
    color: #666;
}

/* input */
.tour_bodyArea input,
.tour_bodyArea button {
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.tour_bodyArea input[type="text"] {
    width: 94%;
    height: 42px;
    border: 1px solid #d9d9d9;
}
.tour_bodyArea input[type="checkbox"] {
    margin: 1px 4px 0 0;
    border: 1px solid #dadada;
}

/* checkbox design */
.check_box {
    position: relative;
}
.check_box input {
    overflow: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
} /* 20170120 김현기 : visibility의 주석을 제거함, 오페라/파이어폭스에서 문제 처리를 위함 */
.check_box input[type="checkbox"] {
    margin: 0;
    border: none;
}
.check_box label {
    position: absolute;
    left: 0;
    height: 14px;
    padding: 0 0 0 20px;
    background: url("/img/trip/flight/ico/sp_checkbox.png") no-repeat 0 0;
    font-size: 12px;
    line-height: 14px;
}
.check_box input[type="checkbox"]:checked + label {
    background: url("/img/trip/flight/ico/sp_checkbox_on.png") no-repeat 0 0;
}

/* select */
.tour_bodyArea select {
    height: 30px;
    padding: 7px 0 5px 5px;
    border: 1px solid #dadada;
    background: #fff url("/img/trip/flight/bul/arr_4px_down_666.gif") no-repeat right 10px center;
    font-family: "dotum";
    font-size: 12px;
    line-height: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.tour_bodyArea select::-ms-expand {
    display: none;
}

/* paging */
.paging {
    width: 100%;
    margin-top: 45px;
    text-align: center;
}
.paging a {
    display: inline-block;
    width: 30px;
    height: 28px;
    border: 1px solid #dadada;
    font-size: 12px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
}
.paging .first {
    margin-right: 7px;
    background: url("/img/trip/flight/btn/btn_paging_first.gif") no-repeat center center;
    text-indent: -9999px;
}
.paging .prev {
    margin-right: 7px;
    background: url("/img/trip/flight/btn/btn_paging_prev.gif") no-repeat center center;
    text-indent: -9999px;
}
.paging .next {
    margin-left: 7px;
    background: url("/img/trip/flight/btn/btn_paging_next.gif") no-repeat center center;
    text-indent: -9999px;
}
.paging .end {
    margin-left: 7px;
    background: url("/img/trip/flight/btn/btn_paging_end.gif") no-repeat center center;
    text-indent: -9999px;
}
.paging strong {
    display: inline-block;
    width: 30px;
    height: 28px;
    border: 1px solid #db8883;
    color: #e81818;
    font-size: 12px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
}

/* footer */
.ft_menu {
    width: 1200px;
    height: 70px;
    margin: 0 auto;
    margin-top: 50px;
    background: #f5f5f5;
}
.ft_menu ul {
    overflow: hidden;
    padding: 10px 0;
}
.ft_menu ul li {
    overflow: hidden;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.3%;
    border-right: 1px solid #dadada;
}
.ft_menu ul li:last-of-type {
    border-right: none;
}
.ft_menu ul li strong {
    float: left;
    display: block;
    height: 49px;
    margin-left: 48px;
    padding-left: 80px;
    color: #333;
    font-size: 18px;
    line-height: 49px;
}
.ft_menu ul li:first-of-type strong {
    background: url("/img/trip/flight/ico/sp_ft.gif") no-repeat left top;
}
.ft_menu ul li:nth-of-type(2) strong {
    background: url("/img/trip/flight/ico/sp_ft.gif") no-repeat left -49px;
}
.ft_menu ul li:last-of-type strong {
    background: url("/img/trip/flight/ico/sp_ft.gif") no-repeat left -98px;
}
.ft_menu ul li .link {
    float: right;
    margin: 20px 50px 0 0;
    padding-right: 11px;
    background: url("/img/trip/flight/bul/arr_7px_right_666.gif") no-repeat right center;
    color: #666;
    font-size: 12px;
    text-decoration: none;
}

/* dim */
.dim {
    position: fixed;
    z-index: 99998;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.dimmed {
    position: absolute;
    z-index: 90;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(245, 245, 245, 0.7);
}
.domestic .dimmed {
    top: 10px;
}

/* loading cover */
.x_loading_cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
}

/* loading */
.loading_stage {
    position: absolute;
    z-index: 5;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #dadada;
}
.domestic .loading_stage {
    right: inherit;
    width: 590px;
}
.domestic .loading_stage.return_loading {
    left: inherit;
    right: 0;
}
.loading_stage .progress {
    position: relative;
    height: 2px;
    background: #5477e9;
    overflow: visible !important;
}
.loading_stage .progress .flight {
    position: absolute;
    top: -10px;
    right: -24px;
    width: 24px;
    height: 22px;
    background: url("/img/trip/flight/ico/ico_loading_airplane.png") no-repeat right center;
}

.pointer_cursor {
    cursor: pointer;
}
.default_cursor {
    cursor: default !important;
}
.text_cursor {
    cursor: text !important;
}

/* =============================================================================
   Library
   ========================================================================== */
/* slides */
.slides li img {
    width: 100%;
}

.bx-pager {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto 39px;
    text-align: center;
}
.bx-pager .bx-pager-item {
    display: inline-block;
    margin-left: 12px;
}
.bx-pager .bx-pager-item:first-of-type {
    margin-left: 0;
}
.bx-pager .bx-pager-item a {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: url("/img/trip/flight/ico/sp_paging.png") no-repeat left top;
    cursor: pointer;
    text-indent: -9999px;
}
.bx-pager .bx-pager-item a.active {
    background-position: right top;
    cursor: default;
}
.bx-controls-direction {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 46px;
    margin: -23px auto 0;
}
.bx-controls-direction .bx-prev,
.bx-controls-direction .bx-next {
    position: absolute;
    width: 26px;
    height: 46px;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat left top;
    cursor: pointer;
    text-indent: -9999px;
}
.bx-controls-direction .bx-prev {
    left: 0;
    background-position: left top;
}
.bx-controls-direction .bx-next {
    right: 0;
    background-position: right top;
}
.bx-controls-direction a {
    display: block;
    height: 46px;
}

/* tour gnb */
.c-trip-gnb .trip-gnb-menu__all .trip-gnb-menu__group--event dd a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 182px;
}

/* =============================================================================
   Contents
   ========================================================================== */
/* main */
.tour_bodyArea h3.tit {
    color: #000;
    font-size: 22px;
    font-weight: normal;
}
.inner_visual {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-height: 348px;
}
.inner_visual .bx-controls-direction {
    width: 1200px;
}
.inner_visual .bx-controls-direction .bx-prev {
    left: 50px;
}
.inner_visual .bx-controls-direction .bx-next {
    right: 50px;
}

.search_form {
    width: 100%;
}
.search_form .tab {
    width: 100%;
    height: 55px;
    background: #171c31;
}
.search_form .tab ul {
    width: 1200px;
    height: 55px;
    margin: 0 auto;
}
.search_form .tab ul li {
    position: relative;
    float: left;
    width: 400px;
    background: #3b4364;
    font-size: 16px;
    font-weight: bold;
    line-height: 55px;
    text-align: center;
}
.search_form.domestic .tab ul li {
    width: 600px;
} /* 국내인 경우 */
.search_form .tab ul li.on {
    background: #262d4c;
    color: #f2f2f2;
}
.search_form .tab ul li.on:after {
    position: absolute;
    top: -6px;
    clear: both;
    display: block;
    width: 100%;
    height: 6px;
    background: #536dfe;
    content: "";
}
.search_form .tab ul li a {
    display: block;
    height: 55px;
    color: #f2f2f2;
    text-decoration: none;
}
.search_form .tab ul li span {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}
.search_form .tab ul li:first-of-type span {
    display: inline-block;
    width: 20px;
    height: 15px;
    margin-top: -3px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat left top;
}
.search_form .tab ul li:nth-of-type(2) span {
    width: 20px;
    height: 5px;
    margin-top: -8px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -27px -3px;
}
.search_form .tab ul li:nth-of-type(3) span {
    width: 20px;
    height: 19px;
    margin-top: -6px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat right top;
}

.quick_search {
    position: relative;
    width: 100%;
    padding: 30px 0 25px;
    border-bottom: 1px solid #dadada;
    background: #eee;
}
/*.quick_search .fl_option{position:relative;z-index:500;width:1200px;margin:0 auto;}*/
.quick_search .fl_option {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}
.quick_search .fl_option:after {
    clear: both;
    display: block;
    content: "";
}
.quick_search .fl_option > li {
    float: left;
    margin-top: 10px;
}
.quick_search .fl_option > li:first-of-type {
    margin-top: 0;
}
.quick_search .fl_option .selform {
    width: 100%;
}
.quick_search .inbox {
    float: left;
    display: table;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px;
    margin-left: -1px;
    border: 1px solid #dadada;
    background: #fff;
}
.quick_search .inbox.on {
    position: relative;
    z-index: 10;
    border: 1px solid #5477e9;
}
.quick_search .inbox:first-of-type {
    margin-left: 0;
}
.quick_search .inbox .ico {
    z-index: -1;
    display: inline-block;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat left top;
    vertical-align: middle;
    text-indent: -9999px;
}
.quick_search .inbox a[role="button"] {
    display: table-cell;
    padding-left: 10px;
    color: #333;
    font-size: 14px;
    text-align: left;
    vertical-align: middle;
    text-decoration: none;
}
.quick_search .inbox.area {
    width: 165px;
} /* 도시 선택 */
.quick_search .inbox.area .ico {
    width: 16px;
    height: 20px;
    margin-right: 9px;
    background-position: left -26px;
}
.quick_search .inbox.area .txt {
    display: inline-block;
    vertical-align: -1px;
}
.quick_search .inbox.date {
    width: 145px;
} /* 날짜 선택 */
.quick_search .inbox.period {
    width: 145px;
    background: #fff url("/img/trip/flight/bul/arr_6px_down_333.gif") no-repeat 121px center;
} /* 기간 선택 */
.quick_search.type02 .inbox.date {
    width: 366px;
} /* 편도 - 날짜 선택 */
.quick_search.type03 .inbox.date {
    position: relative;
    width: 366px;
} /* 다구간 - 날짜 선택 */
.quick_search.type03 .add {
    position: absolute;
    top: 90px;
    right: 0;
    left: 0;
    width: 1200px;
    margin: 0 auto;
    text-align: right;
}
.quick_search.type03 .add a {
    position: relative;
    z-index: 550;
    float: right;
    display: block;
    width: 158px;
    height: 48px;
    border: 1px solid #dadada;
    background: #f5f5f5;
    line-height: 48px;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
}
.quick_search.type03 .add a .ico {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 7px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -38px -14px;
    text-indent: -9999px;
}
.quick_search .inbox.date .ico {
    width: 21px;
    height: 20px;
    margin-right: 9px;
    background-position: -24px -26px;
}
.quick_search .inbox.set {
    width: 270px;
} /* 인원 및 좌석 선택 */
.quick_search .inbox.set .ico {
    float: left;
    width: 15px;
    height: 20px;
    margin-right: 9px;
    background-position: -55px -26px;
}
.quick_search .inbox.set .txt {
    float: left;
    margin-right: 8px;
}
.quick_search .inbox.set .txt:last-of-type {
    margin-right: 0;
}
.quick_search .fl_option .del {
    position: absolute;
    top: 0;
    right: 0;
    display: table;
    width: 48px;
    height: 48px;
    border-left: 1px solid #dadada;
}
.quick_search .fl_option .del a {
    display: table-cell;
    width: 48px;
    vertical-align: middle;
}
.quick_search .fl_option .del a span {
    display: block;
    width: 11px;
    height: 11px;
    margin: 0 auto;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px -14px;
    vertical-align: middle;
    text-indent: -9999px;
}
.quick_search .fl_option .btn_search {
    float: left;
    width: 160px;
    height: 50px;
    background: #536dfe;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
}
.quick_search .fl_option .btn_search a {
    display: block;
    color: #fff;
    text-decoration: none;
}
.quick_search .fl_option .ckbox {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 78px;
    height: 50px;
    margin-left: -1px;
    padding-top: 16px;
    border: 1px solid #dadada;
    background: #f5f5f5;
    text-align: center;
}
.quick_search .fl_option .ckbox .ck_box {
    vertical-align: middle;
}
.quick_search .fl_option .ckbox .label_txt {
    color: #333;
    font-size: 14px;
}
.quick_search .fl_option .trip_section {
    clear: both;
    padding-top: 20px;
}
.lately_search {
    padding: 40px 0 40px;
    border-bottom: 1px solid #dadada;
    background: #f7f7f7;
} /* 최근검색 */
.lately_search .inner_lately {
    position: relative;
    display: table;
    width: 1200px;
    margin: 0 auto;
}
.lately_search .inner_lately:after {
    clear: both;
    display: block;
    content: "";
}
.inner_lately h3 {
    position: absolute;
    top: 0;
    left: 0;
}
.inner_lately .pick {
    position: absolute;
    top: 5px;
    right: 40px;
    font-family: "Tahoma";
    font-size: 14px;
}
.inner_lately .pick a {
    text-decoration: none;
}
.inner_lately .pick span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -52px -52px;
    vertical-align: middle;
    text-indent: -9999px;
}
.inner_lately .column_l {
    position: relative;
    float: left;
    width: 570px;
    padding: 54px 30px 0 0;
}
.inner_lately .no_search {
    margin-top: 10px;
    color: #666;
    font-size: 16px;
}
.inner_lately .no_search .ico {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -47px -36px;
    vertical-align: middle;
}
.inner_lately .column_r {
    display: table-cell;
    width: 600px;
    vertical-align: middle;
}
.inner_lately .list > li {
    overflow: hidden;
    margin-top: 19px;
}
.inner_lately .list > li:first-of-type {
    margin-top: 0;
}
.inner_lately .list li .area {
    float: left;
    display: table;
    width: 260px;
}
.inner_lately .list li .area .num {
    float: left;
    width: 50px;
    color: #999;
    font-family: "Tahoma";
    font-size: 34px;
    line-height: 26px;
}
.inner_lately .list li .area ul {
    position: relative;
    display: table-cell;
    width: 200px;
    padding-right: 10px;
    vertical-align: middle;
}
.inner_lately .list li .area ul li {
    display: table-cell;
    min-width: 30px;
    color: #111;
    font-size: 16px;
}
.inner_lately .list li .area ul li:nth-of-type(2).ico {
    width: 50px;
    height: 15px;
    background: url("/img/trip/flight/ico/ico_flight_route.png") no-repeat center 2px;
    text-indent: -9999px;
}
.inner_lately .list li .area ul li:nth-of-type(2).ico02 {
    height: 6px;
    background: url("/img/trip/flight/ico/ico_flight_route_02.png") no-repeat center 5px;
} /* 편도 */
.inner_lately .list li .area ul li:nth-of-type(2).ico03 {
    height: 17px;
    background: url("/img/trip/flight/ico/ico_flight_route_03.png") no-repeat center 1px;
} /* 다구간 */
.inner_lately .list li .etc {
    float: left;
    width: 310px;
    margin-top: 2px;
}
.inner_lately .list li .etc .date {
    float: left;
    width: 155px;
    font-family: "Tahoma";
    font-size: 14px;
}
.inner_lately .list li .etc .people {
    float: left;
    width: 155px;
    font-size: 12px;
    line-height: 16px;
}
.inner_lately .banner {
    position: relative;
    width: 600px;
    height: 140px;
    background: url("/img/trip/flight/main/bg_lowest.jpg") no-repeat left top;
}
.inner_lately .banner h4 {
    padding: 43px 0 0 43px;
    color: #111;
    font-size: 22px;
    font-weight: normal;
}
.inner_lately .banner h4 .point {
    color: #536dfe;
}
.inner_lately .banner .txt_rolling {
    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    zoom: 1;
    width: 598px;
    height: 40px;
    margin: 0 auto;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    filter: alpha(opacity=50);
}
.inner_lately .banner .txt_rolling ul {
    overflow: hidden;
    width: 100%;
    height: 40px;
}
.inner_lately .banner .txt_rolling ul li {
    overflow: hidden;
    display: table;
    width: 100%;
    height: 40px;
}
.inner_lately .banner .txt_rolling ul li a {
    display: table-cell;
    color: #fff;
    vertical-align: middle;
    text-decoration: none;
}
.inner_lately .banner .txt_rolling ul li .txt {
    margin-right: 12px;
    font-size: 16px;
}
.inner_lately .banner .txt_rolling ul li .txt:last-of-type {
    margin-right: 0;
}

.recommend_wrap {
    width: 1200px;
    margin: 70px auto 0;
} /* 11번가 추천 TOP4 */
.recommend_area {
    width: 100%;
    height: 754px;
    background: #fff;
}
.recommend_area .recommend_nav {
    position: relative;
    float: left;
    width: 297px;
    height: 450px;
    margin-bottom: 2px;
    background: #536dfe;
}
.recommend_area .recommend_nav h3 {
    z-index: 1;
    height: 142px;
    margin: 30px 0 0 30px;
    background: url("/img/trip/flight/main/txt_recommend_city.png") no-repeat left top;
    text-indent: -9999px;
}
.recommend_area .recommend_nav .btn_direction {
    margin: 58px 0 0 30px;
}
.recommend_area .recommend_nav .btn_direction .prev {
    display: inline-block;
    width: 20px;
    height: 34px;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat left -116px;
    text-indent: -9999px;
}
.recommend_area .recommend_nav .btn_direction .next {
    display: inline-block;
    width: 20px;
    height: 34px;
    margin-left: 40px;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat right -116px;
    text-indent: -9999px;
}
.recommend_area .recommend_nav .area {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    margin-left: 1px;
}
.recommend_area .recommend_nav .area li {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 149px;
    height: 55px;
    margin: 0 0 -1px -1px;
    border: 1px solid #8a9cfe;
    background: #6d83fe;
    font-size: 16px;
    line-height: 55px;
    text-align: center;
}
.recommend_area .recommend_nav .area li.on {
    border: 1px solid #445ad4;
    background: #465ee7;
}
.recommend_area .recommend_nav .area li a {
    display: block;
    color: #fff;
    text-decoration: none;
}
.recommend_list ul li {
    position: relative;
    float: left;
    margin: 0 0 2px 2px;
}
.recommend_list ul li:nth-of-type(2) {
    margin-left: 0;
}
.recommend_list ul li:first-of-type .city_intro {
    width: 901px;
    height: 450px;
    text-align: right;
}
.recommend_list ul li:nth-of-type(2) .city_intro {
    width: 596px;
    height: 300px;
    text-align: left;
}
.recommend_list ul li:nth-of-type(3) .city_intro {
    width: 300px;
    height: 300px;
    text-align: left;
}
.recommend_list ul li:last-of-type .city_intro {
    width: 300px;
    height: 300px;
    text-align: left;
}
.recommend_list ul li a {
    display: block;
    text-decoration: none;
}
.recommend_list ul li .city {
    position: relative;
    float: left;
    margin: 152px 0 0 40px;
    color: #fff;
    font-size: 22px;
}
.recommend_list ul li .city:after {
    position: absolute;
    bottom: -18px;
    left: 0;
    display: block;
    width: 30px;
    height: 1px;
    background: #fff;
    content: "";
}
.recommend_list ul li:first-of-type .city {
    float: right;
    margin: 164px 40px 0 0;
}
.recommend_list ul li:first-of-type .city:after {
    right: 0;
    left: auto;
    float: right;
    width: 57%;
}
.recommend_list ul li .desc {
    clear: both;
    padding: 33px 40px 0 0;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
}
.recommend_list ul li .sum {
    position: absolute;
    right: auto;
    bottom: 37px;
    left: 40px;
    color: #fff;
    font-size: 14px;
}
.recommend_list ul li:first-of-type .sum {
    right: 70px;
    bottom: 67px;
    left: auto;
}
.recommend_list ul li .sum strong {
    font-family: "Tahoma";
    font-size: 34px;
    font-weight: normal;
}
.recommend_wrap .guide {
    margin-top: 25px;
    font-size: 14px;
}

.popular_city {
    position: relative;
    width: 100%;
    height: 630px;
    margin-top: 68px;
    background-repeat: no-repeat;
    background-position: center top;
} /* 인기도시 */
.popular_city .txt {
    display: block;
    height: 98px;
    padding-top: 70px;
    background: url("/img/trip/flight/main/txt_popular_city.png") no-repeat center 70px;
    text-align: center;
    text-indent: -9999px;
}
.popular_city .city_banner {
    position: absolute;
    right: 0;
    bottom: -44px;
    left: 0;
    overflow: hidden;
    width: 1200px;
    height: 436px;
    margin: 0 auto;
}
.city_banner ul li {
    position: relative;
    height: 436px;
    background: #fff;
}
.city_banner ul li .thumb {
    position: absolute;
    bottom: 0;
}
.city_banner ul li .thumb img {
    width: 230px;
    height: 240px;
}
.city_banner ul li a {
    display: block;
    text-decoration: none;
}
.city_banner ul li .travel {
    display: table;
    width: 100%;
    height: 196px;
}
.city_banner ul li .info {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.city_banner ul li .info .ico {
    display: block;
    width: 35px;
    height: 17px;
    margin: 0 auto;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -2px -46px;
    text-align: center;
    text-indent: -9999px;
}
.city_banner ul li .info .area {
    margin-top: 12px;
    color: #333;
    font-size: 16px;
}
.city_banner ul li .info .sum {
    margin-top: 18px;
    padding-top: 11px;
    background: url("/img/trip/flight/bul/bul_1px_666.gif") no-repeat center top;
    font-size: 14px;
    font-weight: bold;
}
.city_banner ul li .info .sum strong {
    display: block;
    margin-bottom: 3px;
    color: #111;
    font-size: 30px;
    font-weight: normal;
}
.city_banner .bx-controls-direction .bx-prev {
    background-position: left -116px;
}
.city_banner .bx-controls-direction .bx-next {
    background-position: right -116px;
}
.city_banner .bx-controls-direction .bx-prev,
.city_banner .bx-controls-direction .bx-next {
    height: 34px;
    margin-top: -12px;
}

.reserve_service {
    width: 1200px;
    height: 70px;
    margin: 111px auto 0;
    background: #f7f7f7;
}
.reserve_service p {
    padding: 15px 0 0 15px;
    font-size: 14px;
}
.reserve_service p .ico {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat 0 0;
    vertical-align: middle;
}
.reserve_service p a {
    color: #536dfe;
    text-decoration: underline;
}

.main_colgroup {
    overflow: hidden;
    width: 1200px;
    margin: 46px auto 0;
} /* 공지사항, FAQ */
.main_colgroup .list {
    width: 100%;
    margin-top: 17px;
    padding-top: 5px;
    border-top: 1px solid #dadada;
}
.main_colgroup .list li {
    overflow: hidden;
    margin-top: 12px;
}
.main_colgroup .list li .txt {
    overflow: hidden;
    float: left;
    display: block;
    width: 75%;
    height: 21px;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.main_colgroup .faq .list li .txt {
    width: 100%;
}
.main_colgroup .list li .date {
    float: right;
    font-family: "Tahoma";
    font-size: 14px;
}
.main_colgroup .notice {
    position: relative;
    float: left;
    width: 722px;
}
.main_colgroup .faq {
    position: relative;
    float: right;
    width: 405px;
}
.main_colgroup .more {
    position: absolute;
    top: 6px;
    right: 0;
}
.main_colgroup .more a {
    position: relative;
    padding-right: 11px;
    background: url("/img/trip/flight/bul/arr_7px_right_666.gif") no-repeat right 3px;
    font-family: "dotum";
    text-decoration: none;
}

.tour_bodyArea .company_info {
    width: 1200px;
    margin: 48px auto 10px;
} /* 판매자 연락처 */
.company_info .contact {
    overflow: hidden;
    margin-top: -10px;
}
.company_info .contact > li {
    position: relative;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 200px;
    height: 220px;
    margin: 10px 0 0;
    border: 1px solid #e1e1e1;
    border-right: none;
}
.company_info .contact > li:nth-child(6n),
.company_info .contact > li:last-of-type {
    border-right: 1px solid #e1e1e1;
}
.company_info .contact li .thumb {
    display: table;
    width: 100%;
    height: 70px;
}
.company_info .contact li .thumb span {
    display: table-cell;
    width: 100%;
    text-align: center;
    vertical-align: middle;
}
.company_info .contact li .info {
    position: absolute;
    bottom: 0;
    display: table;
    width: 100%;
    height: 140px;
    background: #f7f7f7;
    text-align: center;
}
.company_info .contact li .info .ico {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 33px;
    height: 33px;
    margin: -17px 0 0 -17px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -50px 0;
    content: "";
    text-indent: -9999px;
}
.company_info .contact .info ul {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}
.company_info .contact .info ul li {
    margin-top: 0px;
    font-size: 16px;
}
.company_info .contact .info ul li.desc {
    font-size: 12px;
    margin: 2px 2px 5px;
}
.company_info .contact .info ul li:first-of-type {
    margin-top: 15px;
}
.company_info .contact .info ul li .txt {
    display: inline-block;
    margin-right: 5px;
    padding-right: 11px;
    background: url("/img/trip/flight/bul/bul_11px_e1e1e1.gif") no-repeat right center;
    font-size: 12px;
    vertical-align: 1px;
}

/* 검색결과 */
.city_code_p {
    color: #cdcdce;
    font-family: "Tahoma";
    font-size: 14px;
}
.cabin_result_p {
    color: #cdcdce;
    font-family: "Tahoma";
}
#rtLowfareDiv {
    padding-top: 20px;
}

.sub_visual {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
} /* 서브 visual */ /* 수정 170119 */
.sub_visual.sub_bg_visual .sub_bg_area {
    height: 240px;
    width: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #eee;
}
.sub_inner {
    position: relative;
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
} /* 수정 170119 */
.sub_visual.sub_bg_visual .sub_inner {
    height: 159px;
}
.sub_inner .area {
    position: relative;
    overflow: hidden;
    width: 847px;
    margin: 30px auto 0;
    padding: 56px 0 0;
    background: url("/img/trip/flight/bg/bg_travel_area.png") no-repeat center top;
}
.sub_inner .area.type02 {
    background: url("/img/trip/flight/bg/bg_travel_area_02.png") no-repeat center top;
} /* 편도 */
.sub_inner .area.type03 {
    background: url("/img/trip/flight/bg/bg_travel_area_03.png") no-repeat center top;
} /* 다구간 */
.sub_inner .area li {
    float: left;
    width: 400px;
    color: #fff;
    font-size: 30px;
    text-align: center;
}
.sub_inner .area li:nth-of-type(2) {
    position: absolute;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    color: #ccced4;
    font-family: "Tahoma";
    font-size: 14px;
}
.sub_inner .area li:last-of-type {
    margin-left: 47px;
}
.sub_inner .my_info {
    position: absolute;
    right: 0;
    bottom: 20px;
}
.sub_inner .my_info ul {
    overflow: hidden;
}
.sub_inner .my_info ul li {
    float: left;
    margin-left: 23px;
    color: #fff;
}
.sub_inner .my_info ul li a {
    display: block;
    color: #fff;
    text-decoration: none;
}
.sub_inner .my_info ul li.select a {
    height: 17px;
    padding: 1px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left 0;
    font-family: "Tahoma";
    font-size: 13px;
}
.sub_inner .my_info ul li.select.on a {
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -23px;
    color: #6e84fe;
}
.sub_inner .my_info ul li.url a {
    height: 14px;
    padding: 2px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -48px;
    font-size: 12px;
}
.sub_inner .my_info ul li.no_url {
    height: 14px;
    padding: 2px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -69px;
    color: #cccfd4;
    font-size: 12px;
}

.lowest_airline {
    position: relative;
    width: 1200px;
    margin: 50px auto 0;
} /* 항공사별 최저가 slide banner */
.lowest_airline .banner {
    position: absolute; /* top: -60px; */
    right: -80px;
    width: 196px;
    height: 157px;
    background-repeat: no-repeat;
    background-position: left top;
    z-index: 8;
}
.lowest_airline .banner a {
    display: block;
    width: 196px;
    height: 157px;
    text-indent: -9999px;
}
.lowest_airline .lowest_list {
    position: relative;
    overflow: hidden;
    height: 156px;
    margin-left: 0px;
}
.lowest_airline .lowest_list .bx-wrapper {
    position: relative;
    max-width: 919px !important;
    margin: 0 0 0 0px !important;
}
.lowest_airline .lowest_list .bx-wrapper .slides li img {
    width: 40px;
    height: 40px;
}
.lowest_airline .lowest_list ul {
    z-index: 8;
}
.lowest_airline .lowest_list ul.slides {
    margin-left: 1px;
}
.lowest_airline .lowest_list ul li {
    position: relative;
    float: left;
    width: 154px;
    height: 154px;
    margin-left: -1px;
    border: 1px solid #dadada;
    text-align: center;
}
/*.lowest_airline .lowest_list ul li:first-of-type{margin-left:0; border-left:none;} /* 모든항공사
.lowest_airline .lowest_list ul li:nth-child(6n+1){margin-left:0; border-left:none;}*/
.lowest_airline .lowest_list ul li.on {
    z-index: 1;
    border: 1px solid #536dfe;
    background: #f8fdff;
} /* 선택 */
.lowest_airline .lowest_list ul li a {
    display: block;
    height: 154px;
    text-decoration: none;
}
.lowest_airline .lowest_list ul li .air_info {
    position: absolute;
    top: 55px;
    right: 0;
    left: 0;
    margin: 0 auto;
}
.lowest_airline .lowest_list ul li .air_info .logo {
    position: absolute;
    top: -43px;
    right: 0;
    left: 0;
    display: block;
    height: 40px;
    margin: 0 auto;
    text-align: center;
}
.lowest_airline .lowest_list ul li .air_info .name {
    display: block;
    padding-bottom: 10px;
    background: url("/img/trip/flight/bul/bul_1px_dadada.gif") no-repeat center bottom;
    color: #333;
    font-size: 16px;
}
/*.lowest_airline .lowest_list ul li:first-of-type .air_info .name{background:url('/img/trip/flight/bul/bul_1px_536dfe.gif') no-repeat center bottom;color:#536dfe;}  모든항공사 */
.lowest_airline .lowest_list ul li .fare {
    position: absolute;
    right: 0;
    bottom: 19px;
    left: 0;
    margin: 0 auto;
    font-size: 14px;
}
.lowest_airline .lowest_list ul li .fare .txt {
    display: block;
    margin-top: 3px;
    color: #666;
    font-size: 14px;
}
.lowest_airline .lowest_list ul li .fare strong {
    color: #111;
    font-family: "Tahoma";
    font-size: 18px;
}
.lowest_airline .lowest_list ul li .area_info {
    position: absolute;
    right: 0;
    bottom: 25px;
    left: 0;
    margin: 0 auto;
} /* 170619 */
.lowest_airline .lowest_list ul li .area_info strong {
    font-size: 15px;
} /* 170619 */
.lowest_airline .lowest_list ul li .date_b {
    font-weight: bold;
    color: #000;
    font-size: 15px;
} /* 170619 */
.lowest_airline .lowest_list ul li:first-of-type .fare {
    font-size: 16px;
} /* 모든항공사 */
/*.lowest_airline .lowest_list ul li:first-of-type .fare strong{color:#536dfe;font-size:22px;}  모든항공사 */
.lowest_airline .lowest_list .bx-controls {
    position: absolute;
    z-index: 9;
    top: 0;
} /* 170619 */
.lowest_airline .lowest_list .bx-controls-direction {
    display: inline-block;
}
.lowest_airline .lowest_list .bx-controls-direction .disabled {
    display: none !important;
}
.lowest_airline .lowest_list .bx-controls-direction .bx-prev {
    position: absolute;
    top: 79px;
    left: 0;
    width: 42px;
    height: 42px;
    display: inline-block;
    line-height: 30px;
    background: url("/img/trip/flight/main/bg_reser_li.png") repeat;
}
.lowest_airline .lowest_list .bx-controls-direction .bx-prev:after {
    display: inline-block;
    width: 14px;
    height: 26px;
    text-indent: -9999em;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat 0 -188px;
} /* 이전버튼 */
.lowest_airline .lowest_list .bx-controls-direction .bx-next {
    position: absolute;
    top: 79px;
    left: 876px;
    width: 42px;
    height: 42px;
    display: inline-block;
    line-height: 30px;
    background: url("/img/trip/flight/main/bg_reser_li.png") repeat;
}
.lowest_airline .lowest_list .bx-controls-direction .bx-next:after {
    display: inline-block;
    width: 14px;
    height: 26px;
    text-indent: -9999em;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat -136px -188px;
} /* 다음버튼 */
.lowest_airline .lowest_list .bx-controls-direction .bx-prev:after,
.lowest_airline .lowest_list .bx-controls-direction .bx-next:after {
    position: absolute;
    top: 50%;
    left: 50%;
    clear: both;
    display: block;
    width: 14px;
    height: 26px;
    margin: -13px 0 0 -7px;
    background-image: url("/img/trip/flight/btn/sp_prev_next.png");
    background-repeat: no-repeat;
    content: "";
}
.lowest_airline .page_num {
    position: absolute;
    z-index: 9;
    display: none;
    top: 56px;
    padding: 6px 12px 6px 11px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    background: url("/img/trip/flight/main/bg_reser_li02.png") repeat;
}
.lowest_airline .page_num.right {
    left: 839px;
    display: block !important;
}
.lowest_airline .page_num.left {
    left: 42px;
    display: block !important;
}
.lowest_airline .wing_bnr {
    position: absolute;
    top: 25px;
    left: -80px;
}
.tour_search_result {
    width: 1200px;
    margin: 40px auto 0;
} /* 검색결과 전체 리스트 */
.domestic.tour_search_result {
    position: relative;
    min-height: 300px;
}
.tour_search_result .apply_filter {
    overflow: hidden;
    height: 30px;
    padding: 10px 0 10px 0;
    border-top: 1px solid #555;
}
.tour_search_result .apply_filter.on {
    height: auto;
}
.apply_filter .txt {
    float: left;
    display: block;
    margin-top: 6px;
    color: #666;
    font-size: 14px;
} /* 적용필터 */
.apply_filter .txt .num {
    color: #536dfe;
    font-style: normal;
}
.apply_filter .filter {
    overflow: hidden;
    float: left;
    width: 860px;
    margin: -4px 0 0 21px;
}
.apply_filter .filter .tit {
    float: left;
    margin: 10px 0 0 10px;
    padding-left: 20px;
    background: url("/img/trip/flight/bul/bul_12px_646464.gif") no-repeat left 4px;
    color: #111;
    font-size: 14px;
}
.apply_filter .filter .list {
    overflow: hidden;
    float: left;
    width: 650px;
}
/* .apply_filter.on .filter .list{height:auto;}  */
.apply_filter .filter .list li {
    float: left;
    margin: 10px 0 3px 20px;
    cursor: pointer;
}
.apply_filter .filter .list li dt {
    float: left;
    color: #666;
    font-size: 14px;
}
.apply_filter .filter .list li dd {
    float: left;
    margin-left: 6px;
    color: #536dfe;
    font-size: 14px;
}
.apply_filter .filter .btn {
    float: right;
    margin-top: 10px;
}
.apply_filter .filter .btn a {
    display: block;
    width: 77px;
    height: 20px;
    background: url("/img/trip/flight/btn/sp_show_hide.gif") no-repeat 0 0;
    text-indent: -9999px;
}
.apply_filter .filter .btn.on a {
    background-position: 0 -25px;
}
.apply_filter .sort {
    float: right;
}
.apply_filter .sort select {
    width: 185px;
}
.tour_filter {
    position: relative;
    height: 48px;
    border: 1px solid #494949;
    background: #555;
} /* 필터 */
.tour_filter.fixed {
    position: fixed;
    z-index: 100;
    top: 70px;
}
.date_wrap .date-title.fixed {
    position: fixed;
    z-index: 100;
    top: 0;
}
.tour_filter .filter_list {
    float: left;
    width: 100%;
}
.tour_filter .filter_list > li {
    position: relative;
    float: left;
}
.tour_filter .filter_list > li.on {
    background: #536dfe;
}
.tour_filter .filter_list > li.on:after {
    position: absolute;
    top: -1px;
    left: 0;
    clear: both;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    border: 1px solid #536dfe;
    background: #536dfe;
    content: "";
}
.tour_filter .filter_list li.on:first-of-type:after {
    left: -1px;
} /* 첫번째 left 위치값 예외 */
.tour_filter .filter_list li.on:last-of-type:after {
    right: -1px;
    left: auto;
} /* 마지막 right 위치값 예외 */
.tour_filter .filter_list .airline {
    width: 135px;
}
.tour_filter .filter_list .stime {
    width: 119px;
}
.tour_filter .filter_list .ltime {
    width: 102px;
}
.tour_filter .filter_list .etime {
    width: 132px;
}
.tour_filter .filter_list .via {
    width: 77px;
}
.tour_filter .filter_list .vendors {
    width: 115px;
}
.tour_filter .filter_list .fare {
    width: 122px;
}
.tour_filter .filter_list .seat {
    width: 76px;
}
.tour_filter .filter_list .discount {
    width: 76px;
}
.tour_filter .filter_list .capita {
    width: 136px;
}
.tour_filter .filter_list .charge {
    width: 184px;
}
.tour_filter .filter_list li > a {
    position: relative;
    z-index: 2;
    display: block;
    height: 22px;
    padding: 15px 0 11px 10px;
    border-left: 1px solid #494949;
    text-align: left;
    text-decoration: none;
}
.tour_filter .filter_list li.on > a {
    border-left: 1px solid #536dfe;
}
.tour_filter .filter_list li:first-of-type > a {
    border-left: none;
}
.tour_filter .filter_list .airline a {
    padding-left: 0;
    text-align: center;
} /* 항공사 */
.tour_filter .filter_list .charge a {
    padding: 10px 0 16px 0;
    text-align: center;
} /* 총요금 */
.tour_filter .filter_list li a .txt {
    padding-right: 20px;
    background: url("/img/trip/flight/bul/arr_6px_down_f8f9eb.gif") no-repeat right 8px;
    color: #fff;
    font-size: 15px;
}
.tour_filter .filter_list li.on a .txt {
    background: url("/img/trip/flight/bul/arr_6px_up_f8f9eb.gif") no-repeat right 8px;
}
.tour_filter .filter_list .charge a .txt {
    padding-right: 0;
    background: none;
    line-height: 7px;
} /* 총요금 */
.tour_filter .filter_list .charge a .txt span {
    font-size: 13px;
}
.tour_filter .filter_list .layer_filter {
    position: absolute;
    z-index: 9;
    top: 49px;
    left: 0;
    display: none;
    padding: 20px;
    border: 2px solid #536dfe;
    background: #fff;
} /* 필터 레이어 영역 */ /* 2017-02-01 김현기 z-index 20->9 */
.tour_filter .filter_list li:first-of-type .layer_filter {
    left: -1px;
} /* 첫번째 left 위치값 예외 */
.tour_filter .filter_list li:last-of-type .layer_filter {
    right: -1px;
    left: auto;
} /* 마지막 right 위치값 예외 */
.tour_filter .filter_list .airline .layer_filter {
    width: 321px;
} /* 항공사 레이어 필터 사이즈 */
.tour_filter .filter_list .stime .layer_filter {
    width: 315px;
} /*  출발시간 레이어 필터 사이즈 */
.tour_filter .filter_list .ltime .layer_filter {
    width: 319px;
} /* 소요시간 레이어 필터 사이즈 */
.tour_filter .filter_list .etime .layer_filter {
    width: 305px;
} /* 도착시간 레이어 필터 사이즈 */
.tour_filter .filter_list .via .layer_filter {
    width: 147px;
} /* 경유 레이어 필터 사이즈 */
.tour_filter .filter_list .vendors .layer_filter {
    width: 147px;
} /* 판매사 레이어 필터 사이즈 */
.tour_filter .filter_list .fare .layer_filter {
    width: 171px;
} /* 요금조건 레이어 필터 사이즈 */
.tour_filter .filter_list .seat .layer_filter {
    width: 121px;
} /* 좌석 레이어 필터 사이즈 */
.tour_filter .filter_list .capita .layer_filter {
    width: 260px;
} /* 1인요금 레이어 필터 사이즈 */
.tour_filter .filter_list .fare .layer_filter {
    width: 300px;
} /* 국내 - 요금 레이어 필터 사이즈 */
.intSearch .tour_filter .filter_list .fare .layer_filter {
    max-height: 700px;
    overflow-y: auto;
}
.filter_list .layer_filter .gbox_ckbox {
    overflow: hidden;
    padding: 0 0 10px 10px;
    border: 1px solid #dadada;
    background: #f5f5f5;
} /* 회색박스 체크박스 */
.filter_list .layer_filter .gbox_ckbox .ckbox li {
    /* float:left; */
    width: 100px;
    margin: 11px 0 0 0;
}
.filter_list .layer_filter .gbox_ckbox .ckbox li.all {
    width: 100%;
}
.filter_list .layer_filter .gbox_ckbox .ckbox li .check_box label {
    color: #333;
}
.filter_list .layer_filter .ckbox {
    font-size: 0;
}
.filter_list .layer_filter .ckbox li {
    display: inline-block;
    width: 140px;
    margin: 17px 0 0 10px;
    font-size: 12px;
    vertical-align: top;
} /* 일반 체크박스 */
.intSearch .tour_filter .filter_list .fare .layer_filter .ckbox li {
    width: calc(50% - 10px);
}
.filter_list .layer_filter .ckbox li .check_box label {
    position: static;
    float: left;
    height: auto;
    cursor: pointer;
}
.filter_list .layer_filter .column {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    padding-top: 20px;
}
.filter_list .layer_filter .column:nth-of-type(odd) {
    padding-right: 10px;
}
.filter_list .layer_filter .column:first-of-type,
.filter_list .layer_filter .column:nth-of-type(2) {
    padding-top: 0;
}
.filter_list .layer_filter .column .txt {
    height: 26px;
    margin-top: -3px;
    color: #111;
    font-size: 14px;
}
.filter_list .layer_filter .rangeslider_wrap {
    margin: -20px;
}
.rangeslider_wrap li {
    overflow: hidden;
    height: 90px;
    border-top: 1px solid #dadada;
}
.rangeslider_wrap li:first-of-type {
    border-top: none;
}
.rangeslider_wrap li .txt {
    float: left;
    width: 50px;
    margin: 30px 0 0 20px;
    color: #111;
    font-size: 14px;
}
.rangeslider_wrap li .range_slider {
    float: left;
    width: 270px;
    margin: 30px 0 0 0;
    color: #111;
    font-size: 14px;
}
.filter_list .layer_filter .guide_txt {
    margin-top: 12px;
    padding-top: 18px;
    border-top: 1px solid #dadada;
    color: #666;
    font-size: 12px;
}
.filter_list .layer_filter .guide_txt .desc {
    display: block;
    margin-top: 3px;
    color: #999;
}

.tour_result_wrap {
    width: 100%;
} /* 리스트 */
.tour_result_wrap:after {
    clear: both;
    display: block;
    content: "";
}
.tour_result_wrap .result_list {
    float: left;
    width: 100%;
}
.tour_result_wrap .result_list > li {
    position: relative;
    float: left;
}
.tour_result_wrap .result_list > li:first-of-type {
    margin-top: 0;
}
.tour_result_wrap .result_list > li .tour_default_info {
    position: relative;
    float: left;
    min-height: 215px;
    border: 1px solid #dadada;
}
.tour_result_wrap .result_list > li .tour_default_info:before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 134px;
    clear: both;
    display: block;
    width: 429px;
    height: 100%;
    border-right: 1px solid #dadada;
    border-left: 1px solid #dadada;
    content: "";
}
.tour_result_wrap .result_list > li .tour_default_info:after {
    position: absolute;
    top: 0;
    right: 0;
    clear: both;
    display: block;
    width: 184px;
    height: 100%;
    border-left: 1px solid #dadada;
    background: #f5f5f5;
    content: "";
}
.tour_result_wrap .result_list .airline_info {
    float: left;
    width: 135px;
    height: 215px;
    text-align: center;
}
.tour_result_wrap .result_list .airline_info .airline_top {
    overflow: hidden;
    height: 182px;
}
.tour_result_wrap .result_list .airline_info .airline_top .pick {
    display: block;
    width: 36px;
    height: 18px;
    margin: 28px auto 22px;
    background: url("/img/trip/flight/ico/sp_my_pick.png") no-repeat 0 0;
    text-indent: -9999px;
}
.tour_result_wrap .result_list .airline_info .airline_top .pick a {
    display: block;
    height: 18px;
    text-indent: -9999px;
}
.tour_result_wrap .result_list .airline_info .airline_top .pick.on {
    background-position: 0 -22px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .ico {
    display: block;
    height: 43px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .ico img {
    width: 40px;
    height: 40px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .txt {
    display: block;
    margin-top: -1px;
    color: #333;
    font-size: 16px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .etc_ic {
    display: block;
    height: 22px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .etc_ic img {
    margin-top: 4px;
}
.tour_result_wrap .result_list .airline_info .airline_btm {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 134px;
    height: 32px;
    margin: 0 -1px -1px -1px;
    border: 1px solid #dadada;
    background: #fbfbfb;
}
.tour_result_wrap .result_list .airline_info .airline_btm .detail_info {
    display: block;
    padding: 9px 0;
    color: #111;
    font-size: 12px;
    text-decoration: none;
}
.tour_result_wrap .result_list .airline_info .airline_btm.on {
    border: 1px solid #536dfe;
    color: #000;
} /* 상세 비행 일정 활성화 버튼 */
.tour_result_wrap .result_list .airline_info .airline_btm.on a {
    color: #5477e9;
} /* 상세 비행 일정 활성화 버튼 */
.tour_result_wrap .result_list .airline_info .airline_btm .detail_info span {
    display: inline-block;
    padding-right: 12px;
    background: url("/img/trip/flight/bul/arr_4px_down_666_02.gif") no-repeat right 6px;
}
.tour_result_wrap .result_list .airline_info .airline_btm.on .detail_info span {
    background: url("/img/trip/flight/bul/arr_4px_down_5477e9.gif") no-repeat right 6px;
} /* 상세 비행 일정 활성화 버튼 */
.tour_result_wrap .result_list .route_info {
    position: relative;
    z-index: 2;
    float: left;
    display: table;
    width: 429px;
    height: 215px;
    text-align: center;
}
.tour_result_wrap .result_list .route_info.type03 {
    height: 276px;
}
.result_list .route_info .route_cell {
    display: table-cell;
    padding: 20px 0;
    vertical-align: middle;
}
.result_list .route_info .route_cell ul {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
}
.result_list .route_info.type03 .route_cell ul {
    margin-top: 15px;
}
.result_list .route_info .route_cell ul:first-of-type {
    margin-top: 0;
}
.result_list .route_info .route_cell li {
    text-align: center;
}
.result_list .route_info .route_cell li.layover {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -2px;
    font-size: 14px;
}
.result_list .route_info .route_cell li .area {
    display: block;
    color: #666;
    font-size: 14px;
}
.result_list .route_info .route_cell li .time {
    display: block;
    color: #111;
    font-family: "Tahoma";
    font-size: 22px;
}
.result_list .route_info .route_cell li .time02 {
    display: block;
    color: #666;
    font-size: 14px;
} /* 170619추가 */
.result_list .route_info .route_cell li .area02 {
    display: block;
    color: #111;
    font-size: 22px;
    font-weight: bold;
} /* 170619추가 */
.result_list.inventory_list .route_info .route_cell li .area02 {
    font-size: 16px;
}
.result_list .route_info .route_cell .start {
    float: left;
    width: 120px;
}
.result_list .route_info .route_cell .route_time {
    float: left;
    width: 80px;
}
.result_list .route_info .route_cell .arrive {
    position: relative;
    float: left;
    width: 120px;
    text-align: center;
}
.result_list .route_info .route_cell .arrive .ico_day {
    position: absolute;
    right: -20px;
    bottom: 5px;
    height: 16px;
    padding: 0 3px 0 2px;
    border: 1px solid #dadada;
    color: #333;
    font-size: 12px;
    line-height: 14px;
}
.result_list.inventory_list .route_info .route_cell .arrive .ico_day {
    right: -37px;
}
.result_list .route_info .route_cell .arrive .ico_day em {
    margin-right: 3px;
    font-size: 14px;
}
.result_list .route_info .route_cell .air_num {
    display: block;
    color: #999;
    font-family: "Tahoma";
    font-size: 12px;
}
.result_list .route_info .route_cell .lead_time {
    display: block;
    margin-top: 2px;
    padding-top: 11px;
    background: url("/img/trip/flight/ico/ico_route.gif") no-repeat center top;
    color: #666;
    font-size: 12px;
}
.result_list .rate_info {
    float: left;
    width: 450px;
    text-align: center;
}
.result_list .rate_info .charge_list {
    height: 183px;
    margin-top: -1px;
    padding-top: 1px;
}
.result_list .rate_info.type03 .charge_list {
    height: 276px;
} /* 다구간인 경우 */
.result_list .rate_info .charge_list.on {
    overflow-y: auto;
    height: auto;
    max-height: 366px;
    margin-bottom: 32px;
}
.result_list .rate_info .charge_list ul {
    width: 100%;
}
.result_list .rate_info .charge_list ul li {
    position: relative;
    margin-top: -1px;
    border: 1px solid #dadada;
    text-align: left;
}
.result_list .rate_info .charge_list ul li.on,
.result_list .rate_info .charge_list ul li:hover,
.result_list .rate_info .charge_list ul li:focus {
    position: relative;
    z-index: 2;
    border: 1px solid #536dfe;
}
.result_list .rate_info .charge_list ul li a {
    display: block;
    width: 100%;
    height: 18px;
    padding: 22px 0 20px;
    text-decoration: none;
}

.result_list .rate_info .charge_list ul li .agency_name {
    float: left;
    width: 105px;
    padding-left: 20px;
    line-height: 18px;
}
.result_list .rate_info .charge_list ul li .agency_name .vender {
    padding-left: 0;
    max-width: 78px;
    width: auto;
    width: initial;
}
.result_list .rate_info .charge_list ul li .agency_name .tmem_icon {
    display: none;
    width: 18px;
    height: 18px;
}
.result_list .rate_info .charge_list ul li .agency_name .tmem_icon.on {
    display: inline-block;
    background: url("/img/trip/flight/m/ico/sp_tour_sub.png") no-repeat left top;
    background-size: 240px;
    content: "";
    background-position: -112px -141px;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area {
    position: relative;
    display: inline-block;
    margin-left: 4.5px;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area .tooltip {
    position: absolute;
    color: #eb0d30;
    background: #ffffff;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 12px;
    display: none;
    border: 1px solid #cdcdcd;
    left: 19px;
    bottom: 26px;
    z-index: 999;
    border-radius: 3px 3px 3px 0;
    font-weight: bold;
    font-size: 11px;
    width: 79px;
    box-shadow: 1px 1px 3px #cdcdcd;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area .tooltip:before {
    content: "";
    position: absolute;
    border-top: 10px solid #cdcdcd;
    border-right: 11px solid transparent;
    border-left: 0 solid transparent;
    bottom: -11px;
    left: -1px;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area .tooltip:after {
    content: "";
    content: "";
    position: absolute;
    border-top: 10px solid #ffffff;
    border-right: 11px solid transparent;
    border-left: 0 solid transparent;
    bottom: -9px;
    left: 0;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area .tmem_shadow {
    display: none;
    position: absolute;
    bottom: 21px;
    left: 16px;
    box-shadow: 1px 1px 3px 0px #cdcdcd;
    border: 1px solid transparent;
    transform: rotate(-43deg);
    width: 14px;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area:hover.icon_area .tooltip {
    display: block;
}
.result_list .rate_info .charge_list ul li .agency_name .icon_area:hover.icon_area .tmem_shadow {
    display: block;
}

.result_list .rate_info .charge_list ul li .vender {
    float: left;
    display: block;
    width: 95px;
    padding-left: 20px;
}
.result_list .rate_info .charge_list ul li .condition {
    position: relative;
    float: left;
    display: block;
    width: 185px;
    line-height: 18px;
}
.result_list .rate_info .charge_list ul li .condition .ico_disc {
    margin-left: 8px;
    vertical-align: middle;
}
.result_list .rate_info .charge_list ul li .seat {
    float: left;
    display: block;
    width: 76px;
    text-align: center;
}
.result_list .rate_info .charge_list ul li .fare {
    float: left;
    display: block;
    width: 88px;
    padding-right: 20px;
    padding-left: 10px;
    font-size: 12px;
    text-align: right;
    line-height: 18px;
}

.result_list .rate_info .charge_list ul li .fare .cpn {
    display: none;
    font-size: 11px;
    margin-right: 6px;
    margin-top: -3px;
}
.result_list .rate_info .charge_list ul li.cpn .fare {
    margin-top: -6px;
}
.result_list .rate_info .charge_list ul li.cpn .fare .cpn {
    display: block;
}
.result_list .rate_info .charge_list ul li.cpn .condition {
    width: 112px;
}

.result_list .rate_info .charge_list ul li .fare strong {
    color: #111;
    font-family: "Tahoma";
    font-size: 14px;
}
.result_list .rate_info .charge_list ul li .ellipsis {
    overflow: hidden;
    display: block;
    width: 105px;
    height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.intSearch .result_list .rate_info .charge_list ul li .ellipsis {
    width: 185px;
}
.intSearch .result_list .rate_info .charge_list ul li.cpn .ellipsis {
    width: 105px;
}

.result_list .rate_info .btn_more {
    position: absolute;
    bottom: 0;
    width: 450px;
    height: 32px;
    border-top: 1px solid #dadada;
    background: #fff;
}
.result_list .rate_info .btn_more .more {
    display: block;
    height: 32px;
    color: #111;
    line-height: 32px;
    text-decoration: none;
}
.result_list .rate_info .btn_more .more .num {
    display: inline-block;
    margin: -3px 0 0 2px;
    padding-right: 14px;
    background: url("/img/trip/flight/ico/ico_add.gif") no-repeat right center;
    color: #999;
    font-family: "Tahoma";
    font-size: 10px;
    vertical-align: middle;
}
.result_list .rate_info .btn_more.close .more .num {
    width: 7px;
    height: 1px;
    margin: -2px 0 0 4px;
    padding: 0;
    background: #666;
} /* 닫기 */
.result_list .total {
    position: relative;
    z-index: 2;
    float: left;
    width: 184px;
    text-align: center;
}
.result_list .total .loading_wrap .loading {
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    display: block;
    width: 144px;
    height: 22px;
    margin: 0 auto;
    background: url("/img/trip/flight/common/loading.gif") no-repeat center center;
    text-indent: -9999px;
}

/* 청구할인 라벨 */
.international .result_list .total.display_label .loading_wrap .loading {
    top: 3px;
}
.international .result_list .total ul {
    margin-top: 11px;
}
.international .result_list .tour_default_info .total .label {
    display: block;
    margin: 29px 0 0;
    height: 20px;
}
.international .result_list .tour_default_info .total .label .item {
    font-size: 12px;
    color: #0b83e6;
    border: 1px solid rgba(11, 99, 255, 0.4);
    padding: 0 5px;
    height: 18px;
    line-height: 18px;
    margin: 0;
    display: none;
    background: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 160px;
}
.international .result_list .tour_default_info .total .label .item.on {
    display: inline-block;
}
.international.complete .result_list .total .loading_wrap {
    display: none;
}
.international.complete .result_list .tour_default_info .total .label {
    margin: 20px 0 9px;
}
.international.complete .result_list .total .btn_reserve {
    margin-top: 35px;
}
.international.complete .result_list .total.cpn .btn_reserve {
    margin-top: 19px;
}

.result_list .total ul {
    display: inline-block;
    margin-top: 52px;
}
.result_list .total ul li {
    float: left;
    margin-right: 10px;
    padding-right: 12px;
    background: url("/img/trip/flight/bul/bul_12px_d1d1d1.gif") no-repeat right 3px;
    font-size: 12px;
    text-decoration: none;
}
.result_list .total ul li:last-of-type {
    margin: 0;
    padding: 0;
    background: none;
}
.result_list .total ul li a {
    padding-bottom: 3px;
    background: url("/img/trip/flight/bul/bul_1px_737373.gif") repeat-x left bottom;
    text-decoration: none;
}
.result_list .total .fare {
    display: block;
    margin-top: 4px;
    color: #111;
    font-size: 14px;
}
.result_list .total .fare strong {
    color: #f33245;
    font-family: "Tahoma";
    font-size: 22px;
}

.result_list .total .fare span.cpn_price {
    display: none;
}
.result_list .total.cpn .fare span.cpn_price {
    display: block;
    color: #f43142;
    font-size: 12px;
    line-height: 16px;
}
.result_list .total.cpn .btn_reserve {
    margin-top: 19px;
}

.result_list .total .btn_reserve {
    display: block;
    width: 142px;
    height: 48px;
    margin: 35px auto 0;
    border: 1px solid #536dfe;
    background: #fff;
    color: #536dfe;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    text-decoration: none;
}
.no_result_search {
    margin: 125px 0 195px;
    padding: 150px 0 0;
    background: url("/img/trip/flight/bg/bg_no_search.gif") no-repeat center top;
    color: #111;
    font-size: 20px;
    text-align: center;
}

/* 검색결과 - 국내 */
.tour_search_result .colum_wrap {
    overflow: hidden;
    width: 100%;
}
.colum_wrap .colum_area {
    position: relative;
    float: left;
    width: 590px;
}
.colum_wrap.single_colum .colum_area {
    position: relative;
    width: 100%;
    margin: 0;
} /* 편도인 경우 */
.colum_wrap .colum_area:first-of-type {
    margin-right: 20px;
}
.colum_wrap .colum_area .top_area {
    display: table;
    width: 100%;
    height: 60px;
    border-top: 2px solid #555;
    margin-top: 10px;
}
.colum_wrap .colum_area .top_area .con_tit {
    position: relative;
    display: table-cell;
    vertical-align: middle;
}
.colum_wrap .colum_area .con_tit .tit {
    display: inline-block;
    height: 25px;
    margin-top: 2px;
    padding-left: 42px;
    background: url("/img/trip/flight/ico/sp_flight_route.png") no-repeat left 0;
    color: #111;
    font-size: 20px;
    font-weight: normal;
}
.colum_wrap .colum_area .con_tit .tit.end {
    background-position: left 100%;
}
.colum_wrap .colum_area .con_tit .area {
    display: inline-block;
    margin-left: 30px;
}
.colum_wrap .colum_area .con_tit .area li {
    float: left;
    font-size: 14px;
}
.colum_wrap .colum_area .con_tit .area li.ico {
    display: inline-block;
    width: 29px;
    height: 6px;
    margin: 4px 10px 0;
    background: url("/img/trip/flight/ico/ico_route.gif") no-repeat left top;
    text-indent: -9999px;
}
.colum_wrap .colum_area .con_tit .calendar {
    float: right;
    text-align: right;
}
.colum_wrap.single_colum .colum_area .con_tit .calendar {
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    float: none;
    margin: 0 auto;
    text-align: center;
} /* 편도인 경우 */
.colum_wrap .colum_area .con_tit .calendar li {
    display: inline-block;
    text-align: center;
}
.colum_wrap .colum_area .con_tit .calendar li.prev {
    margin-right: 9px;
    vertical-align: middle;
}
.colum_wrap .colum_area .con_tit .calendar li.next {
    margin-left: 7px;
    vertical-align: middle;
}
.colum_wrap .colum_area .con_tit .calendar li a {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
}
.colum_wrap .colum_area .con_tit .calendar li.prev a {
    background: url("/img/trip/flight/bul/arr_9px_left_666.gif") no-repeat center center;
}
.colum_wrap .colum_area .con_tit .calendar li.next a {
    background: url("/img/trip/flight/bul/arr_9px_right_666.gif") no-repeat center center;
}
.colum_wrap .colum_area .con_tit .calendar li.date {
    color: #333;
    font-size: 14px;
    vertical-align: middle;
}
.colum_wrap .colum_area .con_tit .calendar li.date strong {
    font-family: "Tahoma";
    font-size: 18px;
}

.colum_wrap .colum_area .sort_area {
    overflow: hidden;
    width: 100%;
    margin: 10px 0;
}
.colum_wrap .colum_area .sort_area .txt {
    float: left;
    margin: 5px 0 0 10px;
    font-size: 14px;
}
.colum_wrap .colum_area .sort_area .txt .num {
    color: #536dfe;
}
.colum_wrap .colum_area .sort_area .sort {
    float: right;
}
.colum_wrap .colum_area .sort_area .sort select {
    width: 145px;
}

.domestic .apply_filter {
    height: 20px;
    padding: 10px 0;
    border-top: 1px solid #dadada;
    background: #eee;
}
.domestic .apply_filter.on {
    height: auto;
}
.domestic .apply_filter .filter {
    float: none;
    width: 100%;
    margin: 0;
}
.domestic .apply_filter .filter .tit {
    margin: 0 0 0 10px;
    padding-left: 0;
    background: none;
}
.domestic .apply_filter .filter ul {
    width: 520px;
    margin: -10px 0 0 -10px;
}
.domestic .apply_filter.overflow .filter ul {
    width: 430px;
}
.domestic .single_colum .apply_filter .filter ul {
    width: 1000px;
} /* 편도인 경우 */
.domestic .apply_filter .filter ul li {
    margin: 10px 0 0 20px;
}
.domestic .apply_filter .filter .btn {
    margin: 0 10px 0 0;
    display: none;
}
.domestic .apply_filter.overflow .filter .btn {
    display: block;
}
.domestic .tour_filter .filter_list li > a {
    padding: 15px 0 11px 0;
    text-align: center;
} /* 선택하기 전 화면 filter */
.domestic .tour_filter .filter_list li a .txt {
    font-size: 14px;
    letter-spacing: -1.5px;
}
.domestic .tour_filter .airline {
    width: 101px;
}
.domestic .tour_filter .stime {
    width: 83px;
}
.domestic .tour_filter .etime {
    width: 83px;
}
.domestic .tour_filter .seat_type {
    width: 93px;
}
.domestic .tour_filter .seat {
    width: 62px;
}
.domestic .tour_filter .fare {
    width: 165px;
}
.domestic .single_colum .tour_filter .airline {
    width: 201px;
} /* 편도인 경우 - 항공사 */
.domestic .single_colum .tour_filter .stime {
    width: 185px;
} /* 편도인 경우 - 출발시간 */
.domestic .single_colum .tour_filter .etime {
    width: 185px;
} /* 편도인 경우 - 도착시간 */
.domestic .single_colum .tour_filter .seat_type {
    width: 194px;
} /* 편도인 경우 - 좌석종류 */
.domestic .single_colum .tour_filter .seat {
    width: 165px;
} /* 편도인 경우 - 좌석 */
.domestic .single_colum .tour_filter .fare {
    width: 268px;
} /* 편도인 경우 - 요금 */
.domestic .tour_result_wrap {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 790px;
    padding-top: 1px;
}
.domestic .colum_area.selected .tour_result_wrap {
    overflow: hidden;
}
.domestic .tour_result_wrap .loading_stage {
    top: 449px;
    bottom: auto;
}
.domestic.full_loading .loading_stage {
    width: 1200px;
}
.domestic.full_loading .loading_stage.return_loading {
    display: none !important;
}
.domestic_result_list > li {
    float: left;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    margin-top: -1px;
    border: 1px solid #dadada;
    cursor: pointer;
} /* 선택하기 전 화면 리스트 */
.domestic_result_list > li.no_search {
    cursor: default;
} /* 선택하기 전 화면 리스트 */
.selected .domestic_result_list > li {
    cursor: default;
} /* 선택하기 전 화면 리스트 */
.domestic_result_list > li.no_search {
    display: table;
    height: 791px;
    background: #fff;
    font-size: 16px;
    text-align: center;
}
.domestic_result_list > li.no_search .re {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
}
.domestic_result_list > li.no_search .re .ico {
    display: inline-block;
    width: 42px;
    height: 42px;
    margin-right: 15px;
    background: url("/img/trip/flight/bg/bg_no_search_02.gif") no-repeat left center;
    vertical-align: middle;
}
.domestic_result_list > li:hover.no_search,
.domestic_result_list > li:focus.no_search {
    border: 1px solid #dadada;
    background: transparent;
}
.domestic_result_list > li.on,
.domestic_result_list > li:hover,
.domestic_result_list > li:focus {
    position: relative;
    border: 1px solid #5477e9;
    background: #f5f5f5;
}
.domestic_result_list li .airline {
    float: left;
    display: table;
    width: 101px;
    height: 78px;
    text-align: center;
}
.single_colum .domestic_result_list li .airline {
    width: 201px;
} /* 편도인 경우 - 항공사 */
.domestic_result_list li .airline .ico {
    display: block;
}
.domestic_result_list li .airline .ico img {
    width: 36px;
    height: 36px;
}
.domestic_result_list li .airline .txt {
    display: block;
    font-size: 12px;
}
.domestic_result_list li .route_info {
    float: left;
    display: table;
    width: 166px;
    height: 78px;
    text-align: center;
}
.single_colum .domestic_result_list li .route_info {
    width: 370px;
} /* 편도인 경우 - 출발시간 ~ 도착시간 */
.domestic_result_list li .route_info li {
    display: inline-block;
}
.domestic_result_list li .route_info .start {
    color: #111;
    font-family: "Tahoma";
    font-size: 18px;
}
.domestic_result_list li .route_info .arrive {
    color: #111;
    font-family: "Tahoma";
    font-size: 18px;
}
.domestic_result_list li .route_info .ico {
    width: 25px;
    margin: 0 10px;
    background: url("/img/trip/flight/ico/ico_route_02.gif") no-repeat center top;
    text-indent: -9999px;
}
.single_colum .domestic_result_list li .route_info .ico {
    margin: 0 56px;
} /* 편도인 경우 */
.domestic_result_list li .seat_type {
    float: left;
    display: table;
    width: 93px;
    height: 78px;
    text-align: center;
}
.single_colum .domestic_result_list li .seat_type {
    width: 194px;
} /* 편도인 경우 - 좌석종류 */
.domestic_result_list li .seat {
    float: left;
    display: table;
    width: 22px;
    height: 78px;
    text-align: center;
    padding-left: 20px;
}
.single_colum .domestic_result_list li .seat {
    width: 165px;
    padding-left: 0;
} /* 편도인 경우 - 좌석 */
.domestic_result_list li .fare {
    float: right;
    display: table;
    width: 153px;
    height: 78px;
    padding-right: 16px;
    text-align: right;
}
.single_colum .domestic_result_list li .fare {
    width: 181px;
    padding-right: 70px;
} /* 편도인 경우 - 요금 */
.domestic_result_list li .fare strong {
    color: #111;
    font-family: "Tahoma";
    font-size: 18px;
}
.domestic_result_list li .fieldset {
    display: table-cell;
    vertical-align: middle;
}
.domestic_result_list li .fieldset .x_fare_item:nth-of-type(n + 3) {
    margin-top: 12px;
}
.domestic_result_list li .fieldset .label {
    display: block;
    width: 153px;
}
.selected .domestic_result_list li .fieldset .label {
    width: 125px;
}
.single_colum .domestic_result_list li .fieldset .label {
    width: 181px;
}
.single_colum .selected .domestic_result_list li .fieldset .label {
    width: 182px;
}
.domestic_result_list li .fieldset .label .item_wrap.temp {
    display: none;
}
.domestic_result_list li .fieldset .label .item_wrap .item {
    font-size: 11px;
    color: #0b83e6;
    border: 1px solid rgba(11, 99, 255, 0.4);
    padding: 0 4px;
    height: 16px;
    line-height: 16px;
    margin: 0 0px -1px 0;
    display: none;
    background: #ffffff;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 143px;
    letter-spacing: -1.8px;
}
.single_colum .domestic_result_list li .fieldset .label .item_wrap .item {
    max-width: 171px;
}
.single_colum .domestic_result_list li .airline .fieldset .label .item {
    max-width: 235px;
}
.selected .domestic_result_list li .fieldset .label .item {
    max-width: 115px;
}
.single_colum .selected .domestic_result_list li .airline .fieldset .label .item {
    max-width: 240px;
}
.domestic_result_list li .fieldset .label .item_wrap .item {
    display: inline-block;
}

.colum_wrap .colum_area.selected {
    width: 528px;
    padding: 0 30px 22px;
    border: 1px solid #dadada;
    border-top: 2px solid #536dfe;
    margin-top: 10px;
}
.single_colum .colum_area.selected {
    width: 1138px;
    padding: 0 30px 22px;
    border: 1px solid #dadada;
    border-top: 2px solid #536dfe;
} /* 편도인 경우 */
.colum_area.selected .top_area {
    border-top: none;
}
.colum_area.selected .con_tit .tit {
    margin-top: 6px;
    background: url("/img/trip/flight/ico/sp_flight_route_on.png") no-repeat left 0;
    color: #536dfe;
}
.colum_area.selected .con_tit .tit.end {
    background-position: left 100%;
}
.colum_area.selected .con_tit .btn_re {
    float: right;
    display: inline-block;
}
.colum_area.selected .con_tit .btn_re a {
    display: block;
    width: 88px;
    height: 33px;
    border: 1px solid #536dfe;
    color: #536dfe;
    font-size: 14px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    text-decoration: none;
}

.colum_area.selected .sel_info .date {
    display: block;
    width: 100%;
    height: 59px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    background: #fbfbfb;
    color: #333;
    line-height: 59px;
    text-align: center;
}
.colum_area.selected .sel_info .date strong {
    font-family: "Tahoma";
    font-size: 26px;
    font-weight: normal;
}
.colum_area.selected .sel_info .date span {
    font-size: 20px;
}
.colum_area.selected .sel_info ul {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 46px 0 51px;
}
.colum_area.selected .sel_info ul li {
    float: left;
    width: 50%;
    text-align: center;
}
.colum_area.selected .sel_info ul li .area {
    display: block;
    color: #333;
    font-size: 22px;
}
.colum_area.selected .sel_info ul li .time {
    display: block;
    margin-top: -3px;
    color: #536dfe;
    font-family: "Tahoma";
    font-size: 34px;
}
.colum_area.selected .sel_info ul .route_time {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 128px;
    margin: -11px 0 0 -64px;
}
.colum_area.selected .sel_info .route_time .air_num {
    display: block;
    color: #999;
    font-family: "Tahoma";
    font-size: 12px;
}
.colum_area.selected .sel_info .route_time .lead_time {
    display: block;
    margin-top: -6px;
    padding-top: 21px;
    background: url("/img/trip/flight/ico/ico_large_route.gif") no-repeat center top;
    color: #666;
    font-size: 12px;
}
.colum_area.selected .tour_filter {
    border: none;
    border-top: 2px solid #555;
    background: #fff;
} /* 선택된 화면 filter */
.colum_area.selected .tour_filter li {
    padding: 16px 0 16px 0;
    color: #333;
    font-size: 14px;
    text-align: center;
}
.colum_area.selected .tour_filter .airline {
    width: 101px;
}
.colum_area.selected .tour_filter .stime {
    width: 83px;
}
.colum_area.selected .tour_filter .etime {
    width: 83px;
}
.colum_area.selected .tour_filter .seat_type {
    width: 83px;
}
.colum_area.selected .tour_filter .seat {
    width: 52px;
}
.colum_area.selected .tour_filter .fare {
    width: 126px;
}
.single_colum .colum_area.selected .tour_filter .airline {
    width: 191px;
} /* 편도인 경우 - 항공사 */
.single_colum .colum_area.selected .tour_filter .stime {
    width: 175px;
} /* 편도인 경우 - 출발시간 */
.single_colum .colum_area.selected .tour_filter .etime {
    width: 175px;
} /* 편도인 경우 - 도착시간 */
.single_colum .colum_area.selected .tour_filter .seat_type {
    width: 176px;
} /* 편도인 경우 - 좌석종류 */
.single_colum .colum_area.selected .tour_filter .seat {
    width: 159px;
} /* 편도인 경우 - 좌석 */
.single_colum .colum_area.selected .tour_filter .fare {
    width: 262px;
} /* 편도인 경우 - 요금 */

.colum_area.selected .domestic_result_list > li {
    border: none;
    border-top: 1px solid #e5e5e5;
    background: #f5f5f5;
} /* 선택된 화면 리스트 */
.colum_area.selected .domestic_result_list .airline {
    width: 101px;
}
.colum_area.selected .domestic_result_list .route_info {
    width: 166px;
}
.colum_area.selected .domestic_result_list .route_info .ico {
    margin: 0 6px;
}
.colum_area.selected .domestic_result_list .seat_type {
    width: 83px;
}
.colum_area.selected .domestic_result_list .seat {
    padding-left: 15px;
}
.colum_area.selected .domestic_result_list .fare {
    width: 125px;
    padding-right: 16px;
}
.single_colum .colum_area.selected .domestic_result_list .airline {
    width: 191px;
} /* 편도인 경우 - 항공사 */
.single_colum .colum_area.selected .domestic_result_list .route_info {
    width: 350px;
} /* 편도인 경우 - 출발시간 ~ 도착시간 */
.single_colum .colum_area.selected .domestic_result_list .route_info .ico {
    margin: 0 52px;
}
.single_colum .colum_area.selected .domestic_result_list .seat_type {
    width: 176px;
} /* 편도인 경우 - 좌석종류 */
.single_colum .colum_area.selected .domestic_result_list .seat {
    width: 159px;
    padding-left: 0;
} /* 편도인 경우 - 좌석 */
.single_colum .colum_area.selected .domestic_result_list .fare {
    width: 182px;
    padding-right: 80px;
} /* 편도인 경우 - 요금 */

.price_details_wrap {
    overflow: hidden;
    width: 1200px;
    margin: 20px auto 0;
}
.detail_info_area {
    border-right: 1px solid #dadada;
    border-left: 1px solid #dadada;
}
.detail_info_area .price_list {
    overflow: hidden;
    padding: 35px 30px 40px 0;
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
}
.detail_info_area .price_list > li {
    float: left;
    width: 359px;
    margin-left: 30px;
}
.detail_info_area .price_list li .person {
    padding-left: 20px;
    color: #111;
    font-size: 18px;
}
.detail_info_area .price_list li .person .num {
    color: #536dfe;
    font-family: "Tahoma";
    font-size: 18px;
    font-weight: bold;
}
.price_list li .detail {
    margin-top: 18px;
    padding: 10px 0 0;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}
.price_list li .detail li {
    display: table;
    height: 32px;
    padding: 0 20px;
}
.price_list li .detail li .tit {
    display: table-cell;
    width: 125px;
    font-size: 12px;
    vertical-align: middle;
}
.price_list li .detail li .num {
    display: table-cell;
    width: 64px;
    font-size: 12px;
    vertical-align: middle;
}
.price_list li .detail li .fare {
    display: table-cell;
    width: 130px;
    color: #666;
    font-size: 12px;
    text-align: right;
    vertical-align: middle;
}
.price_list li .detail li .fare strong {
    color: #333;
    font-family: "Tahoma";
    font-size: 14px;
}
.price_list li .detail li.total {
    display: block;
    height: 56px;
    margin-top: 10px;
    padding: 18px 20px 0;
    background: #f5f5f5;
}
.price_list li .detail li.total .tit {
    float: left;
    display: block;
    width: 189px;
    color: #333;
    font-size: 14px;
    font-weight: bold;
}
.price_list li .detail li.total .tit .desc {
    display: block;
    margin-top: 10px;
    color: #666;
    font-size: 12px;
    font-weight: normal;
}
.price_list li .detail li.total .fare {
    float: right;
    display: block;
    width: 130px;
}
.price_list li .detail li.total .fare strong {
    color: #536dfe;
    font-family: "Tahoma";
    font-size: 18px;
}
.seller_list {
    width: 100%;
}
.seller_list table {
    width: 100%;
}
.seller_list table td {
    height: 80px;
    padding: 0;
    border-bottom: 1px solid #dadada;
    color: #666;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}
.seller_list table td img {
    vertical-align: middle;
    height: 30px;
}
.seller_list table td.logo,
.seller_list table td.btn {
    text-align: center;
}
.seller_list table td.fare {
    padding-right: 20px;
    color: #111;
    font-size: 14px;
    text-align: right;
}
.seller_list table td.fare .label_item {
    font-size: 11px;
    color: #0b83e6;
    border: 1px solid rgba(11, 99, 255, 0.4);
    padding: 0 5px;
    height: 16px;
    line-height: 16px;
    display: none;
    background: #ffffff;
    margin: 0 24px -3px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 113px;
}
.seller_list table td.fare .origin_price {
    margin-right: 8px;
    font-size: 13px;
    text-decoration: line-through;
    display: none;
}
.seller_list table td.fare.on .label_item {
    display: inline-block;
}
.seller_list table td.fare.on .origin_price {
    display: inline-block;
}
.seller_list table td.fare strong {
    color: #f33245;
    font-family: "Tahoma";
    font-size: 20px;
}
.seller_list table td.btn a {
    display: block;
    width: 120px;
    height: 48px;
    margin: 0 auto;
    color: #ffffff;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
    text-decoration: none;
    background: #f43142;
}
.seller_list table td.name {
    color: #333;
    font-size: 14px;
    font-weight: bold;
}

/* 검색결과 - 상세 비행 일정 */
.tour_detail {
    position: relative;
    float: left;
    border: 1px solid #5477e9;
}
.tour_detail .tour_detail_info {
    border: none;
}
.tour_detail_info {
    position: relative;
    float: left;
    display: none;
    width: 1198px;
    margin-top: -1px;
    border: 1px solid #5477e9;
    background: #fff;
}
.tour_detail_info .btn_close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 42px;
    height: 42px;
    background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center;
    text-indent: -9999px;
}
.tour_detail_info .btn_close a {
    display: block;
    height: 42px;
}
.tour_detail_info .con_tit {
    height: 42px;
    background: #5477e9;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
}
.tour_detail_info .detail_view {
    width: 100%;
}
.tour_detail_info .detail_view .tbl_flight {
    width: 100%;
}
.tour_detail_info .detail_view .tbl_flight th {
    padding: 25px 0 20px;
    border-right: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    background: #f5f5f5;
    color: #333;
    font-size: 16px;
    text-align: center;
}
.tour_detail_info .detail_view .tbl_flight th .start {
    padding: 45px 0 0 0;
    background: url("/img/trip/flight/ico/ico_flight_start.png") no-repeat center top;
    font-weight: normal;
}
.tour_detail_info .detail_view .tbl_flight th .arrive {
    padding: 45px 0 0 0;
    background: url("/img/trip/flight/ico/ico_flight_arrive.png") no-repeat center top;
    font-weight: normal;
}
.tour_detail_info .detail_view .tbl_flight td {
    padding: 0 0 10px 187px;
    border-bottom: 1px solid #dadada;
    text-align: left;
}
.tour_detail_info .detail_view .tbl_flight td.txt_desc {
    padding: 13px 16px 15px;
    font-size: 14px;
}
.tour_detail_info .detail_view .tbl_flight td.txt_desc .import {
    color: #5959d3;
}
.tour_detail_info .detail_view .tbl_flight tr:last-of-type th,
.tour_detail_info .detail_view .tbl_flight tr:last-of-type td {
    border-bottom: none;
}
.tour_detail_info .detail_view .itinerary {
    min-height: 125px;
}
.tour_detail_info .detail_view .itinerary > li {
    position: relative;
    float: left;
    width: 174px;
    margin: 65px 0 0 -87px;
}
.tour_detail_info .detail_view .itinerary > li:first-of-type {
    margin-left: -174px;
}
.tour_detail_info .detail_view .itinerary > li:last-of-type {
    margin-bottom: 28px;
}
.tour_detail_info .detail_view .itinerary > li:before {
    position: absolute;
    top: -6px;
    left: 50%;
    clear: both;
    display: block;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    background: url("/img/trip/flight/ico/ico_circle_blue.gif") no-repeat center top;
    content: "";
} /* 파란색 아이콘 */
.tour_detail_info .detail_view .itinerary > li.move:before,
.tour_detail_info .detail_view .itinerary > li.wait:before,
.tour_detail_info .detail_view .itinerary > li.stopover:before {
    left: 0;
} /* 소요시간, 대기시간, 숨은경유지 아이콘 위치 */
.tour_detail_info .detail_view .itinerary > li.move {
    position: relative;
    width: 274px;
} /* 소요시간, 대기시간 */
.tour_detail_info .detail_view .itinerary > li.move:after {
    position: absolute;
    top: 0;
    left: 50%;
    clear: both;
    display: block;
    width: 260px;
    height: 2px;
    margin-left: -130px;
    background: #5477e9;
    content: "";
} /* 소요시간 라인 */
.tour_detail_info .detail_view .itinerary > li.wait:before {
    background: url("/img/trip/flight/ico/ico_circle_gray.gif") no-repeat center top;
} /* 대기시간 아이콘 */
.tour_detail_info .detail_view .itinerary > li.wait:after {
    background: #cbcbcb;
} /* 대기시간 라인 */
.tour_detail_info .detail_view .itinerary > li.stopover {
    position: relative;
    width: 274px;
    margin-left: 0;
} /* 숨은경유지 */
.tour_detail_info .detail_view .itinerary > li.stopover:before {
    background: url("/img/trip/flight/ico/ico_circle_gray.gif") no-repeat center top;
} /* 숨은경유지 아이콘 */
.tour_detail_info .detail_view .itinerary .stopover:after {
    position: absolute;
    top: 0;
    left: 50%;
    clear: both;
    display: block;
    width: 260px;
    height: 2px;
    margin-left: -130px;
    background: #5477e9;
    content: "";
} /* 숨은경유지 라인 */
.tour_detail_info .detail_view .itinerary > li.stopover .info {
    margin-top: 0;
}
.tour_detail_info .detail_view .itinerary > li.stopover .info em {
    position: absolute;
    top: 10px;
    left: -50%;
    width: 100%;
    color: #666;
    font-size: 12px;
}
.tour_detail_info .detail_view .itinerary .section {
    width: 174px;
    margin-top: -46px;
    text-align: center;
}
.tour_detail_info .detail_view .itinerary .date {
    display: block;
    color: #666;
    font-family: "Tahoma";
    font-size: 12px;
}
.tour_detail_info .detail_view .itinerary .time {
    display: block;
    height: 24px;
    color: #111;
    font-family: "Tahoma";
    font-size: 16px;
    line-height: 24px;
}
.tour_detail_info .detail_view .itinerary .area {
    position: relative;
    display: block;
    padding-top: 18px;
    color: #333;
    font-size: 12px;
}
.tour_detail_info .detail_view .itinerary .area:before {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    clear: both;
    display: block;
    width: 14px;
    height: 15px;
    margin-left: -7px;
    background: #fff url("/img/trip/flight/ico/ico_circle_blue.gif") no-repeat center top;
    content: "";
} /* 대기시간(대기시간은 회색 아이콘)이면서 아이콘이 파란색으로 들어가야하는 경우로 인해 처리 (height:14px이 아닌 15px 및 bg컬러 넣은 이유 : ie9, ie10 하단 아이콘 1px정도 보이는 현상) */
/* ADD 2017/01/18 START */
.tour_detail_info .detail_view .itinerary .area_sp1 {
    display: block;
    color: #666;
    font-family: "Tahoma";
    font-size: 12px;
}
.tour_detail_info .detail_view .itinerary .area_sp2 {
    display: block;
    height: 24px;
    color: #111;
    font-family: "Tahoma";
    font-size: 16px;
    line-height: 24px;
}
.tour_detail_info .detail_view .itinerary .area_stopover {
    position: relative;
    display: block;
    padding-top: 18px;
    color: #666;
    font-size: 12px;
}
.tour_detail_info .detail_view .itinerary .area_stopover:before {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    clear: both;
    display: block;
    width: 14px;
    height: 15px;
    margin-left: -7px;
    background: #fff url("/img/trip/flight/ico/ico_circle_gray.gif") no-repeat center top;
    content: "";
} /* 대기시간(대기시간은 회색 아이콘)이면서 아이콘이 파란색으로 들어가야하는 경우로 인해 처리 (height:14px이 아닌 15px 및 bg컬러 넣은 이유 : ie9, ie10 하단 아이콘 1px정도 보이는 현상) */
/* ADD 2017/01/18 END */
.tour_detail_info .detail_view .itinerary .info {
    margin-top: -22px;
}
.tour_detail_info .detail_view .itinerary .info .txt {
    display: block;
    height: 24px;
    color: #999;
    font-family: "Tahoma";
    font-size: 12px;
    text-align: center;
}
.tour_detail_info .detail_view .itinerary .info em {
    display: block;
    margin-top: 4px;
    color: #5477e9;
    font-size: 14px;
    font-style: normal;
    text-align: center;
}
.tour_detail_info .detail_view .itinerary .wait .info .txt {
    color: #666;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
} /* 경유인 경우 */
.tour_detail_info .detail_view .itinerary .wait .info em {
    color: #999;
} /* 경유인 경우 */
.tour_detail_info .detail_view .itinerary .airline {
    position: absolute;
    top: 37px;
    left: 50%;
    display: inline-block;
    padding: 1px 11px 1px 9px;
    border: 1px solid #999;
    color: #333;
    font-size: 12px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
}

/* 최저가 MAP */
.map_wrap {
    position: absolute;
    z-index: 20;
    width: 100%;
}
.map {
    position: relative;
    width: 1200px;
    height: 800px;
    margin: 0 auto;
}
.ly_map {
    position: absolute;
    z-index: 30;
    top: 50px;
    left: 0;
    width: 320px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.2);
}
.ly_map .ly_top {
    overflow: hidden;
    height: 66px;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    background: #536dfe;
} /* layer */
.ly_map .ly_top.open {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.ly_map .ly_top h1 {
    background: url("/img/trip/flight/bul/arr_9px_up_fefefe.gif") no-repeat right center;
    color: #fff;
    font-size: 20px;
}
.ly_map .ly_top.open h1 {
    background: url("/img/trip/flight/bul/arr_9px_down_fefefe.gif") no-repeat right center;
}
.ly_map .ly_top a {
    display: block;
    padding: 11px 20px 13px 18px;
    color: #fff;
    text-decoration: none;
}
.ly_map .ly_top .desc {
    display: block;
    margin-top: 1px;
    color: #b6bfff;
    font-size: 12px;
}
.ly_map .ly_cont .search_condition li {
    height: 86px;
    padding: 17px 20px 0;
    border-bottom: 1px solid #e1e1e1;
}
.ly_map .ly_cont .search_condition li .txt {
    display: block;
    color: #333;
    font-size: 14px;
}
.ly_map .ly_cont .search_condition li .select_form {
    position: relative;
    overflow: hidden;
    margin-top: 8px;
    padding-left: 40px;
    border: 1px solid #dadada;
    background: #fff;
}
.ly_map .ly_cont .search_condition li.date .select_form {
    float: left;
    width: 35%;
} /* 출발월 */
.ly_map .ly_cont .search_condition li .select_form select {
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px 0 9px 0;
    border: none;
}
.ly_map .ly_cont .search_condition li.date .section {
    float: left;
    margin: 22px 0 0 20px;
} /* 출발월 */
.ly_map .ly_cont .search_condition li.date .section .check:first-of-type {
    margin-right: 10px;
} /* 출발월 */
.ly_map .ly_cont .search_condition li.date .section .check label {
    margin-left: 2px;
}
.ly_map .ly_cont .search_condition li .select_form .ico {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url("/img/trip/flight/ico/ico_area.gif") no-repeat 10px center;
}
.ly_map .ly_cont .search_condition li.area {
    background: #f5f5f5;
}
.ly_map .ly_cont .search_condition li.date {
    background: #fbfbfb;
}
.ly_map .ly_cont .search_condition li.date .select_form .ico {
    background: url("/img/trip/flight/ico/ico_date.gif") no-repeat 10px center;
}
.ly_map .ly_cont .search_condition li.fare {
    height: 93px;
    border-bottom: none;
}
.ly_map .ly_cont .search_condition li .rangeslider {
    margin-top: 10px;
}

/* promotion */
.promotion_wrap {
    width: 1200px;
    margin: 50px auto 0;
}
.promotion_wrap .image {
    width: 100%;
}
.promotion_wrap .image img {
    width: 100%;
}
.promotion_wrap .banner {
    overflow: hidden;
    margin: 50px 0 0 -28px;
}
.promotion_wrap .banner li {
    float: left;
    margin-left: 27px;
}
.promotion_wrap .banner li img {
    width: 280px;
    height: 150px;
}

/* error 페이지 */
/* .error_page{width:1200px;height:424px;margin:50px auto 200px;border-top:1px solid #555;background:#f5f5f5;text-align:center;} */
.error_page {
    width: 100%;
    height: 424px;
    margin: 50px auto; /*border-top:1px solid #555;background:#f5f5f5;*/
    text-align: center;
}
.error_page .error {
    width: 435px;
    margin: 40px auto 0;
    padding: 155px 0 30px 0;
    border-bottom: 1px solid #dadada;
    background: url("/img/trip/flight/bg/bg_no_search.gif") no-repeat center top;
    color: #333;
    font-size: 14px;
}
.error_page .error p {
    margin-bottom: 10px;
    color: #111;
    font-size: 30px;
}
.error_page .error p .not_found {
    color: #536dfe;
}
.error_page .btn_area {
    margin-top: 40px;
}
.error_page .btn_area a {
    display: inline-block;
    width: 142px;
    height: 38px;
    background: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 38px;
    vertical-align: middle;
    text-decoration: none;
}
.error_page .btn_area a.home {
    border: 1px solid #666;
}
.error_page .btn_area a.prev {
    border: 1px solid #536dfe;
}

/* 예약내역 */
.mytmall_title h3.t_tour_reserve span {
    width: 137px;
    height: 16px;
    background: url("/img/trip/flight/mytmall_v2/mytmall_tour.gif") no-repeat 0 0;
}
.mytmall_tbl1 .deadline {
    display: block;
    margin: 7px -7px 0;
    font-size: 12px;
    letter-spacing: -1px;
}
.mytmall_tbl1 .deadline .date {
    color: #e90000;
}
.mytmall_tbl1 .contact {
    display: block;
    margin-top: 3px;
    padding-left: 15px;
    background: url("/img/trip/flight/mytmall_v2/ico_contact.gif") no-repeat left 1px;
    font-weight: bold;
}
.mytmall_title .t_desc {
    float: right;
    margin-top: 2px;
    padding-left: 7px;
    background: url("/img/trip/flight/bul/bul_squ_2px_885ca7.gif") no-repeat left 5px;
    color: #885ca9;
    font-family: "Dotum";
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    letter-spacing: -1px;
}
.mytmall_title .t_desc a {
    padding-right: 8px;
    background: url("/img/trip/flight/bul/arr_5px_right_885ca7.gif") no-repeat right 3px;
    color: #885ca9;
}

/* Community (공지사항, FAQ, 판매사 연락처) - 공통 */
.sub_inner .stit {
    margin-top: 33px;
    color: #fff;
    font-size: 32px;
    font-weight: normal;
}
.sub_inner .search_ticket {
    display: inline-block;
    margin-top: 25px;
    padding-top: 40px;
    background: url("/img/trip/flight/ico/ico_airplane.png") no-repeat center top;
    padding-bottom: 33px;
}
.sub_inner .search_ticket a {
    padding-right: 15px;
    background: url("/img/trip/flight/bul/arr_9px_right_fff.gif") no-repeat right center;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
}
.community_area {
    width: 1200px;
    margin: 50px auto 0;
}
.ctab {
    overflow: hidden;
    display: table;
    width: 100%;
    border-top: 2px solid #555;
    text-align: center;
}
.ctab li {
    display: table-cell;
    width: 33.3%;
    height: 57px;
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
    background: #f5f5f5;
    font-size: 18px;
    vertical-align: middle;
}
.ctab li:last-of-type {
    border-right: 1px solid #dadada;
}
.ctab li.on {
    border-bottom: none;
    background: #fff;
}
.ctab li a {
    display: block;
    height: 57px;
    line-height: 57px;
    text-decoration: none;
}

/* 공지사항 */
.community_area .notice {
    margin: 30px 0;
}
.notice .board {
    width: 100%;
}
.notice .board thead th {
    height: 50px;
    padding: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #e5e5e5;
    background: #f5f5f5;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}
.notice .board thead .subject {
    padding-left: 50px;
    font-weight: bold;
    text-align: left;
}
.notice .board tbody td {
    height: 50px;
    padding: 0 0 0 50px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    text-align: left;
}
.notice .board tbody td a strong {
    color: #111;
}
.notice .board tbody td.date {
    padding-left: 0;
    font-family: "Tahoma";
    text-align: center;
}
.notice .board tbody tr:last-of-type td {
    border-bottom: 1px solid #ccc;
}
.notice .board tbody .view {
    padding: 25px 50px;
    line-height: 23px;
}

.notice .board tbody .view ul {
    list-style: decimal;
}
.notice .board tbody .view ol {
    list-style: decimal;
    padding-left: 40px;
}
.notice .board tbody .view li {
    list-style: decimal;
}
.notice .board tbody .view p {
    margin: 14px 0px;
}

.notice .btn_area {
    margin-top: 40px;
    text-align: center;
}
.notice .btn_area .list {
    display: inline-block;
    width: 90px;
    height: 35px;
    background: #666;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    text-decoration: none;
}

/* FAQ */
.community_area .faq {
    margin: 30px 0;
}
.community_area .search_form {
    float: right;
    text-align: right;
}
.community_area .search_form .txt {
    margin-right: 15px;
    color: #111;
    font-size: 14px;
    vertical-align: middle;
}
.community_area .search_form select {
    width: 120px;
}
.community_area .search_form .btn {
    width: 55px;
    height: 30px;
    border: none;
    background: #666;
    color: #fff;
    line-height: 30px;
}
.faqTab {
    overflow: hidden;
    clear: both;
    padding-top: 20px;
    padding-left: 1px;
    border-bottom: 1px solid #666;
}
.faqTab li {
    float: left;
    width: 20%;
    height: 48px;
    margin-left: -1px;
    border: 1px solid #dadada;
    border-bottom: none;
    font-size: 16px;
    line-height: 48px;
    text-align: center;
}
.faqTab li.on {
    position: relative;
    border: 1px solid #666;
    background: #666;
    color: #fff;
}
.faqTab li a {
    display: block;
    height: 48px;
    line-height: 48px;
    text-decoration: none;
}
.faqTab li.on a {
    color: #fff;
}
.community_area h4.tit {
    padding: 14px 0 13px 50px;
    border-bottom: 1px solid #e5e5e5;
    background: #f5f5f5;
    color: #111;
    font-family: "Tahoma", "Malgun Gothic", "Apple SD Gothic Neo";
    font-size: 16px;
    font-weight: bold;
}
.community_area .noti ul li {
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
}
.community_area .noti ul li a {
    display: block;
    padding: 16px 0 17px 0;
    padding-left: 120px;
    text-decoration: none;
}
.community_area .noti ul li.active a {
    color: #111;
}
.community_area .noti ul li.on a {
    color: #111;
}
.community_area .noti ul li .num {
    position: absolute;
    left: 0;
    width: 120px;
    text-align: center;
}
.community_area .noti ul li .keyword {
    color: #536dfe;
}
.community_area .noti ul li .detail {
    display: none;
    padding: 25px 30px 25px 120px;
    background: #fbfbfb;
    color: #111;
    font-size: 14px;
    line-height: 23px;
    word-break: break-all;
    word-wrap: break-word;
}
.community_area .noti ul li.no_search {
    padding: 60px 0 48px 0;
    text-align: center;
}
.community_area .noti ul li.no_search .not_found {
    padding-top: 60px;
    background: url("/img/trip/flight/bg/bg_no_search_02.gif") no-repeat center top;
    font-size: 18px;
}
.community_area .noti ul li.no_search .not_found .re {
    display: block;
    margin-top: 4px;
    font-size: 14px;
}

/* 판매사 연락처 */
.community_area .contact {
    margin: 40px 0;
}
.community_area .contact .intab {
    overflow: hidden;
    padding-left: 1px;
}
.community_area .contact .intab li {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    height: 48px;
    margin-left: -1px;
    border: 1px solid #dadada;
    border-bottom: 1px solid #666;
    font-size: 16px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
}
.community_area .contact .intab li.on {
    border: 1px solid #666;
    background: #666;
    color: #fff;
    cursor: default;
}
.community_area .contact .intab li.on a {
    color: #fff;
}
.community_area .contact .intab li a {
    display: block;
    text-decoration: none;
}
.community_area .seller_contact {
    overflow: hidden;
    padding: 40px 30px;
    border: 1px solid #dadada;
}
.seller_contact ul {
    overflow: hidden;
    font-size: 0;
    text-align: center;
}
.seller_contact ul li {
    display: inline-block;
    width: 190px;
    height: 295px;
    margin-left: 22px;
    padding: 10px;
    background: #f5f5f5;
    vertical-align: top;
    margin-bottom: 22px;
}
.seller_contact ul li:first-of-type {
    margin-left: 0;
}
.seller_contact ul li:nth-of-type(5n + 6) {
    margin-left: 0;
}
.seller_contact ul li .thumb {
    display: table;
    width: 190px;
    height: 90px;
    background: #fff;
    text-align: center;
}
.seller_contact ul li .thumb span {
    display: table-cell;
    vertical-align: middle;
}
.seller_contact ul li p {
    height: 52px;
    margin-top: 17px;
    color: #333;
    font-size: 14px;
    text-align: center;
}
.seller_contact ul li .info {
    position: relative;
    border-top: 1px solid #e1e1e1;
    text-align: center;
}
.seller_contact ul li .info .ico {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 33px;
    height: 33px;
    margin: -17px 0 0 -17px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -50px 0;
    content: "";
    text-indent: -9999px;
}
.seller_contact ul li .info strong {
    display: block;
    margin-top: 30px;
    color: #333;
    font-family: "Tahoma";
    font-size: 22px;
}
.seller_contact ul li .info .desc {
    margin-top: 13px;
    font-size: 12px;
    line-height: 16px;
}

/* loading 페이지 */
.loading_area {
    overflow: hidden;
    width: 100%;
    height: 800px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-color: #eee;
}
.loading_area .ie9_intro_loading {
    display: none;
} /* ie9이하에서 show, IE10이상 hide 처리 */
.loading_area .intro_loading {
    position: relative;
    width: 402px;
    height: 402px;
    margin: 90px auto 0;
    background: url("/img/trip/flight/bg/bg_circle.png") no-repeat left top;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
}
.loading_area .intro_loading:after {
    position: absolute;
    top: 2px;
    left: 83px;
    width: 47px;
    height: 50px;
    background: url("/img/trip/flight/bg/bg_airplane.png") no-repeat left top;
    content: "";
    -webkit-transform: rotate(-120deg);
    -moz-transform: rotate(-120deg);
    -ms-transform: rotate(-120deg);
}
@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading_area .area {
    position: relative;
    width: 100%;
    margin-top: 65px;
    text-align: center;
}
.loading_area .area ul {
    display: inline-block;
    background: url("/img/trip/flight/bg/bg_travel_section.png") no-repeat center 44px;
}
.loading_area .area.type02 ul {
    background: url("/img/trip/flight/bg/bg_travel_section_02.png") no-repeat center 44px;
} /* 편도인 경우 */
.loading_area .area.type03 ul {
    background: url("/img/trip/flight/bg/bg_travel_section_03.png") no-repeat center 44px;
} /* 다구간 경우 */
.loading_area .area.type04 ul {
    margin-left: -24px;
    background: url("/img/trip/flight/bg/bg_travel_section_04.png") no-repeat center 44px;
} /* 다구간 경우_4개 지역 */
.loading_area .area ul li {
    float: left;
    width: 306px;
}
.loading_area .area ul li dt {
    color: #fff;
    font-size: 20px;
}
.loading_area .area ul li dd {
    height: 22px;
    color: #a7a8a8;
    font-family: "Tahoma";
    font-size: 12px;
}
.loading_area .area ul li dd.date {
    padding-top: 34px;
    color: #cdcdce;
    font-family: "Tahoma";
}
.loading_area .loading {
    width: 300px;
    height: 233px;
    margin: -414px auto 0;
    padding: 195px 64px 0;
    background: url("/img/trip/flight/bg/bg_inner_circle.png") no-repeat left top;
    color: #fff;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
}
.loading_area .loading .txt {
    display: block;
    font-size: 20px;
}

/* =============================================================================
   Layer
   ========================================================================== */
/* layout */
.ly_wrap {
    position: absolute;
    z-index: 10;
    top: 50px;
    border: 2px solid #26325a;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.ly_wrap .ly_area {
    position: relative;
    width: 100%;
}
.ly_wrap .ly_header {
    height: 38px;
    background: #26325a;
}
.ly_wrap .ly_header h1 {
    padding-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 35px;
}
.ly_wrap .ly_header h1 span.pipe {
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}
.ly_wrap .ly_header h1 span.text {
    padding-left: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
}
.ly_wrap .ly_header h1 span.info {
    padding-left: 10px;
    color: yellow;
    font-size: 12px;
    font-weight: normal;
    line-height: 38px;
}
.ly_wrap .ly_header h1 span.info img {
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 10px;
}
.ly_wrap .ly_header .close {
    position: absolute;
    top: 40px;
    right: 50px;
}
.ly_wrap .ly_header .close .clse {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px 94px;
}
.ly_wrap .ly_ctns {
    overflow: hidden;
    width: 100%;
}
.ly_wrap .ly_close {
    position: absolute;
    top: 7px;
    right: 9px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center;
}
.ly_wrap .ly_close a {
    display: block;
    text-indent: -9999px;
}

/* layout - 다른유형 */
.lightbox {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    border: 1px solid #666;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.lightbox .ly_area {
    position: relative;
    width: 100%;
}
.lightbox .ly_header {
    height: 38px;
    border-bottom: 1px solid #dadada;
    background: #fff;
}
.lightbox .ly_header h1 {
    padding-left: 15px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    line-height: 38px;
}
.lightbox .ly_ctns {
    padding: 0;
}
.lightbox .ly_close {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_lb_close.png") no-repeat center center;
}
.lightbox .ly_close a {
    display: block;
    width: 22px;
    height: 22px;
    text-indent: -9999px;
}

/* layout - 다른유형 */
.lightgraybox {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    border: 1px solid #666;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.lightgraybox .ly_area {
    position: relative;
    width: 100%;
}
.lightgraybox .ly_header {
    height: 48px;
    background: #555;
}
.lightgraybox .ly_ctns {
    padding: 0;
}
.lightgraybox .ly_header h1 {
    padding-left: 15px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 48px;
}
.lightgraybox .ly_close {
    position: absolute;
    top: 13px;
    right: 9px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center;
}
.lightgraybox .ly_close a {
    display: block;
    width: 22px;
    height: 22px;
    text-indent: -9999px;
}

/* 인원 및 좌석 등급 선택 */
.ly_area .btn_area {
    padding: 20px 0;
    text-align: center;
}
.ly_area .btn_area a {
    display: block;
    width: 90px;
    height: 35px;
    margin: 0 auto;
    padding: 0;
    background: #2b3769;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    text-decoration: none;
}
.ly_people_seat {
    left: 0px;
    width: 515px;
} /* 수정170120 */
.ly_people_seat .ly_sel_form {
    overflow: hidden;
    border-bottom: 1px solid #dadada;
}
.ly_people_seat .ly_sel_form .tit {
    height: 40px;
    border-bottom: 1px solid #dadada;
    color: #26325a;
    font-size: 14px;
    font-weight: bold;
    line-height: 39px;
    text-align: center;
}
.ly_sel_form .people_select {
    float: left;
    width: 344px;
    padding-bottom: 18px;
}
.ly_sel_form .num_people {
    overflow: hidden;
    padding: 17px 20px 0;
    text-align: center;
}
.ly_sel_form .num_people li {
    float: left;
    width: 88px;
    margin: 0 0 0 20px;
}
.ly_sel_form .num_people li:first-of-type {
    float: left;
    width: 88px;
    margin: 0;
}
.ly_sel_form .num_people li .name {
    color: #666;
    font-size: 12px;
    text-align: center;
    text-align: left;
}
.ly_sel_form .num_people li strong {
    display: block;
    margin-bottom: 6px;
    color: #111;
    font-size: 14px;
    font-weight: normal;
}
.ly_sel_form .num_people .num_set {
    position: relative;
    display: block;
    width: 88px;
    height: 28px;
    margin: 9px 0 0;
}
.ly_sel_form .num_people .num_set input {
    width: 28px;
    height: 26px;
    margin: 0 29px;
    padding: 0;
    border: 1px solid #d1d1d6;
    background: #fff;
    color: #333;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
    font-size: 14px;
    font-weight: normal;
    line-height: 26px;
    text-align: center;
}
.ly_sel_form .num_people .num_set .qty_minus,
.ly_sel_form .num_people .num_set .qty_plus {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 30px;
    height: 28px;
    border: 1px solid #d1d1d6;
    background: #f5f5f5;
    text-indent: -1000px;
}
.ly_sel_form .num_people .num_set .qty_minus {
    left: 0;
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
}
.ly_sel_form .num_people .num_set .qty_plus {
    left: 58px;
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
}
.ly_sel_form .num_people .num_set .qty_minus:before,
.ly_sel_form .num_people .num_set .qty_plus:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 1px;
    margin: 0 0 0 -4px;
    background: #5477e9;
    content: "";
}
.ly_sel_form .num_people .num_set .qty_plus:before {
    margin-left: -5px;
}
.ly_sel_form .num_people .num_set .qty_plus:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 9px;
    margin: -4px 0 0 -1px;
    background: #5477e9;
    content: "";
}
.ly_sel_form .num_people .num_set .qty_minus[disabled],
.ly_sel_form .num_people .num_set .qty_plus[disabled] {
    border-color: #ccc;
}
.ly_sel_form .num_people .num_set .qty_minus[disabled]:before,
.ly_sel_form .num_people .num_set .qty_plus[disabled]:before,
.ly_sel_form .num_people .num_set .qty_plus[disabled]:after {
    background-color: #ccc;
}
.ly_sel_form .guide {
    padding: 23px 0 0 20px;
    color: #666;
    font-size: 12px;
    line-height: 18px;
}
.ly_sel_form .flight_class {
    float: left;
    width: 170px;
}
.ly_sel_form .flight_class .seat {
    padding: 15px 0 10px 20px;
}
.ly_sel_form .flight_class .seat li {
    margin-top: 5px;
}
.ly_sel_form .flight_class .seat li button {
    width: 128px;
    height: 33px;
    padding: 0 10px;
    border: 1px solid #bdbdbd;
    background: #f5f5f5;
    color: #666;
    font-size: 12px;
    text-align: left;
}
.ly_sel_form .flight_class .seat li.on button {
    background: #f5f5f5 url("/img/trip/flight/bul/arr_9px_down_blue.gif") no-repeat 102px center;
    color: #333;
}

/* 체류기간 */
.ly_stay {
    left: 0;
    width: 230px;
}
.ly_stay .stay_day {
    padding: 10px 0;
}
.ly_stay .stay_day li {
    width: 100%;
    height: 31px;
}
.ly_stay .stay_day li button {
    display: block;
    width: 100%;
    height: 31px;
    padding-left: 20px;
    border: none;
    background: none;
    color: #666;
    font-size: 12px;
    line-height: 31px;
    text-align: left;
}
.ly_stay .stay_day li button.on {
    background: #5477e9;
    color: white;
}

/* 도시선택 */
.reserve_search .inbox .input_form {
    position: absolute;
    top: 1px;
    left: 0;
}
.reserve_search .inbox .input_form input {
    width: 155px;
    height: 36px;
    line-height: 36px;
    margin-left: 1px;
    padding: 0px 9px;
    border: none;
    color: #333;
    font-size: 14px;
}
.quick_search .inbox .input_form {
    position: absolute;
    top: 1px;
    left: 0;
}
.quick_search .inbox .input_form input {
    width: 113px;
    height: 26px;
    margin-left: 36px;
    padding: 11px 0 9px 9px;
    border: none;
    color: #333;
    font-size: 14px;
}
.ly_city {
    left: -1px;
    width: 600px;
} /* 수정170120 */
.ly_city_major {
    left: -1px;
    width: 1008px;
} /*수정170525 */

.x_keyword_popup.x_major_area_count_8 {
    width: 1008px;
}
.x_keyword_popup.x_major_area_count_7 {
    width: 882px;
}
.x_keyword_popup.x_major_area_count_6 {
    width: 756px;
}
.x_keyword_popup.x_major_area_count_5 {
    width: 630px;
}
.x_keyword_popup.x_major_area_count_4 {
    width: 504px;
}
.x_keyword_popup.x_major_area_count_3 {
    width: 378px;
}
.x_keyword_popup.x_major_area_count_2 {
    width: 378px;
}
.x_keyword_popup.x_major_area_count_2 .city_list ul.major_city li {
    width: 188px;
}
.x_keyword_popup.x_major_area_count_1 {
    width: 378px;
}
.x_keyword_popup.x_major_area_count_1 .city_list ul.major_city li {
    width: 377px;
}
.x_keyword_popup.x_major_area_count_0 {
    width: 378px;
}

.x_keyword_popup.x_keyword_inv_dep .x_keyword_load {
    min-height: 106px;
}

.x_keyword_popup.x_keyword_early.x_keyword_main_all .x_keyword_load {
    height: 275px;
}
.x_keyword_popup.x_keyword_early.x_keyword_main_int {
    width: 882px;
}
.x_keyword_popup.x_keyword_early.x_keyword_main_int .x_keyword_load {
    height: 275px;
}
/*.x_keyword_popup.x_keyword_early.x_keyword_search .x_keyword_load {height: 275px;}*/
.x_keyword_popup.x_keyword_early.x_keyword_inv_arr {
    width: 882px;
}
.x_keyword_popup.x_keyword_early.x_keyword_inv_arr .x_keyword_load {
    height: 530px;
    width: 882px;
}

.city_list table {
    width: 100%;
}
.city_list table th {
    height: 34px;
    padding: 0 0 0 15px;
    border-bottom: 1px solid #dadada;
    background: #e6edfd;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
}
.city_list table td {
    padding: 5px 2px;
    border-bottom: 1px solid #dadada;
    font-size: 12px;
}
.city_list table td:first-of-type {
    padding-left: 15px;
}
.city_list table td:last-of-type {
    padding-right: 20px;
}
.city_list table td a {
    display: block;
    color: #666;
    text-decoration: none;
}
.city_list table tr:last-of-type td {
    border-bottom: none;
}
.city_list ul li {
    position: relative;
    border-bottom: 1px solid #e3e3e8;
}
.city_list ul li a {
    display: block;
    padding: 18px 0 18px 20px;
    text-decoration: none;
}
.city_list ul li:last-of-type {
    border-bottom: none;
}
.city_list ul li.no_search {
    padding: 18px 0 18px 0;
    text-align: center;
}
.city_list ul li dt {
    color: #333;
    font-size: 12px;
}
.city_list ul li .highlight {
    margin: 0;
    padding: 0;
    color: #536dfe;
}
.city_list ul li dt .en {
    margin-left: 1px;
}
.city_list ul li dd {
    margin-top: 5px;
    font-size: 12px;
}
.city_list ul li dd span {
    position: relative;
    padding: 0 6px 0 0;
    background: url("/img/trip/flight/bul/bul_12px_808080.gif") no-repeat right 3px;
}
.city_list ul li dd span:last-of-type {
    margin: 0;
    padding: 0;
    background: none;
}
.city_list .city_list_title {
    display: block;
    padding: 10px 10px;
    background: #fafafa;
    color: #464956;
    border-bottom: 1px solid #dfdfdf;
    font-weight: bold;
}
.city_list .city_list_list table {
    width: 100%;
}
.city_list .city_list_list td {
    padding: 5px 3px;
    height: 24px;
    border-bottom: none;
    font-size: 12px;
    width: 25%;
    text-align: center;
}
.city_list .city_list_list td a {
    padding: 0;
}
.city_list .city_list_list td .on {
    color: #3866e6;
    text-decoration: underline;
    font-weight: bold;
}
/**.city_list .major_city {position:relative; width:100%; height:205px;}
.city_list .major_city .city_block {display:inline-block; width:130px;height:205px; border-right:1px solid #dfdfdf; border-bottom:none; float:left;}
.city_list .major_city .city_block:last-child {border-right:none;}
.city_list .major_city .city_block li {border:none;}
.city_list .major_city .city_block ul {padding:5px 0;}
.city_list .major_city .city_block li a {padding:0 0 0 0; text-indent:15px; line-height:22px;}
.city_list .major_city .city_block .on {color:#335fe3; text-decoration:underline; font-weight:bold;}*/
.city_list .major_city .city_block {
    height: 530px;
} /* 추가170615 */
.ly_wrap .city_list .major_city .city_block {
    height: 275px;
}
.city_list ul.major_city {
    overflow: hidden;
}
.city_name {
    display: block;
    font-size: 13px;
    margin-top: 8px;
    color: #29315a;
    font-weight: bold;
    text-indent: 15px;
}
.city_list ul.major_city li {
    background: #fafafa;
    display: inline-block;
    float: left;
    width: 125px;
    color: #273259;
    font-size: bold;
    border-right: 1px solid #e9e9e9;
    border-bottom: none;
}
.city_list ul.major_city li ul {
    width: 100%;
    height: 100%;
    margin: 8px 0 0 0px;
    border-top: 1px solid #ccc;
    border-right: 1px solid #e9e9e9;
    background-color: #fff;
}
.city_list ul.major_city li ul li {
    padding: 0;
    border-bottom: none;
    border-right: none;
    background: #fff;
}
.city_list ul.major_city li ul li a {
    max-width: 125px;
    padding: 4px 0 4px 0px;
    text-indent: 15px;
    font-size: 12px;
    font-weight: normal;
    background-color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ly_wrap03 .ly_area02 .city_list .none {
    color: #000;
    width: 380px;
}
.ly_wrap03 .ly_area02 .city_list .none .txt {
    width: 100%;
    height: 200px;
    background: #fafafa;
    text-align: center;
}
.ly_wrap03 .ly_area02 .city_list .none .txt i {
    display: block;
    margin: 50px auto 20px auto;
    width: 34px;
    height: 34px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -46px -35px;
} /* 170615 추가 */
.ly_wrap03 .ly_area02 .city_list .none .txt span {
    display: inline-block;
    font-size: 13px;
    color: #222222;
    font-weight: normal;
} /* 170615 추가 */
/* 달력 */
.ly_select_date {
    left: -1px;
    width: 760px;
} /* 수정170120 */
.ly_select_date .cal_wrap {
    overflow: hidden;
    padding: 20px;
}
.ly_select_date .cal_wrap .tbl_cal {
    position: relative;
    float: left;
    width: 350px;
    margin-right: 20px;
    border: 1px solid #f5f5f5;
    border-width: 0 1px 0 0;
}
.ly_select_date .cal_wrap .tbl_cal:last-of-type {
    margin-right: 0;
}
.ly_select_date .cal_wrap .tbl_cal caption {
    height: 56px;
    border: 1px solid #f5f5f5;
    background: #fafafa;
    color: #333;
    font-family: "Tahoma";
    font-size: 22px;
    line-height: 56px;
}
.ly_select_date .cal_wrap .tbl_cal caption .btn_prev {
    position: absolute;
    top: 0;
    left: 0;
    width: 47px;
    height: 56px;
    background: url("/img/trip/flight/bul/arr_11px_left_666.gif") no-repeat center center;
    color: transparent;
    font-size: 11px;
}
.ly_select_date .cal_wrap .tbl_cal caption .btn_next {
    position: absolute;
    top: 0;
    right: 0;
    width: 47px;
    height: 56px;
    background: url("/img/trip/flight/bul/arr_11px_right_666.gif") no-repeat center center;
    color: transparent;
    font-size: 11px;
}
.ly_select_date .cal_wrap .tbl_cal thead th {
    height: 28px;
    padding: 0;
    border-bottom: 1px solid #dadada;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
}
.ly_select_date .cal_wrap .tbl_cal thead th:first-of-type {
    border-left: 1px solid #f5f5f5;
}
.ly_select_date .cal_wrap .tbl_cal td {
    position: relative;
    height: 52px;
    padding: 0;
    border-bottom: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;
    color: #666;
    text-align: center;
}
.ly_select_date .cal_wrap .tbl_cal td a {
    display: block;
    min-height: 15px;
    padding: 5px 0 32px 0;
    color: #666;
    text-decoration: none;
}
.ly_select_date .cal_wrap .tbl_cal td span {
    display: block;
    min-height: 15px;
    padding: 5px 0 32px 0;
}
.ly_select_date .cal_wrap .tbl_cal td .today {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #504f98;
    color: #fff;
}
.ly_select_date .cal_wrap .tbl_cal td .start {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #5477e9;
    color: #fff;
}
.ly_select_date .cal_wrap .tbl_cal td .middle {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #5477e9;
    color: #fff;
}
.ly_select_date .cal_wrap .tbl_cal td .end {
    position: absolute;
    z-index: 5;
    top: -1px;
    left: -1px;
    width: 100%;
    border: 1px solid #2d52cb;
    background: #5477e9;
    color: #fff;
}
.ly_select_date .cal_wrap .tbl_cal td .selected {
    position: absolute;
    z-index: 4;
    top: -1px;
    left: 0;
    width: 100%;
    border: 1px solid #dde4f3;
    background: #e6edfd;
}
.ly_select_date .cal_wrap .tbl_cal td .txt {
    position: absolute;
    z-index: 6;
    right: 0;
    bottom: 5px;
    left: 0;
    margin: 0 auto;
    color: #fff;
    font-size: 11px;
}
.ly_select_date .cal_wrap .tbl_cal .sat {
    color: #5477e9;
}
.ly_select_date .cal_wrap .tbl_cal .holiday {
    color: #f43142;
}
.ly_select_date .cal_wrap .tbl_cal .disable {
    color: #999;
}

/* 찜한여정 */
.ly_tour_plan .ly_ctns {
    padding: 0 15px 15px;
}
.ly_tour_plan .ly_ctns .guide {
    padding-top: 7px;
}
.ly_tour_plan .ly_ctns .guide li {
    margin-top: 6px;
    padding-left: 8px;
    background: url("/img/trip/flight/bul/bul_squ_2px_676767.gif") no-repeat left 6px;
    font-size: 12px;
}
.ly_tour_plan {
    width: 702px;
    max-height: 576px;
}
.ly_tour_plan .plan_list {
    width: 100%;
    height: 100%;
    margin-top: 15px;
}
.ly_tour_plan .plan_list table {
    width: 100%;
    border: 1px solid #dadada;
    border-width: 1px 1px 0 1px;
}
.ly_tour_plan .plan_list table td {
    padding: 16px 0 17px 20px;
    border-bottom: 1px solid #dadada;
    text-align: left;
}
.ly_tour_plan .plan_list table td.td_clear {
    padding: 0;
    border-bottom: none;
}
.ly_tour_plan .plan_list table td.td_clear table {
    border: none;
}
.ly_tour_plan .plan_list table td.ico {
    padding-left: 0;
    text-align: center;
}
.ly_tour_plan .plan_list table td.ico .air img {
    width: 40px;
    height: 40px;
}
.ly_tour_plan .plan_list table td.area {
    padding-left: 0;
    text-align: center;
}
.ly_tour_plan .plan_list table td .cnts_scr {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 395px;
}
.ly_tour_plan .plan_list table td.area ul {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: 5px;
}
.ly_tour_plan .plan_list table td.area ul:first-of-type {
    margin-top: 0;
}
.ly_tour_plan .plan_list table td.area ul li {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #111;
    font-size: 14px;
}
.ly_tour_plan .plan_list table td.area ul li.ico {
    position: absolute;
    left: 50%;
    width: 19px;
    height: 15px;
    margin: 2px 0 0 -9px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -26px -53px;
    text-indent: -9999px;
}
.ly_tour_plan .plan_list table td.area ul li.ico02 {
    width: 19px;
    height: 6px;
    margin: 4px 0 0 -9px;
    background-position: 0 -58px;
}
.ly_tour_plan .plan_list table td.area ul li:first-of-type {
    width: 50%;
    padding-right: 20px;
    text-align: right;
}
.ly_tour_plan .plan_list table td.area ul li:last-of-type {
    width: 50%;
    padding-left: 20px;
    text-align: left;
}
.ly_tour_plan .plan_list table td .sum {
    color: #111;
    font-family: "Tahoma";
    font-size: 16px;
    font-weight: bold;
}
.ly_tour_plan .plan_list table td .sum span {
    color: #666;
    font-size: 12px;
    font-weight: normal;
}
.ly_tour_plan .plan_list table td .date {
    display: block;
    font-family: "Tahoma";
    font-size: 12px;
}
.ly_tour_plan .plan_list table td .etc {
    display: block;
    margin-top: 5px;
    font-size: 12px;
}
.ly_tour_plan .plan_list table td:last-of-type {
    position: relative;
}
.ly_tour_plan .plan_list table td .delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: url("/img/trip/flight/btn/btn_lb_close.png") no-repeat center center;
}
.ly_tour_plan .plan_list table td .delete a {
    display: block;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
}
.ly_tour_plan .plan_list table tfoot td {
    height: 32px;
    padding: 0;
    background: #f5f5f5;
    font-size: 12px;
    text-align: center;
}
.ly_tour_plan .plan_list table tfoot td .more {
    display: block;
    height: 32px;
    margin-top: -1px;
    border-top: 1px solid #dadada;
    color: #111;
    line-height: 32px;
    text-decoration: none;
}
.ly_tour_plan .plan_list table tfoot td .more .num {
    display: inline-block;
    margin: -3px 0 0 2px;
    padding-right: 14px;
    background: url("/img/trip/flight/ico/ico_add.gif") no-repeat right center;
    color: #999;
    font-family: "Tahoma";
    font-size: 10px;
    vertical-align: middle;
}

/* 요금규정 */
.ly_fare_rule {
    width: 527px;
    max-height: 610px;
}
.ly_scroll {
    overflow: auto;
    overflow-x: hidden;
    max-height: 540px;
    padding: 15px;
}
.tbl_fare_list {
    width: 100%;
    border-top: 1px solid #555;
}
.tbl_fare_list th {
    padding: 12px 0 12px 15px;
    border-bottom: 1px solid #e5e5e5;
    background: #f5f5f5;
    color: #333;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    text-align: left;
}
.tbl_fare_list td {
    padding: 12px 10px 12px 15px;
    border-bottom: 1px solid #e5e5e5;
    color: #666;
    font-size: 12px;
    line-height: 16px;
}
.list_loading {
    position: fixed;
    top: 38px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 120;
}
.list_loading > span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    margin-top: -56px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
}
.list_loading > span:before {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 13px;
    border-radius: 30px;
    background: #fff url("/img/trip/flight/m/ico/ico_loading.gif") no-repeat 50% 50%;
    background-size: 40px 40px;
    content: "";
}

/* 상세요금 */
.ly_detail_fare {
    width: 450px;
    max-height: 730px;
}
.ly_detail_fare .ly_scroll {
    max-height: 660px;
}
.ly_detail_fare .fare_list_wrap .card_info {
    padding: 8px 10px 11px;
    background: #555;
    color: #fff;
    font-size: 14px;
    text-align: left;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info {
    width: 100%;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info caption {
    overflow: visible;
    padding: 13px 0 8px 10px;
    border-bottom: 1px solid #dadada;
    color: #111;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info caption .num {
    color: #536dfe;
    font-family: "Tahoma";
    font-size: 14px;
    font-weight: bold;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody tr:first-of-type td {
    padding-top: 13px;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody tr:last-of-type td {
    padding-bottom: 11px;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody td {
    padding: 4px 0 4px 0;
    text-align: left;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody td.txt {
    padding-left: 10px;
    font-size: 12px;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody td.num {
    font-size: 12px;
    text-align: right;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody td.fare {
    padding-right: 10px;
    text-align: right;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tbody td.fare strong {
    color: #333;
    font-family: "Tahoma";
    font-size: 14px;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tfoot th {
    padding: 12px 0 12px 10px;
    border-bottom: 1px solid #dadada;
    background: #f5f5f5;
    color: #333;
    font-size: 14px;
    text-align: left;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tfoot td {
    padding: 12px 10px 12px 0;
    border-bottom: 1px solid #dadada;
    background: #f5f5f5;
    font-size: 12px;
    text-align: right;
}
.ly_detail_fare .fare_list_wrap .tbl_detail_info tfoot td.fare strong {
    color: #536dfe;
    font-family: "Tahoma";
    font-size: 16px;
}
.ly_detail_fare .fare_list_wrap .total {
    overflow: hidden;
    margin-top: 15px;
    padding: 9px 10px 13px 10px;
    border-top: 2px solid #555;
    background: #f5f5f5;
    border-bottom: 1px solid #dadada;
}
.ly_detail_fare .fare_list_wrap .total .tit {
    float: left;
    margin-top: 5px;
    color: #111;
    font-size: 14px;
}
.ly_detail_fare .fare_list_wrap .total.privia .tit .discount {
    display: block;
    margin-top: 8px;
    font-size: 13px;
} /* privia인 경우 */
.ly_detail_fare .fare_list_wrap .total .fare {
    float: right;
    font-size: 16px;
    text-align: right;
}
.ly_detail_fare .fare_list_wrap .total .fare strong {
    color: #f33245;
    font-family: "Tahoma";
    font-size: 22px;
}
.ly_detail_fare .fare_list_wrap .total.privia strong {
    color: #333;
} /* privia인 경우 */
.ly_detail_fare .fare_list_wrap .total.privia .discount {
    display: block;
    margin-top: -3px;
} /* privia인 경우 */
.ly_detail_fare .fare_list_wrap .total.privia .discount strong {
    color: #f33245;
} /* privia인 경우 */
.ly_detail_fare .fare_list_wrap .total .fare .guide {
    margin-top: 7px;
    font-size: 12px;
    color: #777777;
}

.ly_detail_fare .fare_list_wrap.detail_dsc .tbl_detail_info.cpn_dsc tfoot tr:first-of-type th.txt {
    border-bottom: none;
    padding-top: 14px;
    padding-bottom: 8px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .tbl_detail_info.cpn_dsc tfoot tr:last-of-type th.txt {
    padding-top: 0;
    padding-bottom: 14px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .tbl_detail_info.cpn_dsc tfoot tr:first-of-type td.fare {
    border-bottom: none;
    padding-top: 14px;
    padding-bottom: 8px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .tbl_detail_info.cpn_dsc tfoot tr:last-of-type td.fare {
    padding-top: 0;
    padding-bottom: 14px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .tbl_detail_info.cpn_dsc tfoot tr:last-of-type td.fare strong {
    color: #111111;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .tit {
    margin-top: 0;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .tit .name {
    display: block;
    line-height: 27px;
    margin-bottom: 2px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .fare .priceWrap {
    display: block;
    margin-bottom: 2px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .fare .priceWrap .val {
    display: inline-block;
    color: #111111;
    font-size: 16px;
    line-height: 24px;
    height: 27px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .fare .priceWrap .not_confirm {
    display: inline-block;
    color: #777777;
    font-size: 12px;
    line-height: 25px;
    height: 27px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .fare .priceWrap .txt {
    color: #777777;
    font-size: 12px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .fare .priceWrap:last-of-type .val {
    color: #f43142;
    font-size: 22px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .total .fare .priceWrap:last-of-type .txt {
    font-size: 16px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .fare_noti {
    padding: 12px 10px;
    border-bottom: 1px solid #dadada;
    background: #f5f5f5;
    color: #333;
    margin-top: 5px;
    font-size: 12px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .fare_noti .noti_cont {
    margin-left: 5px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .fare_noti .noti_cont:before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: #666666;
    position: absolute;
    margin: 7px 0 0 -6px;
}
.ly_detail_fare .fare_list_wrap.detail_dsc .fare_noti .accent {
    font-weight: bold;
}

/* 주간최저가 */
.ly_weekly_prices {
    width: 1010px;
    max-height: 550px;
}
.ly_weekly_prices .price_info_wrap {
    overflow: hidden;
    padding: 30px;
}
.price_info_wrap .guide {
    float: left;
    color: #333;
    font-size: 14px;
}
.price_info_wrap .price_step {
    float: right;
    width: 360px;
    height: 22px;
    background: url("/img/trip/flight/sub/bg_weekly_prices.gif") no-repeat right top;
    text-indent: -9999px;
}
.price_info_wrap .price_step li {
    float: left;
    width: 80px;
    height: 22px;
    margin-left: 10px;
}
.price_info_wrap .tbl_price_info {
    float: left;
    width: 100%;
    margin-top: 20px;
    border: 1px solid #bababa;
}
.price_info_wrap .tbl_price_info thead th {
    height: 40px;
    padding: 0;
    border-right: 1px solid #c9c9c9;
    background: #ebebeb;
    color: #666;
    font-family: "Tahoma";
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}
.price_info_wrap .tbl_price_info thead th:last-of-type {
    border-right: none;
}
.price_info_wrap .tbl_price_info tbody th {
    height: 50px;
    padding: 0;
    border-top: 1px solid #dadada;
    border-right: 1px solid #dadada;
    background: #fbfbfb;
    color: #666;
    font-family: "Tahoma";
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}
.price_info_wrap .tbl_price_info tbody td {
    height: 50px;
    padding: 0;
    border-top: 1px solid #dadada;
    border-right: 1px solid #dadada;
    font-size: 14px;
    text-align: center;
}
.price_info_wrap .tbl_price_info tbody td:last-of-type {
    border-right: none;
}
.price_info_wrap .tbl_price_info .bg {
    background: #999;
    color: #fff;
}
.price_info_wrap .tbl_price_info .day {
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.price_info_wrap .tbl_price_info tbody td button {
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 5px;
    border: none;
    color: #333;
    font-family: "Tahoma";
    font-size: 14px;
    text-align: right;
}
.price_info_wrap .tbl_price_info tbody td button span {
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.price_info_wrap .tbl_price_info tbody td button.high {
    background: #f28473;
} /* 최고 */
.price_info_wrap .tbl_price_info tbody td button.lowest {
    background: #dff9e7;
} /* 최저 */
.price_info_wrap .tbl_price_info tbody td button.lowest:before {
    position: absolute;
    top: 50%;
    left: 10px;
    clear: both;
    display: block;
    width: 30px;
    height: 26px;
    margin: -13px 0 0 0;
    background: url("/img/trip/flight/ico/ico_lowest.png") no-repeat center top;
    content: "";
}
.price_info_wrap .tbl_price_info tbody td button.low {
    background: #dff9e7;
} /* 최저가 아이콘 안 들어가는 경우 */
.price_info_wrap .tbl_price_info tbody td button.low:before {
    display: none;
}
.price_info_wrap .tbl_price_info tbody td button.below {
    background: #fbf8c1;
} /* 평균이하 */
.price_info_wrap .tbl_price_info tbody td button.above {
    background: #ffd99d;
} /* 평균이상 */

/* 조회시간 만료 */
.ly_timeout {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 300px;
    padding: 15px;
    border: 1px solid #666;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    text-align: center;
}
.ly_timeout .timeout {
    padding-top: 85px;
    background: url("/img/trip/flight/ico/ico_time.gif") no-repeat center top;
    color: #333;
    font-size: 20px;
}
.ly_timeout .desc {
    padding: 13px 0 18px 0;
    border-bottom: 1px solid #dadada;
    font-size: 14px;
    line-height: 18px;
}
.ly_timeout .btn_re {
    margin-top: 20px;
    text-align: center;
}
.ly_timeout .btn_re a {
    display: inline-block;
    width: 90px;
    height: 35px;
    background: #666;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    text-decoration: none;
}

/* new메인추가 (170118) */
.tour_bodyArea .bg {
    width: 100%;
    background-color: #eee;
    background-image: url("/img/trip/flight/main/bg_mainvisual02.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
}
.comparebox .comparecon {
    width: 1200px;
    margin: 0 auto;
    padding: 67px 0 0 0;
}
.comparebox .comparecon h3 {
    margin-bottom: 20px;
    padding-left: 43px;
    background: url("/img/trip/flight/main/bg_compare_tit.png") no-repeat left 6px;
    font-size: 32px;
    color: #fff;
    font-weight: normal;
}
.bg .wh {
    text-indent: -9999em;
    display: inline-block;
    width: 290px;
    height: 33px;
    background: url("/img/trip/flight/main/txt.png") no-repeat 0 0 !important;
}

.tabbox {
    overflow: hidden;
}
.tabbox ul.tabs {
    float: left;
}
.tabbox ul.tabs li {
    width: 160px;
    height: 48px;
    float: left;
    margin-right: 1px;
    background: url("/img/trip/flight/main/bg_tab_off.png") no-repeat center top;
    text-align: center;
}
.tabbox ul.tabs li a {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #9ba3b7;
}
.tabbox ul.tabs li a span {
    height: 48px;
    line-height: 47px;
    display: inline-block;
    padding-left: 23px;
}
.tabbox ul.tabs li a span.tabico01 {
    background: url("/img/trip/flight/main/bg_tab01_off.png") no-repeat left 17px;
}
.tabbox ul.tabs li a span.tabico02 {
    background: url("/img/trip/flight/main/bg_tab02_off.png") no-repeat left 20px;
}
.tabbox ul.tabs li a span.tabico03 {
    background: url("/img/trip/flight/main/bg_tab03_off.png") no-repeat left 16px;
}
.tabbox ul.tabs li a span.tabico04 {
    background: url("/img/trip/flight/main/bg_tab04_off.png") no-repeat left 12px;
    padding-left: 36px;
}
.tabbox ul.tabs li.on {
    width: 160px;
    height: 48px;
    float: left;
    background: url("/img/trip/flight/main/bg_tab_on.png") no-repeat center top;
    text-align: center;
}
/* .tabbox ul.tabs li.on a{ display:block; width:100%; padding:14px 0;  font-size:16px; color:#fff; } */
.tabbox ul.tabs li.on a {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #fff;
} /* 수정170120 */
.tabbox ul.tabs li.on a span {
    display: inline-block;
    padding-left: 23px;
}
.tabbox ul.tabs li.on a span.tabico01 {
    background: url("/img/trip/flight/main/bg_tab01_on.png") no-repeat left 17px;
}
.tabbox ul.tabs li.on a span.tabico02 {
    background: url("/img/trip/flight/main/bg_tab02_on.png") no-repeat left 20px;
}
.tabbox ul.tabs li.on a span.tabico03 {
    background: url("/img/trip/flight/main/bg_tab03_on.png") no-repeat left 16px;
}
.tabbox ul.tabs li.on a span.tabico04 {
    background: url("/img/trip/flight/main/bg_tab04_on.png") no-repeat left 12px;
    padding-left: 36px;
}
.tabbox ul.reser {
    float: right;
    margin-top: 4px;
}
.tabbox ul.reser li {
    float: left;
    margin-right: 5px;
    text-align: center;
    width: 150px;
    height: 40px;
    opacity: 0.92;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}
.tabbox ul.reser li + li {
    margin-right: 1px;
}
.tabbox ul.reser li a {
    display: block;
    width: 100%;
    padding: 11px 0 11px;
    font-size: 14px;
    color: #5461c6;
}
.tabbox ul.reser li a span {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    margin-left: -5px;
}
.tabbox ul.reser li a .arrow {
    background: url("/img/trip/flight/main/bg_main.png") no-repeat right -25px;
    background-size: 100px;
    padding-right: 6px;
    padding-top: 11px;
    margin: 4px 0 0 4px;
    position: absolute;
}
.tabbox02 {
    height: 48px;
    overflow: visible;
}
.reserve_search {
    position: relative;
    padding: 18px 20px 20px;
    background: url("/img/trip/flight/main/bg_reserve_search.png") repeat;
}
.reserve_search .fl_option {
    position: relative;
    z-index: 10;
}
.reserve_search .fl_option:after {
    clear: both;
    display: block;
    content: "";
}
.reserve_search .fl_option > li {
    float: left;
    margin-top: 10px;
    width: 100%;
}
.reserve_search .fl_option > li:first-of-type {
    margin-top: 0;
}
.reserve_search .fl_option .selform {
    width: 100%;
    float: left;
}
.reserve_search .fl_option .selform .fildbox {
    float: left;
}
.reserve_search .fl_option .selform .fildbox .field {
    float: left;
}
.reserve_search .fl_option .selform .fildbox strong.fieldtit {
    display: none;
    margin-bottom: 6px;
    text-align: left;
}
.reserve_search .fl_option .selform .fildbox .ply {
    display: table-cell;
    height: 27px;
    float: left;
    margin: 0px 0 0 -1px;
    padding: 11px 7px 0;
    border: 1px solid #dadada;
    background: #fff;
}
.reserve_search.round_trip .fl_option .selform .fildbox .ply,
.reserve_search.group_fare .fl_option .selform .fildbox .ply {
    padding: 11px 6px 0;
}
.reserve_search .inbox {
    display: table;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    float: left;
    margin-left: -1px;
    border: 1px solid #dadada;
    background: #fff;
}

.reserve_search .inbox.disabled {
    background-color: #f8f8f8;
    border-color: #dddddd;
}
.reserve_search .inbox.disabled a[role="button"] {
    color: #bbbbbb;
    cursor: default;
    cursor: not-allowed;
}

.reserve_search .inbox.on {
    position: relative;
    z-index: 10;
    border: 1px solid #5477e9;
}
/* .reserve_search .inbox.on a{ color:#5477e9 !important; } */
/*.reserve_search .inbox:first-of-type{margin-left:0;}*/
.reserve_search .inbox .ico {
    z-index: -1;
    display: inline-block;
    background: url("/img/trip/flight/main/ico_cal_main.png") no-repeat left top;
    vertical-align: middle;
    text-indent: -9999px;
}
.reserve_search .inbox .ico_not {
    z-index: -1;
    display: inline-block;
    background: url("/img/trip/flight/main/ico_cal_main.png") no-repeat left top;
    vertical-align: middle;
    text-indent: -9999px;
}
.reserve_search .inbox a[role="button"] {
    display: table-cell;
    padding-left: 15px;
    color: #333;
    font-size: 14px;
    text-align: left;
    vertical-align: middle;
    text-decoration: none;
}
.reserve_search .inbox.area {
    width: 178px;
} /* 도시 선택 */
.reserve_search .inbox.area .ico {
    width: 16px;
    height: 20px;
    margin-right: 9px;
    background-position: left -26px;
}
.reserve_search .inbox.area .txt {
    display: inline-block;
}
.reserve_search .inbox.area .txtmd {
    display: inline-block;
    vertical-align: -1px;
    color: #999999;
}
.reserve_search .inbox.date {
    width: 160px;
} /* 날짜 선택 */
.reserve_search .inbox.date span.txt {
    display: inline-block;
    line-height: 38px;
    height: 38px;
}
.reserve_search .inbox.md {
    width: 242px;
} /* 다구간날짜 선택 */
.reserve_search .inbox.md span.txt {
    display: inline-block;
    line-height: 38px;
    height: 38px;
}
.reserve_search .inbox.md .ico {
    z-index: -1;
    display: inline-block;
    background: url("/img/trip/flight/main/ico_cal_main.png") no-repeat left top;
    vertical-align: middle;
    text-indent: -9999px;
    margin-top: 6px;
}
.reserve_search .inbox.md .ico {
    width: 29px;
    height: 26px;
    margin-right: 9px;
    float: right;
}
.reserve_search .inbox.period {
    width: 160px;
    background: #fff url("/img/trip/flight/bul/arr_6px_down_333.gif") no-repeat 107px center;
} /* 기간 선택 */
.reserve_search .inbox.date .ico {
    width: 29px;
    height: 26px;
    margin-right: 9px;
    float: right;
    margin-top: 6px;
}
.reserve_search .inbox.date .ico_not {
    width: 29px;
    height: 26px;
    margin-right: 9px;
    float: right;
    margin-top: 6px;
}
.reserve_search .inbox.set {
    width: 300px;
} /* 인원 및 좌석 선택 */
.reserve_search .inbox.set .ico {
    float: left;
    width: 15px;
    height: 20px;
    margin-right: 9px;
    background-position: 0px -95px;
}
.reserve_search .inbox.set .ico {
    display: none;
}
.reserve_search .inbox.set .txt {
    float: left;
    margin-right: 13px;
    padding-right: 15px;
    background: url("/img/trip/flight/main/bg_inbox.gif") no-repeat right 13px;
    height: 38px;
    line-height: 38px;
}
.reserve_search .inbox.set .txt:last-of-type {
    margin-right: 0;
    background: none;
}
.reserve_search .fl_option .del {
    position: absolute;
    top: 0;
    right: 0;
    display: table;
    width: 48px;
    height: 48px;
    border-left: 1px solid #dadada;
}
.reserve_search .fl_option .del a {
    display: table-cell;
    width: 48px;
    vertical-align: middle;
}
.reserve_search .fl_option .del a span {
    display: block;
    width: 11px;
    height: 11px;
    margin: 0 auto;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px -14px;
    vertical-align: middle;
    text-indent: -9999px;
}
.reserve_search .fl_option .btn_search {
    float: right;
    width: 132px;
    height: 40px;
    background: #26325a;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
}
.reserve_search .fl_option .btn_search.long_text {
    font-size: 13px;
}
.reserve_search .fl_option .btn_search.mdbtn {
    width: 132px;
}
.reserve_search .fl_option .btn_search a {
    display: block;
    color: #fff;
    text-decoration: none;
}
.reserve_search .fl_option a.planadd {
    display: block;
    color: #273259;
    border: 1px solid #273259;
    font-weight: bold;
    text-decoration: none;
    bottom: 0;
    right: 0;
    width: 130px;
    height: 38px;
    padding: 0;
}
.reserve_search .fl_option .ckbox {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 78px;
    height: 40px;
    margin-left: -1px;
    padding-top: 10px;
    border: 1px solid #dadada;
    text-align: center;
}
.reserve_search .fl_option .ckbox .ck_box {
    vertical-align: middle;
}
.reserve_search .fl_option .ckbox .label_txt {
    color: #333;
    font-size: 14px;
}
.reserve_search .fl_option .ckbox label {
    position: absolute;
    top: 12px;
    left: 400px;
    height: 18px !important;
    padding: 0 0 0 22px;
    background: url("/img/trip/flight/ico/sp_trip_input.png") no-repeat 0 -39px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}
.reserve_search .fl_option .ckbox span {
    color: #333333;
}
.reserve_search .fl_option .ckbox input[type="checkbox"] {
    margin: 3px 0 0 -30px;
}
.reserve_search .fl_option .ckbox input[type="checkbox"]:checked + label {
    background: url("/img/trip/flight/ico/sp_trip_input.png") no-repeat 0 -60px;
}
.reserve_search .fl_option .ckbox input[type="checkbox"]:checked + label span {
    font-weight: bold;
    color: #5770fe;
}
.reserve_search .fl_option .trip_section {
    position: relative;
    clear: both;
    padding-top: 10px;
}
.reserve_search .fl_option .trip_section label {
    position: absolute;
    top: 10px;
    left: 0;
    height: 18px !important;
    padding: 0 0 0 22px;
    background: url("/img/trip/flight/ico/sp_trip_input.png") no-repeat 0 -40px;
    font-size: 12px;
    cursor: pointer;
} /* 2017-06-05 추가 */
.reserve_search .fl_option .trip_section span {
    color: #333333;
}
.reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label {
    background: url("/img/trip/flight/ico/sp_trip_input.png") no-repeat 0 -60px;
}
.reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label span {
    font-weight: bold;
    color: #5770fe;
}
.reserve_search .fl_option .trip_section a {
    display: inline-block;
    padding-left: 12px;
    margin-left: 38px;
    background: url("/img/trip/flight/main/bg_trip_section.gif") no-repeat left 1px;
    color: #333333;
    position: absolute;
}
.reserve_search .fl_option .trip_section span.label_name {
    font-weight: 500;
    font-size: 11px;
}
.reserve_search .fl_option .trip_section span.label_tip::before {
    content: "";
    border-left: 1px solid #cccccc;
    display: inline-block;
    margin: 0px 5px 0px 5px;
    height: 9px;
}
.reserve_search .fl_option .trip_section span.label_tip {
    font-size: 12px;
}
.reserve_search .fl_option .trip_section input[type="checkbox"]:checked + label span.label_tip {
    font-weight: normal;
    color: #333333;
}
.reserve_search .fl_option .trip_section02 {
    position: relative;
    clear: both;
    padding: 10px 0 0 159px;
}
.reserve_search .fl_option .trip_section02 label {
    position: absolute;
    top: 10px;
    left: 168px;
    height: 18px !important;
    padding: 0 0 0 22px;
    background: url("/img/trip/flight/ico/sp_trip_input.png") no-repeat 0 -40px;
    font-size: 12px;
    height: 9px;
} /* 2017-06-05 추가 */
.reserve_search .fl_option .trip_section02 input[type="checkbox"] {
    margin-left: 10px;
}
.reserve_search .fl_option .trip_section02 span {
    color: #333333;
}
.reserve_search .fl_option .trip_section02 input[type="checkbox"]:checked + label {
    background: url("/img/trip/flight/ico/sp_trip_input.png") no-repeat 0 -60px;
}
.reserve_search .fl_option .trip_section02 input[type="checkbox"]:checked + label span {
    font-weight: bold;
    color: #5770fe;
}
.reserve_search .fl_option .trip_section02 a {
    display: inline-block;
    padding-left: 12px;
    margin-left: 33px;
    background: url("/img/trip/flight/main/bg_trip_section.gif") no-repeat left 2px;
    color: #333333;
}
.mr8 {
    margin-right: 8px !important;
}
/* maincontents */
.maincontents {
    width: 1200px;
    margin: 0 auto 40px auto;
}
/* 최근검색 */
.inner_recent {
    position: relative;
    display: table;
    margin: 19px 0 18px;
}
.inner_recent.empty_content {
    margin: 0;
}
.inner_recent:after {
    clear: both;
    display: block;
    content: "";
}
.inner_recent .column_l h3 {
    position: absolute;
    top: 0;
    left: 0;
}
.inner_recent .column_r h3 {
    margin-bottom: 10px;
}
.inner_recent .pick {
    position: absolute;
    top: 3px;
    right: 30px;
    font-family: "Tahoma";
    font-size: 14px;
    border: 1px solid #e4e4e4;
    padding: 4px 10px;
}
.inner_recent .pick a {
    text-decoration: none;
    color: #000;
}
.inner_recent .pick span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -52px -50px;
    vertical-align: 1px;
    text-indent: -9999px;
}
.inner_recent .column_l {
    position: relative;
    float: left;
    width: 790px;
    padding: 38px 30px 0 0;
}
.inner_recent .no_search {
    color: #666;
    font-size: 16px;
    text-align: center;
    line-height: 164px;
    border-top: 1px solid #9c9c9c;
    border-bottom: 1px solid #e5e5e5;
}
.inner_recent .no_search .ico {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -47px -36px;
    vertical-align: middle;
}
.inner_recent .column_r {
    float: left;
}
.inner_recent .list {
    border-top: 1px solid #9c9c9c;
}
.inner_recent .list > li {
    position: relative;
    overflow: hidden;
    padding: 16px 0;
    border-bottom: 1px solid #e5e5e5;
}
.inner_recent .list > li:first-of-type {
    margin-top: 0;
}
.inner_recent .list li .area {
    display: table;
}
.inner_recent .list li .area .reserico {
    display: inline-block;
    float: left;
    border: 1px solid #6a69eb;
    color: #6a69eb;
    height: 20px;
    line-height: 20px;
    width: 40px;
    text-align: center;
}
.inner_recent .list li .area ul {
    position: absolute;
    float: left;
    display: table-cell;
    margin-left: 50px;
    vertical-align: middle;
    height: 54px;
    line-height: 54px;
    top: 0;
}
.inner_recent .list li .area ul li {
    display: table-cell;
    min-width: 30px;
    color: #111;
    font-size: 16px;
    font-weight: bold;
}
.inner_recent .list li .area ul li:nth-of-type(2).ico {
    width: 50px;
    height: 54px;
    background: url("/img/trip/flight/ico/ico_flight_route.png") no-repeat center;
    text-indent: -9999px;
}
.inner_recent .list li .area ul li:nth-of-type(2).ico02 {
    height: 6px;
    background: url("/img/trip/flight/ico/ico_flight_route_02.png") no-repeat center;
}
.inner_recent .list li .area ul li:nth-of-type(2).ico03 {
    height: 17px;
    background: url("/img/trip/flight/ico/ico_flight_route_03.png") no-repeat center;
}
.inner_recent .list li .middle {
    position: absolute;
    right: 250px;
    top: 0;
    height: 54px;
    line-height: 54px;
}
.inner_recent .list li .middle .date {
    font-size: 14px;
    right: 0;
    display: block;
}
.inner_recent .list li .etc {
    position: absolute;
    top: 0;
    right: 0;
    height: 54px;
    line-height: 54px;
}
.inner_recent .list li .etc .people {
    display: block;
    font-size: 13px;
    text-align: right;
}
.inner_recent .banner {
    position: relative;
    width: 600px;
    height: 140px;
    background: url("/img/trip/flight/main/bg_lowest.jpg") no-repeat left top;
}
.inner_recent .banner h4 {
    padding: 43px 0 0 43px;
    color: #111;
    font-size: 22px;
    font-weight: normal;
}
.inner_recent .banner h4 .point {
    color: #536dfe;
}
.inner_recent .banner .txt_rolling {
    position: absolute;
    right: 0;
    bottom: 1px;
    left: 0;
    zoom: 1;
    width: 598px;
    height: 40px;
    margin: 0 auto;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    filter: alpha(opacity=50);
}
.inner_recent .banner .txt_rolling ul {
    overflow: hidden;
    width: 100%;
    height: 40px;
}
.inner_recent .banner .txt_rolling ul li {
    overflow: hidden;
    display: table;
    width: 100%;
    height: 40px;
}
.inner_recent .banner .txt_rolling ul li a {
    display: table-cell;
    color: #fff;
    vertical-align: middle;
    text-decoration: none;
}
.inner_recent .banner .txt_rolling ul li .txt {
    margin-right: 12px;
    font-size: 16px;
}
.inner_recent .banner .txt_rolling ul li .txt:last-of-type {
    margin-right: 0;
}
/* 오늘의 땡처리 top5 */
.inner_recent0 {
    margin-top: 33px;
}
.inner_recent0 .tit {
    text-align: center;
    font-size: 24px;
    color: #000;
    font-weight: bold;
}
.inner_recent0 .article_top5 {
    margin: 15px 0 0 0;
}
.inner_recent0 .article_top5 .top5_top {
    display: block;
    width: 100%;
    height: 8px;
    background: url("/img/trip/flight/main/top.png") no-repeat 0 0;
}
.inner_recent0 .article_top5 .top5_bottom {
    display: block;
    width: 100%;
    height: 8px;
    background: url("/img/trip/flight/main/bottom.png") no-repeat 0 0;
}
.inner_recent0 .article_top5 ul {
    display: block;
    width: 100%;
    border-top: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    overflow: hidden;
}
.inner_recent0 .article_top5 li {
    display: inline-block;
    width: 239px;
    float: left;
    border-right: 1px dotted #dadada;
}
.inner_recent0 .article_top5 li:last-child {
    margin: 0;
    border-right: 1px solid #dadada;
}
.inner_recent0 .article_top5 li a:hover {
    text-decoration: none;
}
.inner_recent0 .top5_banner {
    text-align: center;
    margin-right: 1px;
}
.inner_recent0 .title-ja {
    width: 239px;
    height: 90px;
    border-right: 1px solid #fff;
    background: url("/img/trip/flight/main/JPN_MO_SH.jpg") no-repeat center;
} /* 땡처리 일본 배경이미지 */
.inner_recent0 .title-ch {
    width: 239px;
    height: 90px;
    border-right: 1px solid #fff;
    background: url("/img/trip/flight/main/CHI_MO_SH.jpg") no-repeat center;
} /* 땡처리 중국 배경이미지 */
.inner_recent0 .title-asi {
    width: 239px;
    height: 90px;
    border-right: 1px solid #fff;
    background: url("/img/trip/flight/main/ASIA_MO_SH.jpg") no-repeat center;
} /* 땡처리 아시아 배경이미지 */
.inner_recent0 .title-oce {
    width: 239px;
    height: 90px;
    border-right: 1px solid #fff;
    background: url("/img/trip/flight/main/SOPA_MO_SH.jpg") no-repeat center;
} /* 땡처리 대양주 배경이미지 */
.inner_recent0 .title-afr {
    width: 240px;
    height: 90px;
    border-right: 1px solid #fff;
    background: url("/img/trip/flight/main/KOR_MO_SH.jpg") no-repeat center;
} /* 땡처리 지방출발 배경이미지 */
.inner_recent0 .title-usa {
    width: 240px;
    height: 90px;
    border-right: 1px solid #fff;
    background: url("/img/trip/flight/main/AMCA_MO_SH.jpg") no-repeat center;
} /* 땡처리 미주 배경이미지 */
.inner_recent0 .title-txt {
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    line-height: 90px;
}
.inner_recent0 .info .area {
    display: block;
    height: 45px;
    margin-top: 25px;
}
.inner_recent0 .info span {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    vertical-align: middle;
}
.inner_recent0 .info .area .ico {
    display: inline-block;
    width: 18px;
    height: 14px;
    margin: -3px 10px 0;
    background: url("/img/trip/flight/main/ico_ply.png") no-repeat 0 0;
    text-indent: -9999px;
}
.inner_recent0 .info .tour_circle {
    width: 50px;
    height: 50px;
}
.inner_recent0 .info .tour {
    font-size: 15px;
}
.inner_recent0 .sum {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    padding: 10px 0 30px 0;
}
.inner_recent0 .sum b {
    color: #ff0000;
}
.inner_recent0 .date {
    display: block;
    width: 85%;
    margin: 0 auto;
    padding: 15px 10px;
    border-top: 1px solid #dadada;
}
.inner_recent0 .date p {
    text-align: left;
    color: #000;
    font-size: 14px;
}
.inner_recent0 .info .txt {
    display: table-cell;
    width: 238px;
    height: 265px;
    vertical-align: middle;
} /* 170615 추가 */
.inner_recent0 .info .txt span {
    display: inline-block;
    font-size: 15px;
    color: #222222;
    font-weight: normal;
} /* 170615 추가 */
.inner_recent0 .info .txt i {
    display: block;
    margin: 0 auto 10px auto;
    width: 34px;
    height: 34px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -46px -35px;
} /* 170615 추가 */
/* 지역별 특가 */
.comparebox2 {
    position: relative;
    width: 100%;
    background: url("/img/trip/flight/main/bg_area.jpg") no-repeat center top;
    background-size: 100%;
}
.comparebox2 .comparecon {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 33px 0 45px 0;
}
.comparebox2 .comparecon .tit {
    text-align: center;
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin-bottom: -3px;
}
.comparebox2 .comparecon .gnb {
    position: absolute;
    top: 45px;
    right: 0px;
}
.comparebox2 .comparecon .gnb li {
    display: inline-block;
}
.comparebox2 .comparecon .gnb li a {
    font-size: 14px;
    margin-left: 4px;
}
.comparebox2 .comparecon .gnb li a:hover {
    text-decoration: none;
}
.comparebox2 .comparecon .gnb .on {
    font-weight: bold;
    color: #000;
}
.comparebox2 .comparecon .gnb .tx_bar {
    margin: 0 0 0 10px;
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #666;
    vertical-align: -1px;
}
.comparebox2 .comparecon .tab {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px auto 15px auto;
}
.comparebox2 .comparecon .tab ul {
    overflow: hidden;
}
.comparebox2 .comparecon .tab .width6 {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 0 0 -1px;
    text-align: center;
    background: #3d3d3e;
    float: left;
    border: 1px solid #000;
    width: 16.7%;
    padding: 20px 0;
} /*탭이6개 일때 수정170616*/
.comparebox2 .comparecon .tab .width5 {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    background: #3d3d3e;
    float: left;
    border: 1px solid #000;
    width: 20%;
    height: 65px;
    line-height: 65px;
    border-right: none;
} /*탭이5개 일때 수정170616*/
.comparebox2 .comparecon .tab .width5:last-of-type {
    border-right: 1px solid #000;
}
.comparebox2 .comparecon .tab li a {
    display: inline-block;
    color: #eee;
    font-size: 18px;
    text-decoration: none;
    width: 100%;
}
.comparebox2 .comparecon .tab .on {
    font-weight: bold;
    color: #fff;
    display: inline;
    border-bottom: 2px solid #fff;
    padding-bottom: 3px;
}
.comparebox2 .comparecon .area-list {
    position: relative;
    display: block;
}
.comparebox2 .comparecon .area-list .table-sub {
    display: block;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    background: #f7f7f7;
}
.comparebox2 .comparecon .area-list .table-sub span {
    display: inline-block;
    vertical-align: middle;
    color: #000;
    font-weight: bold;
    text-align: center;
    height: 44px;
    line-height: 44px;
    font-size: 13px;
}
.comparebox2 .comparecon .area-list .table-sub .ico {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -3px;
    width: 14px;
    height: 14px;
    background: url("/img/trip/flight/main/ico_-.png") no-repeat 0 0;
}
.comparebox2 .comparecon .area-list .tour-list {
    position: relative;
    display: block;
    background: #fff;
}
.comparebox2 .comparecon .area-list .tour-list ul {
    max-height: 645px;
    overflow-y: auto;
    padding-top: 1px;
    margin-top: -1px;
}
.comparebox2 .comparecon .area-list .tour-list li {
    display: block;
}
.comparebox2 .comparecon .area-list .tour-list .list0 {
    margin-top: -1px;
    border: 1px solid #dadada;
    overflow: hidden;
    cursor: pointer;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .air {
    display: inline-block;
    height: 68px;
    font-size: 14px;
    color: #000;
    text-align: center;
    line-height: 68px;
    border-right: 1px solid #dadada;
    float: left;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .air img {
    vertical-align: middle;
    margin: -3px 3px 0 0;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .info {
    display: inline-block;
    height: 68px;
    border-right: 1px solid #dadada; /* padding:20px 0 0 0; */
    text-align: center;
    float: left;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .info .ico {
    display: inline-block;
    width: 18px;
    height: 14px;
    margin: 0 3px;
    background: url("/img/trip/flight/main/ico_ply.png") no-repeat 0 0;
    text-indent: -9999px;
    line-height: 34px;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .info .info-date {
    display: inline-block;
    width: 277px;
    height: 51px;
    margin-top: 17px;
    text-align: center;
    font-size: 13px;
    color: #000;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .info .info-date b {
    font-weight: bold;
    font-size: 15px;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .term {
    display: inline-block;
    height: 68px;
    font-size: 15px;
    color: #000;
    line-height: 68px;
    text-align: center;
    border-right: 1px solid #dadada;
    float: left;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .fee {
    display: inline-block;
    height: 68px;
    line-height: 68px;
    text-align: left; /* text-indent:45px; */
    float: left;
    text-align: center;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .fee .sum {
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: bold;
}
.comparebox2 .comparecon .area-list .tour-list .list0 .fee .sum b {
    color: #ff0000;
}
.comparebox2 .comparecon .area-list .tour-list .list0.on {
    position: relative;
    z-index: 3;
    margin-top: -1px;
    border: 1px solid #536dfe;
}
.comparebox2 .comparecon .area-list .tour-list .txt {
    display: table-cell;
    width: 1200px;
    height: 300px;
    vertical-align: middle;
    text-align: center;
} /* 170615 추가 */
.comparebox2 .comparecon .area-list .tour-list .txt span {
    display: inline-block;
    font-size: 15px;
    color: #222222;
    font-weight: normal;
} /* 170615 추가 */
.comparebox2 .comparecon .area-list .tour-list .txt i {
    display: block;
    margin: 0 auto 30px auto;
    width: 34px;
    height: 34px;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -46px -35px;
} /* 170615 추가 */
/* popup */
.ly_help {
    position: absolute;
    right: 32px;
    top: 35px;
    z-index: 10;
    margin-top: 6px;
    border: 1px solid #888888;
    background: #fff;
    text-align: left;
}
.ly_help p {
    padding: 10px 15px;
    font-size: 12px;
    color: #000;
    line-height: 18px;
    letter-spacing: -1px;
}
.ly_help p:first-of-type {
    font-weight: bold;
    padding-bottom: 0;
}
.ly_help .edge_cen {
    position: absolute;
    top: 0;
    left: 50%;
    width: 9px;
    height: 6px;
    margin: -6px 0 0 -5px;
    background: url("/img/trip/flight/ico/bg_layer_edge.gif") no-repeat 0 0;
    font-size: 0;
}
/*  */
.ev_banner {
    position: relative;
    overflow: hidden;
    width: 380px;
    height: 80px;
}
.ev_banner:first-of-type {
    margin-bottom: 6px;
}
.ev_banner .slides li a {
    width: 380px;
    height: 80px;
    display: block;
    text-align: center;
}
.ev_banner .slides li a img {
    width: 300px;
    height: 80px;
}
.ev_banner .bx-controls-direction {
    height: 0px;
    margin-top: -40px;
}
.ev_banner .bx-controls-direction .bx-prev,
.ev_banner .bx-controls-direction .bx-next {
    background: none;
    width: 40px;
    height: 80px;
}
.ev_banner .bx-controls-direction .bx-prev {
    background-position: left -111px;
    left: 0;
}
.ev_banner .bx-controls-direction .bx-next {
    background-position: right -111px;
    right: 0;
}
.ev_banner .bx-controls-direction .bx-prev:before,
.ev_banner .bx-controls-direction .bx-prev:after {
    position: absolute;
    left: 18px;
    content: "";
    height: 8px;
    width: 1px;
    background-color: #9a9a9a;
}
.ev_banner .bx-controls-direction .bx-prev:before {
    transform: rotate(45deg);
    bottom: 38.5px;
}
.ev_banner .bx-controls-direction .bx-prev:after {
    transform: rotate(-45deg);
    top: 38.5px;
}
.ev_banner .bx-controls-direction .bx-next:before,
.ev_banner .bx-controls-direction .bx-next:after {
    position: absolute;
    right: 18px;
    content: "";
    height: 8px;
    width: 1px;
    background-color: #9a9a9a;
}
.ev_banner .bx-controls-direction .bx-next:before {
    transform: rotate(45deg);
    top: 38.5px;
}
.ev_banner .bx-controls-direction .bx-next:after {
    transform: rotate(-45deg);
    bottom: 38.5px;
}

/* 여행하기 좋은도시 top5 */
.tour_area {
    overflow: hidden;
    width: 100%;
    margin-bottom: 17px;
}
.tour_area .tour_nav {
    position: relative;
    float: left;
    width: 300px;
    height: 300px;
    margin-bottom: 2px;
    background: #6a69eb;
}
.tour_area .tour_nav h3 {
    z-index: 1;
    height: 100px;
    margin: 30px 0 0 30px;
    background: url("/img/trip/flight/main/txt_recommend_city5.png") no-repeat left top;
    text-indent: -9999px;
}
.tour_area .tour_nav .date {
    position: absolute;
    left: 32px;
    font-size: 20px;
    color: yellow;
    top: 135px;
}
.tour_area .tour_nav .btn_direction {
    margin: 58px 0 0 30px;
}
.tour_area .tour_nav .btn_direction .prev {
    display: inline-block;
    width: 20px;
    height: 34px;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat left -116px;
    text-indent: -9999px;
}
.tour_area .tour_nav .btn_direction .next {
    display: inline-block;
    width: 20px;
    height: 34px;
    margin-left: 40px;
    background: url("/img/trip/flight/btn/sp_prev_next.png") no-repeat right -116px;
    text-indent: -9999px;
}
.tour_area .tour_nav .area {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    margin-left: 1px;
}
.tour_area .tour_nav .area li {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 149px;
    height: 55px;
    margin: 0 0 -1px -1px;
    border: 1px solid #8a9cfe;
    background: #6d83fe;
    font-size: 16px;
    line-height: 55px;
    text-align: center;
}
.tour_area .tour_nav .area li.on {
    border: 1px solid #445ad4;
    background: #465ee7;
}
.tour_area .tour_nav .area li a {
    display: block;
    color: #fff;
    text-decoration: none;
}
.tour_list ul li {
    position: relative;
    float: left;
    margin: 0 0 2px 2px;
}
.tour_list ul li:nth-of-type(3) {
    margin-left: 0;
}
.tour_list ul li:first-of-type .city_intro {
    width: 596px;
    height: 300px;
    text-align: right;
}
.tour_list ul li:nth-of-type(2) .city_intro {
    width: 300px;
    height: 300px;
    text-align: left;
}
.tour_list ul li:nth-of-type(3) .city_intro {
    width: 300px;
    height: 300px;
    text-align: left;
}
.tour_list ul li:nth-of-type(4) .city_intro {
    width: 300px;
    height: 300px;
    text-align: left;
}
.tour_list ul li:last-of-type .city_intro {
    width: 596px;
    height: 300px;
    text-align: left;
}
.tour_list ul li a {
    display: block;
    text-decoration: none;
}
.tour_list ul li .city_intro .moveArea {
    bottom: -110px;
    position: relative;
    height: 110px;
    overflow: hidden;
}
.tour_list ul li .dimmd {
    width: 100%;
    height: 300px;
    position: absolute;
    background: black;
    opacity: 0.5;
    display: none;
}

/* .tour_list ul li .city{position:relative;float:left;margin:152px 0 0 40px;color:#fff;font-size:22px;} */
/* .tour_list ul li .city:after{position:absolute;bottom:-18px;left:0;display:block;width:20px;height:1px;background:#fff;content:'';} */
/* .tour_list ul li:first-of-type .city{ margin:55px 40px 0 40px; } */
/* .tour_list ul li:first-of-type .city:after{ left:auto;  width:20px;} */
/* .tour_list ul li .desc{clear:both;padding:33px 40px 0 40px;color:#b4b7c3;font-size:16px;line-height:24px; text-align:left; } */
.tour_list ul li .city {
    position: relative;
    float: left;
    margin: 55px 40px 0 40px;
    color: #fff;
    font-size: 22px;
}
.tour_list ul li .city:after {
    position: absolute;
    bottom: -18px;
    left: 0;
    display: block;
    width: 20px;
    height: 1px;
    background: #fff;
    content: "";
}
.tour_list ul li:first-of-type .city:after {
    left: auto;
    width: 20px;
}
.tour_list ul li .desc {
    clear: both;
    padding: 33px 40px 0 40px;
    color: #b4b7c3;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    overflow: hidden;
}
.tour_list ul li:first-of-type .desc {
    font-size: 16px;
}
.tour_list ul li .sum {
    position: absolute;
    right: auto;
    bottom: 37px;
    left: 40px;
    color: #fff;
    font-size: 14px;
}
.tour_list ul li:first-of-type .sum {
    bottom: 44px;
    left: 40px;
}
.tour_list ul li .sum strong {
    font-family: "Tahoma";
    font-size: 34px;
    font-weight: normal;
}
.tour_list .guide {
    margin-top: 25px;
    font-size: 14px;
}

/* 요즘어디가지 */
.tour_city {
    position: relative;
    width: 100%;
    height: 712px;
    margin-top: 23px;
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #eee;
} /* 인기도시 */
.tour_city .txt {
    display: block;
    height: 98px;
    padding: 70px 0 50px 0;
    background: url("/img/trip/flight/main/txt_tour_city.png") no-repeat center 70px;
    text-align: center;
    text-indent: -9999px;
}
.tour_city .tour_banner {
    position: absolute;
    right: 0;
    left: 0;
    overflow: hidden;
    margin: 0 auto;
}
.tour_banner ul li {
    position: relative;
    height: 436px;
    background: #fff;
    cursor: pointer;
}
.tour_banner ul li .thumb {
    position: absolute;
    bottom: -4px;
    background-color: #f7f7f7;
}
.tour_banner ul li .thumb img {
    width: 230px;
    height: 240px;
}
.tour_banner ul li a {
    display: block;
    text-decoration: none;
}
.tour_banner ul li .travel {
    display: table;
    width: 100%;
    height: 196px;
}
.tour_banner ul li .info {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.tour_banner ul li .info .ico {
    display: block;
    width: 35px;
    height: 17px;
    margin: 0 auto;
    background: url("/img/trip/flight/ico/sp_main_etc.png") no-repeat -2px -46px;
    text-align: center;
    text-indent: -9999px;
}
.tour_banner ul li .info .area {
    margin-top: 12px;
    color: #333;
    font-size: 16px;
}
.tour_banner ul li .info .sum {
    margin-top: 18px;
    padding-top: 11px;
    background: url("/img/trip/flight/bul/bul_1px_666.gif") no-repeat center top;
    font-size: 14px;
    font-weight: bold;
}
.tour_banner ul li .info .sum strong {
    display: block;
    margin-bottom: 3px;
    color: #111;
    font-size: 30px;
    font-weight: normal;
}
.tour_city .tour_banner .bx-controls-direction {
    height: 0;
}
.tour_banner .bx-controls-direction .bx-prev {
    left: 15px;
    background-position: left -116px;
}
.tour_banner .bx-controls-direction .bx-next {
    right: 15px;
    background-position: right -116px;
}
.tour_banner .bx-controls-direction .bx-prev,
.tour_banner .bx-controls-direction .bx-next {
    height: 34px;
    margin-top: -12px;
}
.tour_city p.travel_txt {
    position: absolute;
    margin-left: -240px;
    bottom: 25px;
    left: 50%;
    color: #ffffff;
}

/* 다구간 */
.mdtype {
    margin-top: 16px;
}
.mdtype .ply {
    margin-top: 0 !important;
}

.mdtype a.closed {
    display: inline-block;
    margin-top: 6px;
}

a.planadd {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 168px;
    padding: 14px 0;
    border: 1px solid #d4d4d4;
    background: #fff; /* color:#d4d4d4; */
    text-align: center;
    font-size: 14px;
}
a.planadd em {
    display: inline-block;
    padding-left: 22px;
    background: url("/img/trip/flight/main/ico_planadd.gif") no-repeat left 11px;
    height: 38px;
    line-height: 38px;
}

/* 해외항공_기본 */
.sub_position {
    position: relative;
}
.sub_searchbox {
    width: 100%;
    border-bottom: 1px solid #dadada;
}
.sub_searchbox .tabbox {
    position: relative;
    width: 1200px;
    margin: 33px auto 0 auto;
} /* margin: 35px auto 0 auto 에서 수정 */
.sub_searchbox .sub_searchconbox {
    background: #fafafa;
}
.sub_searchbox .sub_searchcon {
    width: 1200px;
    margin: 0 auto 0 auto;
}

ul.reser_info {
    position: absolute;
    top: 16px;
    right: 50%;
    margin-right: -580px;
}
ul.reser_info li {
    float: left;
    margin-left: 23px;
    color: #fff;
}
ul.reser_info li a {
    display: block;
    color: #fff;
    text-decoration: none;
}
ul.reser_info li.select a {
    height: 17px;
    padding: 1px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left 0;
    font-family: "Tahoma";
    font-size: 13px;
}
ul.reser_info li.select a.no_wish {
    height: 17px;
    padding: 1px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info_dark.png") no-repeat left 0;
    font-family: "Tahoma";
    font-size: 13px;
    cursor: text;
    color: #cccfd4;
}
ul.reser_info li.select.on a {
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -23px;
    color: #6e84fe;
}
ul.reser_info li.url a {
    height: 14px;
    padding: 2px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -48px;
    font-size: 12px;
}
ul.reser_info li.no_url {
    height: 14px;
    padding: 2px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -69px;
    color: #cccfd4;
    font-size: 12px;
}
.ml0 {
    margin-left: 0 !important;
}

/**********************************************여기서부터 추가된 style 입니다 ***********************************************/

.search-info {
    position: relative;
    display: block;
    margin: 10px 0 0;
}
.search-info .gray {
    color: #666;
}
.search-info .red {
    color: #ff0000;
}
.search-info b {
    color: #000;
}
.comparebox .background {
    background: #fafafa;
}
.reserve_search {
    position: relative;
}
/**.reserve_search .inh {position:relative; height:40px;}
.reserve_search .inbox.date .idate{position:absolute; right:0; top:10px; z-index:1;}**/
/* .reserve_search .wid {width:319.5px !important; position:relative;} */

.tour_bodyArea .bg {
    width: 100%;
    background-color: #eee;
    background-image: url("/img/trip/flight/main/bg_mainvisual02.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
}
.ly_wrap02 {
    position: absolute;
    display: inline-block;
    width: 400px;
    z-index: 10;
    top: 50px;
    border: 2px solid #26325a;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.ly_wrap02 .ly_area02 {
    position: relative;
    width: 100%;
}
.ly_wrap02 .ly_header02 {
    height: 38px;
    background: #26325a;
}
.ly_wrap02 .ly_header02 h1 {
    padding-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 35px;
}
.ly_wrap02 .ly_header02 h1 span.pipe {
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}
.ly_wrap02 .ly_header02 h1 span.text {
    padding-left: 10px;
    color: yellow;
    font-size: 12px;
    font-weight: normal;
    line-height: 38px;
}
.ly_wrap02 .ly_header02 h1 span.info {
    padding-left: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
}
.ly_wrap02 .ly_header02 h1 span.info img {
    vertical-align: middle;
    margin: -4px 3px 0;
}
.ly_wrap02 .ly_header02 .close {
    position: absolute;
    top: 40px;
    right: 50px;
}
.ly_wrap02 .ly_header02 .close .clse {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px 94px;
}
.ly_wrap02 .ly_ctns {
    overflow: hidden;
    width: 100%;
}
.ly_wrap02 .ly_close {
    position: absolute;
    top: 7px;
    right: 9px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center;
}
.ly_wrap02 .ly_close a {
    display: block;
    text-indent: -9999px;
}

.ly_wrap03 {
    position: absolute;
    display: inline-block;
    width: auto;
    min-width: 378px;
    z-index: 10;
    top: 50px;
    border: 2px solid #26325a;
    background: #fff;
    font-family: "Malgun Gothic", "Apple SD Gothic Neo";
}
.ly_wrap03 .ly_area02 {
    position: relative;
    width: 100%;
}
.ly_wrap03 .ly_header02 {
    height: 38px;
    background: #26325a;
}
.ly_wrap03 .ly_header02 h1 {
    padding-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 35px;
}
.ly_wrap03 .ly_header02 h1 span.pipe {
    padding-left: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 38px;
}
.ly_wrap03 .ly_header02 h1 span.text {
    padding-left: 10px;
    color: yellow;
    font-size: 12px;
    font-weight: normal;
    line-height: 38px;
}
.ly_wrap03 .ly_header02 h1 span.info {
    padding-left: 8px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
}
.ly_wrap03 .ly_header02 h1 span.info img {
    vertical-align: middle;
    margin: -4px 3px 0;
}
.ly_wrap03 .ly_header02 .close {
    position: absolute;
    top: 40px;
    right: 50px;
}
.ly_wrap03 .ly_header02 .close .clse {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("/img/trip/flight/ico/sp_quick_search.png") no-repeat -24px 94px;
}
.ly_wrap03 .ly_ctns {
    position: relative;
    height: 100%;
}
.ly_wrap03 .ly_close {
    position: absolute;
    top: 7px;
    right: 9px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_ly_close.png") no-repeat center center;
}
.ly_wrap03 .ly_close a {
    display: block;
    text-indent: -9999px;
}
/*.li4 {width:504px;}*/
/*.li5 {width:630px;}*/
/*.li6 {width:756px;}*/
/*.li7 {width:882px;}*/
.btn_area02 {
    position: relative;
    text-align: center;
    display: block;
    margin: 30px 0;
}
.btn_area02 a {
    padding: 10px 20px;
    background: #2b3769;
    color: #fff;
}
.ly_people_seat02 {
    width: 340px;
}
ul.reser_info02 {
    position: absolute;
    top: 5px;
    right: 50%;
    margin-right: -580px;
}
ul.reser_info02 li {
    float: left;
    margin-left: 23px;
    color: #fff;
}
ul.reser_info02 li a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
}
ul.reser_info02 li.select a {
    font-family: "Tahoma";
    font-size: 13px;
}
ul.reser_info02 li.select a.no_wish {
    height: 17px;
    padding: 1px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info_dark.png") no-repeat left 0;
    font-family: "Tahoma";
    font-size: 13px;
    cursor: text;
    color: #cccfd4;
}
.ml0 {
    margin-left: 0 !important;
}
ul.reser_info02 .reser_li {
    padding: 2px 10px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    border-right: 1px solid #000;
    margin-left: 0 !important;
    background: url("/img/trip/flight/main/bg_reser_li.png") repeat;
}
ul.reser_info02 .reser_li:last-child {
    border-right: none;
}
ul.reser_info02 .txt {
    display: inline-block;
}
ul.reser_info02 .select {
    height: 17px;
    padding: 1px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left 0;
}
ul.reser_info02 .select.on {
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -23px;
}
ul.reser_info02 .url {
    height: 14px;
    padding: 2px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -48px;
}
ul.reser_info02 .url.on {
    height: 14px;
    padding: 2px 0 0 23px;
    background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -69px;
}

.name02 {
    font-size: 20px !important;
    font-weight: bold;
}
.air_info02 {
    top: 30px !important;
}
.red {
    color: #ff0000 !important;
}
.lowest_airline .fix {
    position: absolute;
    width: 156px;
    height: 156px;
}
.lowest_airline .fix .select {
    position: relative;
    display: inline-block;
    width: 156px;
    height: 156px;
    background: url("/img/trip/flight/main/fix.png") no-repeat 0 0;
} /*170619*/
.lowest_airline .fix .select a {
    color: #536dfe;
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 100%;
} /*170619*/
.lowest_airline .fix .select.on {
    z-index: 9;
    display: inline-block;
    width: 156px;
    height: 156px;
    background: url("/img/trip/flight/main/fix_on.png") no-repeat 0 0;
}
.lowest_airline .fix .select.on .date02 {
    display: block;
    margin-top: 10px;
}
.air_top {
    top: 20px !important;
}
.lowest_airline .fix .select.on p {
    display: none;
}
.lowest_airline .lowest_list02 {
    margin-left: 155px;
}
.lowest_airline .lowest_list ul li .air_info .name03 {
    display: block;
    padding-bottom: 10px;
    color: #333;
    font-size: 16px;
}
.lowest_airline .lowest_list ul li:first-of-type .air_info .name03 {
    color: #536dfe;
} /* 모든항공사 */
.lowest_airline .fix .select .date02 {
    color: #536dfe;
}
.lowest_airline .air_info03 {
    position: absolute;
    top: 30px;
    right: 0;
    left: 0;
    margin: 0 auto;
}
.lowest_airline .air_info03 .date {
    font-weight: bold;
    font-size: 15px;
}
.lowest_airline .air_info03 .date02 {
    font-size: 15px;
}
.lowest_airline .air_info03 p {
    display: inline-block;
    border: 1px solid #cccccc;
    color: #444;
    margin-top: 5px;
    padding: 4px 7px 2px 7px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-size: 12px;
}
.lowest_airline .air_info03 .name {
    display: block;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}
.lowest_airline .area_info {
    position: relative;
    margin-top: 100px;
    font-size: 16px;
}
.lowest_airline .area_info strong {
    display: block;
}
.lowest_airline .area_info p {
    display: inline-block;
    border: 1px solid #cccccc;
    color: #444;
    margin-top: 5px;
    padding: 4px 7px 2px 7px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-size: 12px;
}
.lowest_airline .area_info .price {
    font-weight: bold;
    font-size: 22px;
}
.date_wrap {
    position: relative;
}
.date_wrap .date-title {
    background: #f8fdff;
    padding: 0 12px;
    height: 36px;
    line-height: 35px;
}
.date_wrap .date-title h2 {
    font-size: 14px;
    color: #000;
    font-weight: normal;
}
.tour_result_wrap .result_list .airline_info .airline_top .pick02 {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    width: 35px;
    height: 35px;
    background: url("/img/trip/flight/ico/sp_my_pick02.png") no-repeat 0 -35px;
    text-indent: -9999px;
}
.tour_result_wrap .result_list .airline_info .airline_top .pick02 a {
    display: block;
    height: 35px;
    text-indent: -9999px;
}
.tour_result_wrap .result_list .airline_info .airline_top .pick02.on {
    background-position: 0 0;
}
.underline {
    border-bottom: 1px solid #eee;
    padding: 0 0 25px 0;
    margin-top: 0;
}
.tour_result_wrap .result_list .seller {
    position: relative;
    float: Left;
    width: 115px;
    height: 215px;
    border-right: 1px solid #dadada;
    text-align: center;
}
.tour_result_wrap .result_list .seller .seller_info {
    position: relative;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.tour_result_wrap .result_list .seller .ico {
    display: block;
}
.tour_result_wrap .result_list .tour-date {
    position: relative;
    float: Left;
    width: 122px;
    height: 215px;
    border-right: 1px solid #dadada;
    font-size: 15px;
    text-align: center;
}
.tour_result_wrap .result_list .seat {
    position: relative;
    float: Left;
    width: 74px;
    height: 215px;
    border-right: 1px solid #dadada;
    text-align: center;
    font-size: 14px;
}
.tour_result_wrap .result_list .seat02 {
    position: relative;
    float: Left;
    width: 74px;
    text-align: center;
}
.tour_result_wrap .result_list .discount {
    position: relative;
    float: Left;
    width: 0;
    text-align: center;
    min-height: 15px;
    display: inline-block;
}
.tour_result_wrap .result_list li.cpn .discount {
    width: 74px;
}
.tour_result_wrap .result_list .discount .down {
    border: 1px solid #ff3334;
    display: block;
    width: 58px;
    height: 23px;
    color: #ff3334;
    line-height: 23px;
    margin: -4px 0 0 9px;
}
.tour_result_wrap .result_list .fee {
    position: relative;
    float: Left;
    width: 135px;
    font-size: 15px;
    border-right: 1px solid #dadada;
    text-align: center;
}
.tour_result_wrap .result_list .tour-date span {
    display: block;
    line-height: 215px;
    height: 215px;
}
.tour_result_wrap .result_list .seat span {
    display: block;
    line-height: 215px;
    height: 215px;
}
.tour_result_wrap .result_list .fee span {
    display: block;
    line-height: 215px;
    height: 215px;
    color: #111;
    font-family: "Tahoma";
}

/*** 결과 리스트 더보기 버튼 ***/
.tour_result_wrap button.btn_list_more {
    width: 100%;
    height: 60px;
    margin-top: -1px;
    border: 1px solid #d5d6e0;
    font-size: 15px;
    font-weight: bold;
    color: #000;
    text-align: center;
    background-color: #fff;
}
.tour_result_wrap .btn_list_more:after {
    display: inline-block;
    overflow: hidden;
    width: 14px;
    height: 9px;
    margin-left: 7px;
    vertical-align: middle;
    content: "";
    background: url("/img/trip/flight/bul/sp_abroad_hotel.png") no-repeat -212px -137px;
}
.tour_result_wrap .btn_list_more:hover,
.btn_list_more:focus {
    background: #f7f8f9;
}

.result_list .rate_info .btn_more .more .num02 {
    display: inline-block;
    width: 7px;
    height: 7px;
    margin: -3px 0 0 0;
    padding-right: 7px;
    background: url("/img/trip/flight/ico/ico_add.gif") no-repeat right center;
    vertical-align: middle;
}
.padding {
    margin-bottom: 10px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline {
    margin-top: 55px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .none {
    display: none !important;
}
/*** 땡처리 안내 배너 ***/
.floatMenu {
    position: fixed;
    top: 45%;
    right: 0px;
    width: 250px;
    margin-right: -255px;
    background: #fff;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    z-index: 10;
}
.floatMenu .sub {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #cccccc;
}
.floatMenu .sub h2 {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: -1px;
}
.floatMenu .sub h2 b {
    color: #ff0000;
}
.floatMenu .sub .ly_close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_lb_close.png") no-repeat center center;
}
.floatMenu .sub .ly_close a {
    display: block;
    text-indent: -9999px;
}
.floatMenu .popup_info {
    position: relative;
    padding: 10px;
}
.floatMenu .popup_info:hover {
    cursor: pointer;
}
.floatMenu .popup_info .lavel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.floatMenu .popup_info .circle-bg {
    display: inline-block;
    padding: 25px 0;
    width: 71px;
    background: #eee no-repeat center center;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    text-align: center;
    vertical-align: middle;
}
.floatMenu .popup_info .circle-bg span {
    color: #fff;
    font-size: 15px;
    letter-spacing: -1px;
    padding: 30px 0;
}
.floatMenu .popup_info .info {
    display: inline-block;
    margin: 0 0 0 6px;
    letter-spacing: -1px;
    vertical-align: middle;
}
.floatMenu .popup_info .info span {
    font-size: 14px;
}
.floatMenu .popup_info .info h2 {
    font-size: 16px;
    margin-top: 4px;
}
.floatMenu .popup_info .info h2 b {
    color: #ff0000;
}
/*** 땡처리 안내 말풍선 ***/
.tabbox-popup {
    position: absolute;
    top: -50px;
    left: 620px;
    padding: 18px 20px 10px 7px;
    background: #fff;
    border: 1px solid #888888;
    z-index: 20;
}
.tabbox-popup p {
    font-size: 11px;
    letter-spacing: -1px;
}
.tabbox-popup p b {
    color: #ff0000;
}
.tabbox-popup .edge_cen {
    position: absolute;
    bottom: -11px;
    left: 4px;
    width: 11px;
    height: 11px;
    margin: -6px 0 0 -5px;
    background: url("/img/trip/flight/ico/bg_layer_edge02.gif") no-repeat 0 0;
    font-size: 0;
}
.tabbox-popup .ly_close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_lb_close.png") no-repeat center center;
}
.tabbox-popup .ly_close a {
    display: block;
    text-indent: -9999px;
}
/*** 알림 팜업 ***/
.ly_timeout .desc02 {
    padding: 35px 0 25px 0;
    font-size: 14px;
    line-height: 18px;
}
.ly_timeout .ly_close {
    position: absolute;
    top: 3px;
    right: 5px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_lb_close.png") no-repeat center center;
}
.ly_timeout .ly_close a {
    display: block;
    text-indent: -9999px;
}
.ly_timeout .btn_re {
    margin-top: 20px;
    text-align: center;
}
.ly_timeout .btn_re a {
    display: inline-block;
    width: 90px;
    height: 35px;
    background: #666;
    color: #fff;
    font-size: 14px;
    line-height: 35px;
    text-decoration: none;
}
.ly_timeout .btn_re02 a {
    width: auto;
    padding: 0 20px;
}
.ly_timeout02 {
    width: 400px;
    text-align: left;
}
.tabbox02 {
    height: 48px;
    overflow: visible;
}
.reserve_search .inbox .input_form02 {
    position: absolute;
    top: 1px;
    left: 0;
}
.reserve_search .inbox .input_form02 input {
    width: 165px;
    height: 26px;
    margin-left: 1px;
    padding: 4px 4px 6px;
    border: none;
    color: #333;
    font-size: 14px;
}
.ecj {
    text-indent: -9999em;
    position: absolute;
    display: inline-block;
    width: 57px;
    height: 20px;
    top: 0px;
    left: 0px;
    background: url("/img/trip/flight/ico/ico_popup.png") no-repeat 0 0;
}
.tour_result_wrap .result_list .seller .img_size {
    width: 95px;
}
/*** 항공권 없을때 ***/
.no_result_search .btn_area {
    position: relative;
    text-align: center;
}
.no_result_search .btn_area a {
    display: inline-block;
    width: 142px;
    height: 38px;
    background: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 38px;
    vertical-align: middle;
    text-decoration: none;
    border: 1px solid #536dfe;
}
/*** 찜한목록 플로팅 ***/
.list_floating {
    position: absolute;
    top: 10px;
    left: -90px;
    width: 74px;
    height: 75px;
    background: url("/img/trip/flight/main/floating.png") no-repeat 0 0;
    z-index: 10;
}
.list_floating p {
    text-align: center;
}
.list_floating .floating_tt {
    margin-top: 30px;
}
.list_floating strong {
    text-align: center;
    color: #536dfe;
    font-size: 15px;
}
.list_floating.on {
    width: 74px;
    height: 75px;
    background: url("/img/trip/flight/main/floating_on.png") no-repeat 0 0;
}
.list_floating.on strong {
    text-decoration: underline;
}
.date_wrap .result_list .date-title.fixed {
    position: fixed;
    top: 120px;
    background: #f8fdff;
    padding: 0 12px;
    width: 1174px;
    border: 1px solid #dadada;
    border-top: none;
    z-index: 3;
} /*170623추가*/
.date-title.fixed {
    position: fixed;
    top: 50px;
    background: #f8fdff;
    padding: 8px 12px;
    width: 1174px;
    border: 1px solid #dadada;
    z-index: 3;
} /*170623추가*/

/*** 왕복 실시간 조회하기 ***/
.no_result_search.bg_none {
    position: relative;
    margin: 50px auto;
    padding: 50px 0;
    background: none;
}
.no_result_search .btn_area03 {
    display: block;
    width: 500px;
    padding: 20px 0;
    margin: 0 auto 30px auto;
    background: #fff;
    text-align: center;
    border: 1px solid #536dfe;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.no_result_search .btn_area03 .lavel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.no_result_search .btn_area03 .circle-bg {
    display: inline-block;
    padding: 38px 0;
    width: 100px;
    margin: 0 auto;
    background: #eee no-repeat center center;
    -webkit-border-radius: 56px;
    border-radius: 56px;
    text-align: center;
    vertical-align: middle;
}
.no_result_search .btn_area03 .circle-bg span {
    font-size: 16px;
    color: #fff;
}
.no_result_search .btn_area03 a {
    font-weight: bold;
    color: #536dfe;
    font-size: 20px;
    margin-left: 20px;
}
.no_result_search .btn_area03 b {
    font-size: 23px;
}
.low_wrap {
    display: block;
    width: 500px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    z-index: 10;
}
.low_wrap .sub {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #cccccc;
}
.low_wrap .sub h2 {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: -1px;
}
.low_wrap .sub h2 b {
    color: #ff0000;
}
.low_wrap .sub .ly_close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 22px;
    height: 22px;
    background: url("/img/trip/flight/btn/btn_lb_close.png") no-repeat center center;
}
.low_wrap .sub .ly_close a {
    display: block;
    text-indent: -9999px;
}
.low_wrap .popup_info {
    position: relative;
    padding: 20px 40px;
}
.low_wrap .popup_info .lavel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.low_wrap .popup_info .tour {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}
.low_wrap .popup_info .tour img {
    display: block;
}
.low_wrap .popup_info .tour span {
    font-size: 15px;
    letter-spacing: -1px;
}
.low_wrap .popup_info .info {
    display: inline-block;
    margin: 0 0 0 20px;
    letter-spacing: -1px;
    vertical-align: middle;
    text-align: left;
}
.low_wrap .popup_info .info span {
    font-size: 16px;
    color: #444;
    margin-right: 10px;
}
.low_wrap .popup_info .info span b {
    font-weight: bold;
    font-size: 20px;
}
.low_wrap .popup_info .info h2 {
    font-size: 18px;
    margin-top: 9px;
    color: #ff0000;
}
.low_wrap .popup_info .info h2 b {
    font-size: 24px;
    vertical-align: -2px;
}

/* 운임 강조 이벤트 */
.tour_result_wrap .result_list .airline_info .airline_top .airline .accent {
    display: block;
    height: 24px;
}
.tour_result_wrap .result_list .airline_info .airline_top .airline .accent i {
    margin-top: 4px;
    padding: 0 6px 0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 13px;
    font-style: normal;
    vertical-align: 1px;
    background-color: #fffaeb;
    color: #f57075;
    border: 1px solid #ff99a3;
    display: inline-block;
    height: 18px;
    line-height: 18px;
}
.result_list .rate_info .charge_list ul li.accent {
    background-color: #fff9e6;
    color: #f33844;
}
.result_list .rate_info .charge_list ul li.accent .fare strong {
    color: #f33844;
}

/*** 항공권특가(국제선) ***/
.lowest_airline.lowest_airline02 .lowest_list .bx-wrapper .slides .airSpecials {
    position: absolute;
    background: #5072c8;
    border: 1px solid #546efa;
    z-index: 24000;
    border-radius: 20px;
    padding: 10px 20px;
    top: -33px;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-wrapper .slides .airSpecials:before {
    content: "";
    position: absolute;
    border-top: 8px solid #546efa;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -9px;
    left: 20px;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-wrapper .slides .airSpecials:after {
    content: "";
    position: absolute;
    border-top: 8px solid #5072c8;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -7px;
    left: 20px;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-wrapper .slides .airSpecials span {
    float: left;
    font-size: 13px;
    color: #ffffff;
    font-family: "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-wrapper .slides .airSpecials span.close {
    top: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
    background: url("/img/trip/flight/m/ico/sp_tour.png") no-repeat;
    background-repeat: no-repeat;
    background-position: -56px -16px;
    background-size: 160px;
    float: left;
    margin-left: 10px;
    margin-top: 2px;
    cursor: pointer;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-wrapper,
.lowest_airline02 .bx-viewport {
    height: 191px !important;
}
.lowest_airline.lowest_airline02 .lowest_list ul.slides {
    margin-left: 1px;
    top: 35px;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-controls-direction .bx-prev {
    position: absolute;
    top: 114px;
    left: 0;
    width: 42px;
    height: 42px;
    display: inline-block;
    line-height: 30px;
    background: url("/img/trip/flight/main/bg_reser_li.png") repeat;
}
.lowest_airline.lowest_airline02 .lowest_list .bx-controls-direction .bx-next {
    position: absolute;
    top: 114px;
    left: 876px;
    width: 42px;
    height: 42px;
    display: inline-block;
    line-height: 30px;
    background: url("/img/trip/flight/main/bg_reser_li.png") repeat;
}
.lowest_airline.lowest_airline02 .page_num {
    position: absolute;
    z-index: 9;
    display: none;
    top: 91px;
    padding: 6px 12px 6px 11px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    background: url("/img/trip/flight/main/bg_reser_li02.png") repeat;
}
.lowest_airline.lowest_airline02 .lowest_list02 {
    margin-left: 155px;
    height: 191px;
    top: -35px;
    margin-bottom: -35px;
}

/*** 쿠폰 다운 ***/
.flight.pop_coupon_down {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fafafa;
    z-index: 105;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.flight.pop_coupon_down .bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 8px;
    background: rgba(249, 249, 249, 0.95);
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 106;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.flight.pop_coupon_down .bottom button {
    display: block;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: 16px;
    line-height: 44px;
}
.flight.pop_coupon_down .bottom .btn_down_all {
    width: 100%;
    background: #f43142;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.flight.pop_coupon_down .bottom .btn_down_all:disabled {
    background: #ccc;
}
.flight.pop_coupon_down .bottom .btn_down_all:disabled:hover {
    -webkit-tap-highlight-color: transparent;
}
.flight.pop_coupon_down .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 52px;
    height: 52px;
    border: none;
    background: none;
    color: transparent;
    font-size: 10px;
    z-index: 20;
}
.flight.pop_coupon_down .close:before,
.pop_coupon_down .close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 25px;
    margin-top: -13px;
    background: #333;
    content: "";
}
.flight.pop_coupon_down .close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.flight.pop_coupon_down .close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.flight.pop_coupon_down .sum_clc {
    margin: 0 16px 24px;
    padding: 16px 2px 12px;
    background: #fff;
}
.flight.pop_coupon_down .sum_clc ul {
    display: table;
    width: 100%;
}
.flight.pop_coupon_down .sum_clc li {
    display: table-cell;
    position: relative;
    width: 33.33%;
    padding: 0 4px;
    color: #333;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
}
.flight.pop_coupon_down .sum_clc li > div {
    display: inline-block;
    text-align: right;
}
.flight.pop_coupon_down .sum_clc li em {
    display: block;
    height: 16px;
    padding-right: 1px;
    font-size: 11px;
    line-height: 16px;
}
.flight.pop_coupon_down .sum_clc li .prc {
    display: block;
    margin-top: 4px;
    font-size: 17px;
    line-height: 20px;
    white-space: nowrap;
}
.flight.pop_coupon_down .sum_clc li .oprc {
    display: block;
    color: #999;
    font-size: 13px;
    line-height: 16px;
    text-decoration: line-through;
}
.flight.pop_coupon_down .sum_clc li .sprc {
    display: block;
    margin-left: -8px;
    color: #f43142;
    font-size: 11px;
    line-height: 13px;
}
.flight.pop_coupon_down .sum_clc li:nth-child(2) {
    color: #f43142;
}
.flight.pop_coupon_down .sum_clc li:nth-child(2):before {
    content: "";
    position: absolute;
    top: 30px;
    left: -4px;
    width: 8px;
    height: 1px;
    background: #333;
}
.flight.pop_coupon_down .sum_clc li:nth-child(2) .prc {
    font-weight: bold;
}
.flight.pop_coupon_down .sum_clc li:nth-child(3):before {
    content: "";
    position: absolute;
    top: 28px;
    left: -4px;
    width: 8px;
    height: 2px;
    border: solid #333;
    border-width: 1px 0;
}
.flight.pop_coupon_down .sum_clc li:nth-child(3) .prc {
    color: #111;
    font-weight: bold;
}
.flight.pop_coupon_down .sum_clc.sum_clc_fix {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 16px 16px 12px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    z-index: 106;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.flight.pop_coupon_down .coupon_down_cont {
    overflow: hidden;
    position: relative;
    background: #fafafa;
}
.flight.pop_coupon_down .prd_coupon_down {
    overflow: hidden;
    padding-bottom: 72px;
}
.flight.pop_coupon_down .prd_coupon_down .ttxt {
    margin: 32px 0 20px;
    color: #1b1b1b;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
}
.flight.pop_coupon_down .prd_coupon_down .stit {
    margin: 24px 16px 12px;
    color: #333;
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown_lst {
    margin: 12px 16px 0;
}
.flight.pop_coupon_down .prd_coupon_down .cdown_lst li {
    margin-top: 4px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown {
    position: relative;
    padding: 17px 56px 13px 17px;
    border-radius: 4px;
    background: #fff;
    color: #333;
    font-size: 13px;
    line-height: 20px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #eee;
    border-radius: 4px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .prc {
    float: left;
    width: 78px;
    color: #f43142;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .icon {
    display: inline-block;
    padding: 0 8px;
    margin: 0 0 6px 0;
    border: 1px solid #333;
    border-radius: 17px;
    color: #333;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    vertical-align: top;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .icon.icon_cart {
    border-color: #f43142;
    color: #f43142;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .icon.icon_delivery {
    border-color: #5165f4;
    color: #5165f4;
}
.flight.pop_coupon_down .prd_coupon_down .cdown > div {
    overflow: hidden;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .pay,
.prd_coupon_down .cdown .name,
.prd_coupon_down .cdown .cont,
.prd_coupon_down .cdown .terms {
    display: block;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .down,
.prd_coupon_down .cdown .end,
.prd_coupon_down .cdown .apply {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 48px;
    border-radius: 0 4px 4px 0;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .down {
    width: 49px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(49%, #ef4756), color-stop(50%, #fff)) repeat-y 0 0;
    background: -webkit-linear-gradient(#ef4756 49%, #fff 50%) repeat-y 0 0;
    background: linear-gradient(#ef4756 49%, #fff 50%) repeat-y 0 0;
    background-size: 1px 4px;
    background-color: #ef4756;
    border: none;
    color: #fff;
    font-size: 14px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .down span {
    background-image: url("//m.11st.co.kr/MW/img/order/sprites/sp_coupon_down.png");
    background-position: -25px 0px;
    width: 20px;
    height: 20px;
    background-size: 45px 20px;
    display: block;
    overflow: hidden;
    margin: 1px auto;
    color: transparent;
    font-size: 10px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .end {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(49%, #eeeeee), color-stop(50%, rgba(238, 238, 238, 0))) repeat-y 0 0;
    background: -webkit-linear-gradient(#eeeeee 49%, rgba(238, 238, 238, 0) 50%) repeat-y 0 0;
    background: linear-gradient(#eeeeee 49%, rgba(238, 238, 238, 0) 50%) repeat-y 0 0;
    background-size: 1px 4px;
    color: #333;
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    text-align: center;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .end span {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.flight.pop_coupon_down .prd_coupon_down .cdown .apply {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(49%, #ef4756), color-stop(50%, rgba(239, 71, 86, 0))) repeat-y 0 0;
    background: -webkit-linear-gradient(#ef4756 49%, rgba(239, 71, 86, 0) 50%) repeat-y 0 0;
    background: linear-gradient(#ef4756 49%, rgba(239, 71, 86, 0) 50%) repeat-y 0 0;
    background-size: 1px 4px;
    color: #f43142;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: center;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .apply span {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.flight.pop_coupon_down .prd_coupon_down .cdown .apply span:before {
    background-image: url("//m.11st.co.kr/MW/img/order/sprites/sp_coupon_down.png");
    background-position: 0px 0px;
    width: 20px;
    height: 20px;
    background-size: 45px 20px;
    content: "";
    display: block;
    margin: 0 auto 5px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown.cdelivery .prc {
    color: #5165f4;
}
.flight.pop_coupon_down .prd_coupon_down .cdown.capply:before {
    border-color: #f2717c;
}
.flight.pop_coupon_down .prd_coupon_down .cdown.capply .icon {
    border-color: #f43142;
    color: #f43142;
}
.flight.pop_coupon_down .prd_coupon_down .cdown.capply > div,
.prd_coupon_down .cdown.capply .prc {
    color: #f43142;
}
.flight.pop_coupon_down .prd_coupon_down .cdown_non {
    margin: 100px 0;
}
.flight.pop_coupon_down .prd_coupon_down .cdown_non p {
    color: #333;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}
.flight.pop_coupon_down .prd_coupon_down .cdown_non p:before {
    content: "";
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto 26px;
    background: url("//m.11st.co.kr/MW/img/order/img_cart_non.png") no-repeat;
    background-size: 100% 100%;
}

.flight.pop_coupon_down {
    position: fixed;
    width: 600px;
    display: none;
    z-index: 99999;
}
.flight.pop_coupon_down .coupon_down_cont {
    height: 100%;
    overflow-y: auto;
    min-height: 457px;
    max-height: 700px;
}
.flight.pop_coupon_down .bottom {
    position: relative;
}
.flight.pop_coupon_down.lightbox {
    left: 50%;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .prc {
    width: 115px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .down {
    width: 70px;
}
.flight.pop_coupon_down .prd_coupon_down .cdown .end {
    width: 70px;
}

/* 메인페이지 최근검색 리뉴얼 2020.06 */
.tour_bodyArea .comparebox .recent_search {
    width: 1200px;
    margin: 0 auto;
    display: table;
    min-height: 63px;
}
.tour_bodyArea .comparebox .recent_search .left_sec {
    display: block;
    margin: 23px 0;
    float: left;
}
.tour_bodyArea .comparebox .recent_search .left_sec .left_cts {
    border-right: 1px solid #5461c6;
    height: 14px;
    display: inline-block;
    padding-right: 11px;
}
.tour_bodyArea .comparebox .recent_search .left_sec .left_cts .tit_txt {
    font-size: 14px;
    font-weight: bold;
    margin-top: -3px;
    display: inline-block;
}
.tour_bodyArea .comparebox .recent_search .mid_sec {
    display: table;
    margin: 8px 0;
    padding: 0 7px;
    float: left;
    width: 992px;
}
.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item {
    display: inline-block;
    opacity: 0.9;
    border-radius: 16px;
    background-color: #5461c6;
    padding: 4px 13px;
    cursor: pointer;
    margin: 6px 4px;
}
.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .text_box {
    color: #ffffff;
    font-size: 13px;
    display: block;
    line-height: 24px;
    float: left;
}
/*.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .text_box.number_box{margin-top: -1px;}*/
/*.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .city_box{max-width: 83px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; float: left;}*/
.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .route_box {
    background: url("/img/trip/flight/main/bg_main.png") no-repeat -48px 0;
    background-size: 100px;
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 4px 0;
    float: left;
}
.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .route_box.OW {
    background-position: -24px 0;
    margin-top: -1px;
}
.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .route_box.MT {
    background-position: -72px 0;
}
.tour_bodyArea .comparebox .recent_search .mid_sec .recent_item .cancel_box {
    padding-right: 24px;
    display: block;
    height: 24px;
    float: right;
    margin: 0 -5px 0 6px;
    background: url("/img/trip/flight/main/bg_main.png") no-repeat 0 -25px;
    background-size: 100px;
}
.tour_bodyArea .comparebox .recent_search .right_sec {
    float: right;
    display: block;
    margin: 23px 0 0 0;
}
.tour_bodyArea .comparebox .recent_search .right_sec .right_cts {
    border-left: 1px solid #5461c6;
    height: 14px;
    display: inline-block;
    padding-left: 11px;
}
.tour_bodyArea .comparebox .recent_search .right_sec .right_cts .wish_box {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    height: 32px;
    opacity: 0.92;
    border-radius: 16px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-top: -9px;
    cursor: pointer;
}
.tour_bodyArea .comparebox .recent_search .right_sec .right_cts .wish_box .before_box {
    width: 24px;
    display: block;
    height: 24px;
    margin: 4px 0 0 12px;
    float: left;
    background: url("/img/trip/flight/main/bg_main.png") no-repeat 0 0;
    background-size: 100px;
}
.tour_bodyArea .comparebox .recent_search .right_sec .right_cts .wish_box .wish_info {
    margin: 6px 0 0 4px;
    display: block;
    color: #5461c6;
    float: left;
    font-size: 13px;
    line-height: 18px;
    height: 18px;
}
.tour_bodyArea .comparebox .recent_search .right_sec .right_cts .wish_box .arrow {
    width: 24px;
    display: block;
    height: 24px;
    margin: 4px 6px 0 0;
    float: left;
    background: url("/img/trip/flight/main/bg_main.png") no-repeat -24px -25px;
    background-size: 100px;
}

/* 메인페이지 베너 리뉴얼 2020.06 */
.maincontents .inner_recent .column .ev_banner {
    margin: 0;
    width: 1200px;
    height: 120px;
}
.maincontents .inner_recent .column .ev_banner .slides li a {
    height: 120px;
    width: 1200px;
}
.maincontents .inner_recent .column .ev_banner .slides li a img {
    height: 120px;
    width: auto;
}
.maincontents .inner_recent .column .ev_banner .bx-controls-direction .bx-prev,
.maincontents .inner_recent .column .ev_banner .bx-controls-direction .bx-next {
    height: 120px;
    width: 70px;
}
.maincontents .inner_recent .column .ev_banner .bx-controls-direction {
    margin-top: -60px;
}
.maincontents .inner_recent .column .ev_banner .bx-controls-direction a:before {
    display: none;
}
.maincontents .inner_recent .column .ev_banner .bx-controls-direction a:after {
    position: absolute;
    left: 26px;
    content: "";
    height: 44px;
    width: 44px;
    top: 38px;
    background: url("/img/trip/flight/main/bg_main.png") no-repeat -1px -55px;
    background-size: 100px;
    transform: none;
    bottom: inherit;
}
.maincontents .inner_recent .column .ev_banner .bx-controls-direction a.bx-next:after {
    background-position: -42px -57px;
    left: 0;
}
.maincontents .inner_recent .column .ev_banner:hover .bx-controls-direction a:after {
    background-color: #000000;
    border-radius: 22px;
    opacity: 0.5;
}

/* 메인페이지 추천항공권 리뉴얼 2020.06 */
.maincontents .recommend {
    margin: 22px 0 11px;
    display: inline-block;
    width: 100%;
}
.maincontents .recommend .rec_top {
    padding-bottom: 10px;
    border-bottom: 1px solid #bbbbbb;
}
.maincontents .recommend .rec_top .rec_subj {
    color: #111111;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: -1.09px;
    line-height: 29px;
    height: 29px;
    display: inline-block;
}
.maincontents .recommend .rec_top .rec_noti {
    color: #999999;
    float: right;
    font-size: 12px;
    line-height: 29px;
    margin-right: 1px;
}
.maincontents .recommend .rec_bot {
    padding-top: 20px;
}
.maincontents .recommend .rec_bot .rec_item {
    width: 290px;
    height: 90px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #cccccc;
    background-color: #ffffff;
    float: left;
    margin: 0 11px 11px 0;
    cursor: pointer;
}
.maincontents .recommend .rec_bot .rec_item:nth-of-type(4n) {
    margin-right: 0;
    width: 289px;
}
.maincontents .recommend .rec_bot .rec_item .item_left {
    padding: 20px 21px 0 31px;
    float: left;
    width: 238px;
}
.maincontents .recommend .rec_bot .rec_item.airline .item_left {
    width: 197px;
    padding-right: 0;
}
.maincontents .recommend .rec_bot .rec_item .item_left .route {
    font-size: 15px;
    font-weight: bold;
    color: #111111;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.maincontents .recommend .rec_bot .rec_item .item_left .price {
    margin-top: 10px;
    display: block;
}
.maincontents .recommend .rec_bot .rec_item .item_left .price .price_txt {
    font-size: 18px;
    font-weight: bold;
    display: inline;
}
.maincontents .recommend .rec_bot .rec_item .item_left .price .price_after {
    font-size: 11px;
    font-weight: bold;
}
.maincontents .recommend .rec_bot .rec_item .item_right {
    float: right;
    padding: 25px 21px 0 0;
}
.maincontents .recommend .rec_bot .rec_item .item_right .airline_icon {
    width: 40px;
    display: block;
}

/* 오픈여정 검색 숨김 2020.06 */
#notConfirmed {
    display: none !important;
}

/* 국내선 운임 강조 라벨 */
.domestic_result_list > li > div {
    height: 100% !important;
}
.domestic_result_list > li.itinerary.focus_fare {
    background: #fff9e6;
}
.colum_area.selected .domestic_result_list .itinerary_row.focus_fare {
    background: #fff9e6;
}
.colum_area.selected .focus_notice_wrap {
    overflow: hidden;
}
.colum_area.selected .focus_notice_wrap .focus_notice {
    overflow: hidden;
    margin-top: 3px;
}
.colum_area.selected .focus_notice_wrap .focus_notice.temp {
    display: none;
}
.colum_area.selected .focus_notice_wrap .focus_notice .focus_text_wrap {
    float: right;
}
.colum_area.selected .focus_notice_wrap .focus_notice .focus_text {
    font-size: 13px;
    color: #f33844;
    font-weight: bold;
}
.domestic_result_list li .airline .fieldset .label {
    display: block;
    width: inherit;
}
.single_colum .selected .domestic_result_list li .airline .fieldset .label {
    display: block;
    width: inherit;
}
.domestic_result_list li .airline .fieldset .label .temp {
    display: none;
}
.domestic_result_list li .airline .fieldset .label .item {
    max-width: 90px;
    margin: 3px 0 -3px;
}
.selected .domestic_result_list li .airline .fieldset .label .item {
    max-width: 80px;
}
.seller_list table td.label {
    padding-left: 10px;
}
.seller_list table td.label .label_item {
    font-size: 11px;
    color: #6423ff;
    background: #f9f7ff;
    border: 1px solid rgba(100, 35, 255, 0.4);
    padding: 1px 6px;
    height: 16px;
    line-height: 16px;
    display: inline-block;
    margin: 0 0 -3px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    letter-spacing: -1.8px;
}
.seller_list table td.label .label_item.card {
    color: #0b83e6;
    border: 1px solid rgba(11, 131, 230, 0.4);
    background: #f6fbfe;
}
.seller_list table td.label .label_wrap {
    text-align: right;
    margin: 3px;
    float: left;
}
.seller_list table td.label .label_wrap.temp {
    display: none;
}
