@charset "UTF-8";

@import "../../common/mixins";
@import "../../common/helpers/variables";
@import "../../trip/sprites/sp_icon_2x";

//공통 작은 레이어
.c_trip_samll_layer {
    display: none;
    position: absolute;
    padding: 20px 10px 20px 20px;
    border-radius: 4px;
    background-color: $color-grayscale-13;
    box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36);

    .c_trip_samll_layer_cont {
        min-width: 167px;
        padding-right: 24px;

        p {
            font-size: 16px;
            line-height: 24px;
            color: $color-grayscale-02;
            text-align: left;
        }
    }

    .c_trip_samll_layer_close {
        button {
            overflow: hidden;
            position: absolute;
            top: 20px;
            right: 11px;
            @include ico-cancel($size: 12px, $thick: 1px, $color: $color-grayscale-03, $padding: 6px);
        }
    }

    &--active {
        display: block;
        z-index: 100;
    }
}

//c_trip_share_layer 해외패키지, btn_area 해외호텔
.c_trip_share_layer,
.btn_area {
    .c_trip_samll_layer {
        width: 280px;
        padding: 20px 0;
        background-color: $color-gray-14;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12);
        box-sizing: border-box;

        &_cont {
            padding-right: 0;
            color: $color-gray-02;
            font-weight: normal;
            font-size: 15px;

            .title {
                padding: 0 20px;
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 15px;
                line-height: 1.5;
            }

            .cont {
                padding: 0 20px;
                white-space: normal;
                word-wrap: break-word;
                word-break: break-all;

                .share_sns {
                    display: table;
                    width: 100%;
                    padding-top: 10px;
                    text-align: center;
                    table-layout: fixed;

                    li {
                        display: table-cell;
                        text-align: center;
                    }

                    [class^="sns_"] {
                        color: $color-gray-03;
                        font-size: 13px;
                        font-family: $font-family-lato;

                        &:before {
                            display: block;
                            @include sprite-retina($sp_icon_2x_sns_facebook);
                            margin: 0 auto 4px;
                            content: "";
                        }
                    }

                    .sns_twitter:before {
                        @include sprite-retina($sp_icon_2x_sns_twitter);
                    }

                    .sns_x:before {
                        @include sprite-retina($sp_icon_2x_sns_x);
                    }

                    .sns_kakao:before {
                        @include sprite-retina($sp_icon_2x_sns_kakaostory);
                    }
                }

                .share_form {
                    margin-top: 20px;

                    .c_trip_product_input {
                        position: relative;
                        display: block;

                        .form_input {
                            overflow: hidden;
                            width: 100%;
                            height: 40px;
                            padding: 0 10px 0 12px;
                            font-size: 14px;
                            background-color: $color-gray-14;
                            border: 1px solid $color-gray-10;
                            color: $color-gray-02;
                            font-size: 15px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;

                            &::placeholder {
                                color: $color-gray-11;
                                opacity: 1;
                            }

                            &::-ms-input-placeholder {
                                color: $color-gray-11;
                            }

                            &:not(:only-child) {
                                width: calc(100% - 58px);
                                padding-right: 0;
                            }

                            &:read-only {
                                background-color: $color-gray-13;
                                color: $color-gray-09;
                            }
                        }

                        .form_add {
                            overflow: hidden;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            text-align: right;

                            button {
                                position: relative;
                                display: inline-block;
                                min-width: 56px;
                                height: 40px;
                                padding: 0 13px;
                                background-color: $color-gray-14;
                                border: 1px solid $color-gray-09;
                                color: $color-gray-02;
                                font-size: 15px;
                                line-height: 38px;
                                text-align: center;
                                vertical-align: middle;
                                box-sizing: border-box;

                                &:before,
                                &:after {
                                    display: inline-block;
                                    margin: -2px 2px 0 0;
                                    vertical-align: middle;
                                    content: "";
                                }
                            }
                        }
                    }
                }
            }
        }

        &_close {
            button {
                top: 11px;
            }

            span {
                @include ico-cancel(8px, 1px, $color-gray-02, 4px);
            }
        }
    }
}

.btn_area {
    .c_trip_samll_layer {
        z-index: 1;
        right: 0;
    }
}
//항공권
ul.reser_info02 {
    .c_trip_samll_layer {
        top: 38px;
        right: 0;
    }
    .c_trip_btn_share {
        display: flex;
        align-items: center;
        color: $color-gray-14;
        text-decoration: none;
        font-size: 13px;
        line-height: 30px;
        &::before {
            display: block;
            width: 23px;
            height: 18px;
            background: url("/img/trip/flight/ico/sp_my_info.png") no-repeat left -48px;
            content: "";
        }
    }
}
